import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signOut } from "firebase/auth";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {db, storage} from "@/plugins/firebase";
import {deleteObject, getDownloadURL, ref as storageRef, uploadBytes} from "firebase/storage";
import i18n from "@/i18n";

export default {
  state: {
    user: null,
    verificationMailWasRecentlySent: null
  },
  mutations: {
    setUser (state, payload) {
      state.user = payload
    },
    setVerificationMailWasRecentlySent (state, payload) {
      state.verificationMailWasRecentlySent = payload
    }
  },
  actions: {
      // Actions returns Promises, see https://mclassdesigns.gitbook.io/js-journal/
    signUserUp({ dispatch, commit }, payload) {
        commit('startCall');
        return createUserWithEmailAndPassword(getAuth(), payload.email, payload.password)
            .then(userCredential => {
                commit('setUser', {
                    id: userCredential.user.uid,
                    email: userCredential.user.email,
                })
                commit('successCall');
                return userCredential.uid; // Does it make sense to return the UID and then do other things?
            })
            .then(() => dispatch('sendEmailVerification')) // TODO wait for email being sent??? 
            .then(() => dispatch('logout'))
            .catch(error => {
                // commit('errorCall', error);
                return Promise.reject(error);
            })
    },
    signUserIn({commit}, payload) {
        console.info("signing user " + payload.email + " in ...");
        commit('startCall');
        commit('setVerificationMailWasRecentlySent', false);
        return signInWithEmailAndPassword(getAuth(), payload.email, payload.password)
            .then(userCredential => {
                if (userCredential.user.emailVerified) {
                  commit('setUser', {
                      id: userCredential.user.uid,
                      email: userCredential.user.email
                  })
                  commit('successCall');
                  return userCredential.user.uid;
                } else {
                  const error = new Error("Email is not verified"); 
                  error.code = "auth/emailnotverified";
                  // commit('errorCall', error);
                  return Promise.reject(error);
                }
            })
            .catch(error => {
                // commit('errorCall', error);
                return Promise.reject(error);
            })
    },
    sendEmailVerification({commit}) { // TODO has this to be possible without auth like the password reset, only with the mail? --> no, because the user can first try to login and then resend the verification mail
        commit('startCall');
        return sendEmailVerification(getAuth().currentUser)
            .then(() => {
                commit('setVerificationMailWasRecentlySent', true);
                commit('successCall');
                return true
            })
            .catch(error => {
                commit('errorCall', error);
                return Promise.reject(error);
            })
    },
    resetPassword({commit}, payload) {
      commit('startCall');
      return sendPasswordResetEmail(getAuth(), payload.email)
          .then(() => {
              commit('successCall');
              return true
          })
          .catch(error => {
              commit('errorCall', error);
              return Promise.reject(error);
          })
    },
    // this just sets the user and is not quite similar to signUserIn
    // therefore this just returns true, not the uid from firebase which is already known 
    // and passed to this action as a parameter
    autoSignIn ({commit}, authenticatedFirebaseUser) {
      console.debug("(auto) logging user " + authenticatedFirebaseUser.email + " in ...");
      if (authenticatedFirebaseUser.emailVerified) {
        commit('setUser', {
          id: authenticatedFirebaseUser.uid,
          email: authenticatedFirebaseUser.email
        })
        return true;
      } else {
        console.debug("email was not verified");
        const error = new Error("Email is not verified"); 
        error.name = "auth/emailnotverified";
        commit('errorCall', error);
        return Promise.reject(error);
      }
    },
    fetchUserData ({ commit, getters }) {
        console.debug("fetching user data ...");
        commit('startCall');
        return getDoc(doc(db, "users", getters.user.id))
            .then((queryUser) => {
                const data = queryUser.data();
                commit('setUser', {
                    id: getters.user.id,
                    email: getters.user.email,
                    videoSnippetFavoriteIds: data?.videoSnippetFavoriteIds || [],
                    toolFavoriteIds: data?.toolFavoriteIds || [],
                    sequenceFavoriteIds: data?.sequenceFavoriteIds || [],
                    realname: data?.realname || '',
                    nickname: data?.nickname || '',
                    imageSrc: data?.imageSrc || '',
                    storageUrl: data?.storageUrl || '',
                    language: data?.language || '',
                    role: data?.role || '',
                    roles: data?.roles || [],
                });
                commit('successCall');
                return getters.user.id;
            })
            .catch(error => {
                commit('errorCall', error);
                return Promise.reject(error);
            });
    },
    updateUser({ commit, getters }, payload) {
        console.debug("updating user ...");
        commit('startCall');
        const docRef = doc(db, "users", getters.user.id);
        return setDoc(docRef, {
                videoSnippetFavoriteIds: payload.videoSnippetFavoriteIds,
                toolFavoriteIds: payload.toolFavoriteIds,
                sequenceFavoriteIds: payload.sequenceFavoriteIds,
                realname: payload.realname || '',
                nickname: payload.nickname || '',
                imageSrc: payload.imageSrc || '',
                storageUrl: payload.storageUrl || '',
                language: payload.language || '',
                role: payload.role || '',
                roles: payload.roles || [],
            }).then(() => {
                commit('setUser', {
                    id: getters.user.id,
                    email: getters.user.email,
                    videoSnippetFavoriteIds: payload.videoSnippetFavoriteIds || [],
                    toolFavoriteIds: payload.toolFavoriteIds || [],
                    sequenceFavoriteIds: payload.sequenceFavoriteIds || [],
                    realname: payload.realname || '',
                    nickname: payload.nickname || '',
                    imageSrc: payload.imageSrc || '',
                    storageUrl: payload.storageUrl || '',
                    language: payload.language || '',
                    role: payload.role || '',
                    roles: payload.roles || [],
                });
                commit('successCall');
                return true;
            })
            .catch(error => {
                commit('errorCall', error);
                return Promise.reject(error);
            });
    },
    updateUserWithImage({ dispatch, commit }, { payload, imageFile }) {
      if (imageFile) { // first upload the image, then update the user

          if (imageFile.size > (3 * 1024 * 1024)) {
            const error = new Error(i18n.t('error.upload.profile-image-too-large'))
            commit('errorCall', error );
            return Promise.reject(error);
          }

          const filename = imageFile.name;
          const ext = filename.slice(filename.lastIndexOf('.'));
          const storageUrl = 'profileImages/' + payload.id + ext;
          const imageStorageRef = storageRef(storage, storageUrl);
          return uploadBytes(imageStorageRef, imageFile)
              .then(async (snapshot) => {
                  console.debug('Uploaded a blob or file!', snapshot);
                  
                  // const orginalDownloadUrl = getDownloadURL(imageStorageRef).then((downloadURL) => {
                  //     console.debug('File available at', downloadURL);
                  //     payload.imageSrc = downloadURL;
                  //     payload.storageUrl = storageUrl;
                  //     return dispatch("updateUser", payload);
                  // });

                  commit('setLoading', true);

                  // wait until firebases image resizes extension has resized the image 
                  // problematic if the time is not enough and the file is not yet resized, cause a new token will be stored 
                  // TODO: [OP-181] store url of resized images base on event mechanism and delete resized images as well 
                  // -> replace by waiting for event that the resize function triggers
                  await new Promise(r => setTimeout(r, 3000));

                  const resizedStorageUrl = 'profileImages/' + payload.id + "_800x800.jpeg";
                  const newResizedImageStorageRef = storageRef(storage, resizedStorageUrl);
                  return getDownloadURL(newResizedImageStorageRef).then((downloadURL) => {
                    console.debug('File available at', downloadURL);
                    payload.imageSrc = downloadURL;
                    payload.storageUrl = resizedStorageUrl;
                    commit('setLoading', false);
                    return dispatch("updateUser", payload);
                });
                
              });
      }
      return dispatch("updateUser", payload);
    },
    deleteUserImage({ dispatch, commit }, { payload }) {
      if (payload.storageUrl) {
          const imageStorageRef = storageRef(storage, payload.storageUrl);
          // Delete the file
          deleteObject(imageStorageRef).then(() => {
              // File deleted successfully
              payload.imageSrc = "";
              payload.storageUrl = "";
              return dispatch("updateUser", payload);
          }).catch((error) => {
              commit('errorCall', error);
              return Promise.reject(error);
          });
      }
    },
    logout ({ dispatch, commit }) {
      console.debug("logging the user out ... ");
      commit('setLoading', true);
      commit('clearError');
      return signOut(getAuth())
        .then(() => {
          commit('setUser', null);
          commit('setLoading', false);
          // clear things requiring auth, here or in main.js onAuthStateChanged?
          dispatch('clearEverythingRequiringAuth');
          return true
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          return Promise.reject(error);
        })
    }
  },
  getters: {
    user (state) {
      // console.debug("getting user");
      return state.user
    },
    verificationMailWasRecentlySent (state) {
      return state.verificationMailWasRecentlySent
    }
  }
}
