<template>
  <v-btn v-if="id" :href="vimeoLink" target="_blank" class="d-flex ma-0">
    <v-icon left>mdi-vimeo</v-icon>
    {{ $t('nav.watch-on-platform') }} Vimeo
  </v-btn>
</template>

<script>
export default {
  name: "VimeoLink",
  props: {
    id: {
      type: String,
      required: true
    },
    start: {
      type: Number, // seconds
      default: 0
    },
    autoplay: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    startTimeArgument() {
      if (!this.start || this.start === '0') {
        return "";
      } else {
        const minutes = Math.floor(this.start / 60);
        const seconds = this.start - minutes * 60;
        return `#t=${minutes}m${seconds}s`;
      }
    },
    // https://help.vimeo.com/hc/en-us/articles/12425821012497-Start-playback-at-a-specific-timecode
    // attention: the start time argument must be attached to the end of the url with a hash (#) instead of a question mark (?)
    vimeoLink() {https://vimeo.com/28986014?loop=0&dnt=1
      return "https://vimeo.com/" + this.id + "?" + "autoplay=" + this.autoplay + "&loop=0&dnt=1" + this.startTimeArgument;
    }
  }
};
</script>

<style scoped>
</style>
