import { cleanTagsForStorage } from '@/utils';

export default {
  methods: {
    // Sharing only worked in Safari on the Mac
    canShare() {
      return navigator.share;
    },
    cleanTag(tag) {
      tag = tag.toLowerCase();
      // remove #, :, ,, ;, .
      tag = tag.replace(/[#]/g, "");
      tag = tag.replace(/[:,;.]/g, " ");
      // remove space at the beginning, but not in the middle or at the end
      tag = tag.replace(/^\s+/g, '');
      // replace multiple spaces with one space
      tag = tag.replace(/\s\s+/g, ' ');
      return tag;
    },
    cleanTagsForStorage,
    // follow a tag by searching for it in the video snippets
    followTag(tag){
      this.$router.push({ name: "VideoSnippetSearch", query: { q: "tags:" + tag } });
    },
    convertedDuration() {
      if (!this.videoSnippetSolrDoc || !this.videoSnippetSolrDoc.duration) {
        throw new Error("videoSnippetSolrDoc or videoSnippetSolrDoc.duration is null or undefined");
      }

      if (this.videoSnippetSolrDoc.duration === "P0D") {
        return "LIVESTREAM";
    }

      const match = this.videoSnippetSolrDoc.duration.match(
        /PT(\d+H)?(\d+M)?(\d+S)?/
      );

      if (!match) {
        console.log(
          "Duration does not match the expected format:",
          this.videoSnippetSolrDoc.duration
        );
        return null;
      }

      const hours = parseInt((match[1] || "0H").slice(0, -1));
      const minutes = parseInt((match[2] || "0M").slice(0, -1));
      const seconds = parseInt((match[3] || "0S").slice(0, -1));

      let convertedDuration;

      if (hours === 0) {
        const formattedMinutes = minutes.toString().padStart(2, "0");
        const formattedSeconds = seconds.toString().padStart(2, "0");
        convertedDuration = `${formattedMinutes}:${formattedSeconds}`;
      } else {
        const formattedHours = hours.toString().padStart(2, "0");
        const formattedMinutes = minutes.toString().padStart(2, "0");
        const formattedSeconds = seconds.toString().padStart(2, "0");
        convertedDuration = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      }

      return convertedDuration;
    },
  },
}