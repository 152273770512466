import Vue from 'vue';
import { collection, doc, getDoc, getDocs, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { db, storage } from "@/plugins/firebase";
import data from '@/data';
import { cleanTagsForStorage } from '@/utils';

export default {
	state: {
		videoSnippets: []
	},
	getters: {
		videoSnippets: state => {
			return state.videoSnippets;
		},
		videoSnippetById: state => id => {
			return state.videoSnippets.find(s => s.id === id);
		}
	},
	// Actions returns Promises, see https://mclassdesigns.gitbook.io/js-journal/
	actions: {

		// load a single video snippet by id
		async loadVideoSnippet({ commit }, id) {
			commit('startCall');

			try {
				const docRef = doc(db, 'video-snippets', id);
				const docSnapshot = await getDoc(docRef);
				if (docSnapshot.exists()) {
				  const data = docSnapshot.data();
				  const videoSnippet = {
					id: docSnapshot.id,
					// indexed: data.indexed || "",
					created: data.created,
					updated: data.updated,
					userId: data.userId,
					published: data.published,
					name: data.name,
					description: data.description,
					videoId: data.videoId,
					videoStart: data.videoStart,
					videoEnd: data.videoEnd,
					votesUp: data.votesUp || [],
					votesDown: data.votesDown || [],
					tags: data.tags || [],
					imageSrc: data.imageSrc,
					storageUrl: data.storageUrl,
				  };
				  // TODO: [OP-207] check if videoSnippet already exists in the store, multiple calls to loadVideoSnippet will add the same videoSnippet multiple times
				  commit('addVideoSnippet', videoSnippet); 
				  commit('successCall');
				  return true;
				} else {
				  throw new Error(`Video snippet with ID ${id} not found in the database`);
				}
			  } catch (error) {
				commit('errorCall', error);
				return Promise.reject(error);
			  }
		},

		// out of use atm, we are loading single video snippets on demand
		loadVideoSnippets({ commit, getters }) {
			commit('startCall');
			return getDocs(collection(db, "video-snippets"))
				.then((queryVideoSnippets) => {
					commit('clearVideoSnippets');
					queryVideoSnippets.forEach((doc) => {
						const data = doc.data();
						// const baseVideo = getters.videos.find((s) => s.id === data.videoId);
						commit('addVideoSnippet', {
							id: doc.id,
							// indexed: data.indexed || "",
							created: data.created,
							updated: data.updated,
							userId: data.userId,
							published: data.published,
							name: data.name,
							description: data.description,
							videoId: data.videoId,
							videoStart: data.videoStart,
							videoEnd: data.videoEnd,
							votesUp: data.votesUp || [],
							votesDown: data.votesDown || [],
							tags: data.tags || [],
							imageSrc: data.imageSrc,
							storageUrl: data.storageUrl,
							// temporarily: add the video information redundantly not to break mini search:
							baseVideoFieldsForMiniSearchIndex: "temporarily not used"
							// baseVideoFieldsForMiniSearchIndex: baseVideo.description + " " + baseVideo.publisher + " " + baseVideo.name + " " + baseVideo.externalId 
						});
					});
					commit('successCall');
					// console.debug("loaded videosnippets");
					return true;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		},
		// TODO not yet tested and no data in examples
		createExampleVideoSnippets({ commit, dispatch }, userId) {
			commit('startCall');
			for (const [id, videoSnippet] of Object.entries(data.video-snippets)) {
				dispatch('createVideoSnippet', {
					userId: userId,
					published: "to-approve", // TODO: use published value
					name: videoSnippet.name,
					description: videoSnippet.description,
					videoId: videoSnippet.defaultVideoId,
					videoStart: videoSnippet.defaultVideoStart,
					videoEnd: videoSnippet.defaultVideoEnd,
					votesUp: [],
					votesDown: [],
					tags: videoSnippet.tags || [],
					imageSrc: "",
					storageUrl: ""
				});
			}
			commit('successCall');
		},
		createVideoSnippet({ commit }, videoSnippet) {
			let now = new Date().toISOString();

			videoSnippet = {
				...videoSnippet,
				created: now,
				updated: now
			};

			commit('startCall');
			return addDoc(collection(db, "video-snippets"), videoSnippet)
				.then((createdVideoSnippet) => {
					commit('addVideoSnippet', {
						id: createdVideoSnippet.id,
						created: videoSnippet.created,
						updated: videoSnippet.updated,
						userId: videoSnippet.userId,
						name: videoSnippet.name,
						description: videoSnippet.description,
						imageSrc: videoSnippet.imageSrc,
						storageUrl: videoSnippet.storageUrl,
						videoId: videoSnippet.videoId,
						videoStart: videoSnippet.videoStart,
						videoEnd: videoSnippet.videoEnd,
						votesUp: [],
						votesDown: [],
						tags: cleanTagsForStorage(videoSnippet.tags) || [],
						published: videoSnippet.published
					});
					commit('successCall');
					return createdVideoSnippet.id;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		},
		updateVideoSnippet({ commit }, payload) {

			let now = new Date().toISOString();

			payload = {
				...payload,
				updated: now
			};

			commit('startCall');
			return updateDoc(doc(db, "video-snippets", payload.id), payload)
				.then(() => {
					commit('setVideoSnippet', payload);
					commit('successCall');
					return true;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		},
		updateVideoSnippetWithImage({ dispatch }, { payload, imageFile }) {
			if (imageFile) { // first upload the image, then update the videoSnippet
				const filename = imageFile.name;
				const ext = filename.slice(filename.lastIndexOf('.'));
				const storageUrl = 'video-snippets/' + payload.id + ext;
				const imageStorageRef = storageRef(storage, storageUrl);
				return uploadBytes(imageStorageRef, imageFile)
					.then((snapshot) => {
						console.debug('Uploaded a blob or file!', snapshot);
						return getDownloadURL(imageStorageRef).then((downloadURL) => {
							console.debug('File available at', downloadURL);
							payload.imageSrc = downloadURL;
							payload.storageUrl = storageUrl;
							return dispatch("updateVideoSnippet", payload);
						});
					});
			}
			return dispatch("updateVideoSnippet", payload);
		},
		toggleApproveVideoSnippet({ dispatch, getters }, payload) {
			if (payload.published === "published")
				payload.published = "hidden";
			else
				payload.published = "published";
			
			// payload is the videoSnippetSolrDoc which may not be up to date
			// we need to get the latest version from the database
			dispatch("loadVideoSnippet", payload.id).then(() => {

				var videoSnippetInDatabase = getters.videoSnippetById(payload.id);
				videoSnippetInDatabase.published = payload.published;

				return dispatch("updateVideoSnippet", videoSnippetInDatabase);
			});
		},
		async deleteVideoSnippetImage({ dispatch, commit }, payload) { // this expects the videoSnippet (payload) to be up to date, not a solr doc
            if (payload.storageUrl) {
				try {
					const imageStorageRef = storageRef(storage, payload.storageUrl);
					// Delete the file
					await deleteObject(imageStorageRef);
					// File deleted successfully
					payload.imageSrc = "";
					payload.storageUrl = "";
					await dispatch("updateVideoSnippet", payload);
					return true;
				} catch (error) {
					console.error("Error deleting video snippet image: ", payload.storageUrl,  error);
					commit('errorCall', error);
					return false;
				}
			} else {
				const error = new Error("Image file could not be deleted (storageUrl was null or undefined)");
				commit('errorCall', error);
				return false;
			}
        },
        async deleteVideoSnippet({ dispatch, commit, getters }, payload) {
            commit('startCall');

			// payload may be the videoSnippetSolrDoc which may not be up to date
			// we need to get the latest version from the database
			await dispatch("loadVideoSnippet", payload.id).then(async () => { // await was added for tests to work
				var videoSnippetInDatabase = getters.videoSnippetById(payload.id);

				if (videoSnippetInDatabase.storageUrl) {
					await dispatch("deleteVideoSnippetImage", videoSnippetInDatabase) 
				}
				try {
					await deleteDoc(doc(db, "video-snippets", videoSnippetInDatabase.id));
					commit('deleteVideoSnippet', videoSnippetInDatabase);
					commit('successCall');
					console.debug("Video snippet deleted");
					return true;
				} catch (error) {
					console.error("Error deleting video snippet: ", error);
					commit('errorCall', error);
					return false;
				}
			});
        }
	},
	mutations: {
		clearVideoSnippets(state) {
			state.videoSnippets = [];
		},
		addVideoSnippet(state, payload) {
			state.videoSnippets.push(payload);
		},
		setVideoSnippet (state, payload) {
			const videoSnippet = state.videoSnippets.find(m => m.id === payload.id );
			if (videoSnippet) {
				// videoSnippet.indexed = payload.indexed
				videoSnippet.created = payload.created
				videoSnippet.updated = payload.updated
				videoSnippet.name = payload.name
				videoSnippet.userId = payload.userId,
				videoSnippet.description = payload.description
				videoSnippet.videoId = payload.videoId
				videoSnippet.videoStart = payload.videoStart
				videoSnippet.videoEnd = payload.videoEnd
				videoSnippet.upvotes = payload.upvotes
				videoSnippet.downvotes = payload.downvotes
				videoSnippet.imageSrc = payload.imageSrc
				videoSnippet.storageUrl = payload.storageUrl
				videoSnippet.image = payload.image
				videoSnippet.tags = payload.tags
				videoSnippet.published = payload.published
			}
		},
		deleteVideoSnippet(state, payload) {
			Vue.delete(state.videoSnippets, state.videoSnippets.indexOf(payload));
		}
	}
}
