<template>
  <v-layout row>
    <v-flex xs12 sm6 offset-sm3>
      <div v-if="loading" class="loading text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-alert v-if="error" type="error" dismissible>{{ error.message }}</v-alert>
        <v-card flat class="mt-16">
          <v-card-title class="pa-6 text-h3 h-break">
            Admin Dashboard
          </v-card-title>
          <!-- <v-card-text class="px-6 py-0 my-0 text-body-1">
            Es werden Tools und Sammlungen in der Datenbank gespeichert. 
            Bitte nur ausführen, wenn die Beispieldaten noch nicht vorhanden sind.
             Es fehlen allerdings die Bilder Tools und die Inhalte der Sammlungen
          </v-card-text> -->
          <v-card-actions>
            <v-btn v-if="userIsAdmin" text @click="loadExampleData" class="mr-4">
              <v-icon left>mdi-plus</v-icon>
              Beispieldaten laden
            </v-btn>
            <v-btn @click="goDashboard" class="mr-4">
              <v-icon left>mdi-chevron-left</v-icon>
              Leave admin area
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-flex>
  </v-layout>
</template>

<script>

export default {
  name: "AdminDashboard",
  data() {
    return {
    };
  },
  computed: {
    loading: function() { return this.$store.getters.loading; },
    error: function() { return this.$store.getters.error; },
    user() {
      return this.$store.getters.user;
    },
    userIsAuthenticated() {
      return this.user !== null && this.user !== undefined;
    },
    userIsAdmin() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("admin");
    },
    userIsFamily() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("family");
    },
  },
  methods: {
    loadExampleData() {
      if (confirm("Es werden Tools und Sammlungen in der Datenbank gespeichert. Bitte nur ausführen, wenn die Beispieldaten noch nicht vorhanden sind. Es fehlen allerdings die Bilder Tools und die Inhalte der Sammlungen")) {
        console.debug("Load example data");
        this.$store.dispatch("createExampleTools", this.user.id).then(() => this.$store.dispatch("createExampleSequences"), this.user.id);
      }
    },
    goDashboard() {
      if (this.$route.name !== 'Dashboard') {
        this.$router.push({ name: 'Dashboard'});
      }
    },
  }
};
</script>
