export default {
    "measures": {
        "070f8f50-35e3-11ea-bf03-bfa96037777e": {
            "defaultVideoEnd": "250",
            "defaultVideoId": "Ru_Qm68Yxpw",
            "defaultVideoStart": "200",
            "description": "Liebscher & Bracht empfehlen Schlafen in Rückenlage und Bauchlage und ohne Kissen, inbesondere ohne Nackenrolle.",
            "downvotes": 0,
            "firebaseid": "070f8f50-35e3-11ea-bf03-bfa96037777e",
            "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
            "imageSrc": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Flaming_June%2C_by_Frederic_Lord_Leighton_%281830-1896%29.jpg/480px-Flaming_June%2C_by_Frederic_Lord_Leighton_%281830-1896%29.jpg",
            "lastEditedBy": {
                "email": "mail@polowinski.de",
                "id": "mkbquY8FCNU9lG4L15ydGZi8z6Y2",
                "votings": {
                    "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                        "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    },
                    "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
                        "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                        "type": "measure",
                        "vote": 1
                    },
                    "17bb1a20-3621-11ea-bf03-bfa96037777e": {
                        "id": "17bb1a20-3621-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    },
                    "1c34d700-3714-11ea-bf03-bfa96037777e": {
                        "id": "1c34d700-3714-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    },
                    "3828ca90-74ed-11ea-ac2f-9d1b33a03834": {
                        "id": "3828ca90-74ed-11ea-ac2f-9d1b33a03834",
                        "type": "measure",
                        "vote": -1
                    },
                    "50aac390-85fd-11ea-b8ab-5589732d8728": {
                        "id": "50aac390-85fd-11ea-b8ab-5589732d8728",
                        "type": "measure",
                        "vote": -1
                    },
                    "6202fb50-00b3-11ea-acbf-4399ea40fa26": {
                        "id": "6202fb50-00b3-11ea-acbf-4399ea40fa26",
                        "type": "measure",
                        "vote": 1
                    },
                    "675fe560-3621-11ea-bf03-bfa96037777e": {
                        "id": "675fe560-3621-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    },
                    "8030f060-92f0-11ec-982e-8d5b5f26d663": {
                        "id": "8030f060-92f0-11ec-982e-8d5b5f26d663",
                        "type": "measure",
                        "vote": 1
                    },
                    "8def5fd0-8667-11ea-b6ee-119c10ce8be3": {
                        "id": "8def5fd0-8667-11ea-b6ee-119c10ce8be3",
                        "type": "measure",
                        "vote": -1
                    },
                    "948c3d50-f6ab-11e9-8b0b-c718f4158888": {
                        "id": "948c3d50-f6ab-11e9-8b0b-c718f4158888",
                        "type": "measure",
                        "vote": 1
                    },
                    "96649ef0-01b0-11ea-b2b0-0da2025906af": {
                        "id": "96649ef0-01b0-11ea-b2b0-0da2025906af",
                        "type": "measure",
                        "vote": 1
                    },
                    "9b502f10-e9fa-11e9-94a7-97260168418b": {
                        "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
                        "type": "measure",
                        "vote": 1
                    },
                    "9f4cee10-7284-11ea-90ee-d145525ddc72": {
                        "id": "9f4cee10-7284-11ea-90ee-d145525ddc72",
                        "type": "measure",
                        "vote": 1
                    },
                    "eafb5da0-6a88-11ea-a090-f5e944b75795": {
                        "id": "eafb5da0-6a88-11ea-a090-f5e944b75795",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Richtig Schlafen gegen Nackenschmerzen",
            "tags": [
                "schlaf",
                "liegen",
                "nacken"
            ],
            "upvotes": 4,
            "votes": 3
        },
        "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
            "defaultVideoEnd": 0,
            "defaultVideoId": "",
            "defaultVideoStart": 0,
            "description": "Das Schlafen auf der Seite führt zu einer Position, die dem Sitzen ähnelt. Um dies zu verhindern, wird vielfach empfohlen eine harte Matratze zu verwenden. Hier ist das Liegen auf der Seite so unbequem, dass man sich während der Nacht öfter einmal in eine andere Position begibt, zum Beispiel auf den Rücken oder auf den Bauch.",
            "downvotes": 0,
            "firebaseid": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
            "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
            "imageSrc": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/43/Albert_Anker_-_Schlafendes_M%C3%A4dchen_auf_einer_Holzbank.jpg/640px-Albert_Anker_-_Schlafendes_M%C3%A4dchen_auf_einer_Holzbank.jpg",
            "name": "Harte Matratze verwenden",
            "tags": [
                "schlafen",
                "liegen",
                "ergonomie"
            ],
            "upvotes": 3,
            "votes": 12
        },
        "1140d940-f62b-11e9-9edc-8fa03c46b765": {
            "defaultVideoEnd": "0",
            "defaultVideoId": "RMPCNcKSy3k",
            "defaultVideoStart": "34",
            "description": " Energie - Booster für Deinen Tag. Morgenroutine von Strong & Flex",
            "downvotes": 0,
            "firebaseid": "1140d940-f62b-11e9-9edc-8fa03c46b765",
            "id": "1140d940-f62b-11e9-9edc-8fa03c46b765",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F1140d940-f62b-11e9-9edc-8fa03c46b765.png?alt=media&token=a475c577-df1c-4a1e-b800-6c492dd4c933",
            "lastEditedBy": {
                "email": "mail@polowinski.de",
                "id": "mkbquY8FCNU9lG4L15ydGZi8z6Y2",
                "votings": {
                    "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                        "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    },
                    "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
                        "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                        "type": "measure",
                        "vote": 1
                    },
                    "1140d940-f62b-11e9-9edc-8fa03c46b765": {
                        "id": "1140d940-f62b-11e9-9edc-8fa03c46b765",
                        "type": "measure",
                        "vote": 1
                    },
                    "17bb1a20-3621-11ea-bf03-bfa96037777e": {
                        "id": "17bb1a20-3621-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    },
                    "3828ca90-74ed-11ea-ac2f-9d1b33a03834": {
                        "id": "3828ca90-74ed-11ea-ac2f-9d1b33a03834",
                        "type": "measure",
                        "vote": -1
                    },
                    "50aac390-85fd-11ea-b8ab-5589732d8728": {
                        "id": "50aac390-85fd-11ea-b8ab-5589732d8728",
                        "type": "measure",
                        "vote": -1
                    },
                    "6202fb50-00b3-11ea-acbf-4399ea40fa26": {
                        "id": "6202fb50-00b3-11ea-acbf-4399ea40fa26",
                        "type": "measure",
                        "vote": 1
                    },
                    "675fe560-3621-11ea-bf03-bfa96037777e": {
                        "id": "675fe560-3621-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    },
                    "8030f060-92f0-11ec-982e-8d5b5f26d663": {
                        "id": "8030f060-92f0-11ec-982e-8d5b5f26d663",
                        "type": "measure",
                        "vote": 1
                    },
                    "8def5fd0-8667-11ea-b6ee-119c10ce8be3": {
                        "id": "8def5fd0-8667-11ea-b6ee-119c10ce8be3",
                        "type": "measure",
                        "vote": -1
                    },
                    "948c3d50-f6ab-11e9-8b0b-c718f4158888": {
                        "id": "948c3d50-f6ab-11e9-8b0b-c718f4158888",
                        "type": "measure",
                        "vote": 1
                    },
                    "96649ef0-01b0-11ea-b2b0-0da2025906af": {
                        "id": "96649ef0-01b0-11ea-b2b0-0da2025906af",
                        "type": "measure",
                        "vote": 1
                    },
                    "9b502f10-e9fa-11e9-94a7-97260168418b": {
                        "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
                        "type": "measure",
                        "vote": 1
                    },
                    "9f4cee10-7284-11ea-90ee-d145525ddc72": {
                        "id": "9f4cee10-7284-11ea-90ee-d145525ddc72",
                        "type": "measure",
                        "vote": 1
                    },
                    "eafb5da0-6a88-11ea-a090-f5e944b75795": {
                        "id": "eafb5da0-6a88-11ea-a090-f5e944b75795",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "5 Tibeter - 13 - fache Dehnung",
            "tags": [
                "morgens",
                "gesamtprogramm",
                "dehnung"
            ],
            "upvotes": 0,
            "votes": 1
        },
        "17bb1a20-3621-11ea-bf03-bfa96037777e": {
            "defaultVideoEnd": "280",
            "defaultVideoId": "Nu9bTQKvhs8",
            "defaultVideoStart": "70",
            "description": "nach L&B",
            "downvotes": 1,
            "firebaseid": "17bb1a20-3621-11ea-bf03-bfa96037777e",
            "id": "17bb1a20-3621-11ea-bf03-bfa96037777e",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F17bb1a20-3621-11ea-bf03-bfa96037777e.png?alt=media&token=bfe19316-28f5-48c1-8ea8-d6abbdc85dea",
            "lastEditedBy": {
                "email": "mail@polowinski.de",
                "id": "mkbquY8FCNU9lG4L15ydGZi8z6Y2",
                "votings": {
                    "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
                        "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                        "type": "measure",
                        "vote": 1
                    },
                    "3828ca90-74ed-11ea-ac2f-9d1b33a03834": {
                        "id": "3828ca90-74ed-11ea-ac2f-9d1b33a03834",
                        "type": "measure",
                        "vote": -1
                    },
                    "50aac390-85fd-11ea-b8ab-5589732d8728": {
                        "id": "50aac390-85fd-11ea-b8ab-5589732d8728",
                        "type": "measure",
                        "vote": -1
                    },
                    "8def5fd0-8667-11ea-b6ee-119c10ce8be3": {
                        "id": "8def5fd0-8667-11ea-b6ee-119c10ce8be3",
                        "type": "measure",
                        "vote": -1
                    },
                    "96649ef0-01b0-11ea-b2b0-0da2025906af": {
                        "id": "96649ef0-01b0-11ea-b2b0-0da2025906af",
                        "type": "measure",
                        "vote": 1
                    },
                    "9b502f10-e9fa-11e9-94a7-97260168418b": {
                        "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
                        "type": "measure",
                        "vote": 1
                    },
                    "9f4cee10-7284-11ea-90ee-d145525ddc72": {
                        "id": "9f4cee10-7284-11ea-90ee-d145525ddc72",
                        "type": "measure",
                        "vote": 1
                    },
                    "eafb5da0-6a88-11ea-a090-f5e944b75795": {
                        "id": "eafb5da0-6a88-11ea-a090-f5e944b75795",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Nacken dehnen 45 Grad",
            "tags": [
                "nacken",
                "dehnen"
            ],
            "upvotes": 1,
            "votes": 6
        },
        "1c34d700-3714-11ea-bf03-bfa96037777e": {
            "defaultVideoEnd": "",
            "defaultVideoId": "4enqyb4faWk",
            "defaultVideoStart": "300",
            "description": "",
            "downvotes": 0,
            "firebaseid": "1c34d700-3714-11ea-bf03-bfa96037777e",
            "id": "1c34d700-3714-11ea-bf03-bfa96037777e",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F1c34d700-3714-11ea-bf03-bfa96037777e.png?alt=media&token=7f8e7111-2e01-450e-8d1a-a93d7e7ac2f7",
            "lastEditedBy": {
                "email": "mail@polowinski.de",
                "id": "mkbquY8FCNU9lG4L15ydGZi8z6Y2",
                "votings": {
                    "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
                        "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                        "type": "measure",
                        "vote": 1
                    },
                    "17bb1a20-3621-11ea-bf03-bfa96037777e": {
                        "id": "17bb1a20-3621-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    },
                    "3828ca90-74ed-11ea-ac2f-9d1b33a03834": {
                        "id": "3828ca90-74ed-11ea-ac2f-9d1b33a03834",
                        "type": "measure",
                        "vote": -1
                    },
                    "50aac390-85fd-11ea-b8ab-5589732d8728": {
                        "id": "50aac390-85fd-11ea-b8ab-5589732d8728",
                        "type": "measure",
                        "vote": -1
                    },
                    "8def5fd0-8667-11ea-b6ee-119c10ce8be3": {
                        "id": "8def5fd0-8667-11ea-b6ee-119c10ce8be3",
                        "type": "measure",
                        "vote": -1
                    },
                    "948c3d50-f6ab-11e9-8b0b-c718f4158888": {
                        "id": "948c3d50-f6ab-11e9-8b0b-c718f4158888",
                        "type": "measure",
                        "vote": 1
                    },
                    "96649ef0-01b0-11ea-b2b0-0da2025906af": {
                        "id": "96649ef0-01b0-11ea-b2b0-0da2025906af",
                        "type": "measure",
                        "vote": 1
                    },
                    "9b502f10-e9fa-11e9-94a7-97260168418b": {
                        "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
                        "type": "measure",
                        "vote": 1
                    },
                    "9f4cee10-7284-11ea-90ee-d145525ddc72": {
                        "id": "9f4cee10-7284-11ea-90ee-d145525ddc72",
                        "type": "measure",
                        "vote": 1
                    },
                    "eafb5da0-6a88-11ea-a090-f5e944b75795": {
                        "id": "eafb5da0-6a88-11ea-a090-f5e944b75795",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Rückenschmerzen lösen nach Luke Brandenburg",
            "tags": [
                "rücken",
                "schmerz"
            ],
            "upvotes": 1
        },
        "20b9ec10-92ee-11ec-982e-8d5b5f26d663": {
            "defaultVideoEnd": "637",
            "defaultVideoId": "J486Fn4Rhms",
            "defaultVideoStart": "30",
            "description": "Gabi Fastner, Faszientraining Morgenroutine: 10 min.",
            "downvotes": 0,
            "firebaseid": "20b9ec10-92ee-11ec-982e-8d5b5f26d663",
            "id": "20b9ec10-92ee-11ec-982e-8d5b5f26d663",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F20b9ec10-92ee-11ec-982e-8d5b5f26d663.JPG?alt=media&token=2a00f64f-fdf6-4e06-b7fa-0fccf11e3593",
            "lastEditedBy": {
                "email": "mail@polowinski.de",
                "id": "mkbquY8FCNU9lG4L15ydGZi8z6Y2",
                "votings": {
                    "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
                        "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                        "type": "measure",
                        "vote": 1
                    },
                    "17bb1a20-3621-11ea-bf03-bfa96037777e": {
                        "id": "17bb1a20-3621-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    },
                    "3828ca90-74ed-11ea-ac2f-9d1b33a03834": {
                        "id": "3828ca90-74ed-11ea-ac2f-9d1b33a03834",
                        "type": "measure",
                        "vote": -1
                    },
                    "50aac390-85fd-11ea-b8ab-5589732d8728": {
                        "id": "50aac390-85fd-11ea-b8ab-5589732d8728",
                        "type": "measure",
                        "vote": -1
                    },
                    "8def5fd0-8667-11ea-b6ee-119c10ce8be3": {
                        "id": "8def5fd0-8667-11ea-b6ee-119c10ce8be3",
                        "type": "measure",
                        "vote": -1
                    },
                    "948c3d50-f6ab-11e9-8b0b-c718f4158888": {
                        "id": "948c3d50-f6ab-11e9-8b0b-c718f4158888",
                        "type": "measure",
                        "vote": 1
                    },
                    "96649ef0-01b0-11ea-b2b0-0da2025906af": {
                        "id": "96649ef0-01b0-11ea-b2b0-0da2025906af",
                        "type": "measure",
                        "vote": 1
                    },
                    "9b502f10-e9fa-11e9-94a7-97260168418b": {
                        "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
                        "type": "measure",
                        "vote": 1
                    },
                    "9f4cee10-7284-11ea-90ee-d145525ddc72": {
                        "id": "9f4cee10-7284-11ea-90ee-d145525ddc72",
                        "type": "measure",
                        "vote": 1
                    },
                    "eafb5da0-6a88-11ea-a090-f5e944b75795": {
                        "id": "eafb5da0-6a88-11ea-a090-f5e944b75795",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Morgengymnastik als Faszientraining, ohne Geräte",
            "tags": [
                "faszien ",
                "nacken ",
                "rücken ",
                "schulter",
                "gesamtprogramm"
            ],
            "upvotes": 0
        },
        "2c36bca0-ffe1-11e9-97c8-d53d9dd837e2": {
            "defaultVideoEnd": "446",
            "defaultVideoId": "ngZrNjPcAwQ",
            "defaultVideoStart": "92",
            "description": "Morgenroutine von Einfach besser leben",
            "firebaseid": "2c36bca0-ffe1-11e9-97c8-d53d9dd837e2",
            "id": "2c36bca0-ffe1-11e9-97c8-d53d9dd837e2",
            "name": "Morgenroutine 2",
            "tags": [
                "morgens",
                "gesamtprogramm"
            ],
            "votes": 4
        },
        "32c5e880-92f0-11ec-982e-8d5b5f26d663": {
            "defaultVideoEnd": "1127",
            "defaultVideoId": "B0QFlk14T1A",
            "defaultVideoStart": "842",
            "description": "Die besten Nackenübungen - Nackenverspannungen lösen",
            "id": "32c5e880-92f0-11ec-982e-8d5b5f26d663",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F32c5e880-92f0-11ec-982e-8d5b5f26d663.JPG?alt=media&token=0ef1a75b-fe40-4f7b-bdb4-a279130e56c9",
            "lastEditedBy": {
                "email": "dennis.gue@web.de",
                "id": "mBRvCdgko4PAoNUd2qyTvgFHUPc2",
                "votings": {
                    "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                        "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Nackenverspannung lösen + Selbstmassage",
            "tags": [
                "Nacken",
                "Halswirbelsäule ",
                "Selbstmassage",
                "Massage"
            ]
        },
        "3b3b3430-f508-11e9-8439-adab4f265efd": {
            "defaultVideoEnd": "0",
            "defaultVideoId": "JlbQUjJzO6o",
            "defaultVideoStart": "7",
            "description": "Progressive Muskelentspannung (auch PMR für engl. Progressive Muskel Relaxation) ist eine Entspannungstechnik nach Jacobsen. ",
            "downvotes": 0,
            "firebaseid": "3b3b3430-f508-11e9-8439-adab4f265efd",
            "id": "3b3b3430-f508-11e9-8439-adab4f265efd",
            "lastEditedBy": {
                "email": "urbansky@hxcode.io",
                "id": "fYabiCIeYhZJj74gLiVOt2iy3yO2"
            },
            "name": "Progressive Muskelentspannung - Stefan",
            "tags": [
                "entspannung",
                "psyche",
                "stress",
                "liegen"
            ],
            "upvotes": 1,
            "video": {
                "id": "",
                "name": "",
                "start": 0
            }
        },
        "42084500-92ef-11ec-982e-8d5b5f26d663": {
            "defaultVideoEnd": "890",
            "defaultVideoId": "ajFg46J7wpg",
            "defaultVideoStart": "675",
            "description": "4 Übungen Skoliose gegen Deine Beschwerden ➕ Selbsttest und Ursachen",
            "id": "42084500-92ef-11ec-982e-8d5b5f26d663",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F42084500-92ef-11ec-982e-8d5b5f26d663.JPG?alt=media&token=c6f19c4f-8111-4853-9946-548444dbd958",
            "lastEditedBy": {
                "email": "dennis.gue@web.de",
                "id": "mBRvCdgko4PAoNUd2qyTvgFHUPc2",
                "votings": {
                    "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                        "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Übungen gegen Skoliose",
            "tags": [
                "Skoliose",
                "rücken ",
                "rückentraining "
            ]
        },
        "485c0390-827f-11ec-8f7f-59dfb0f6c04f": {
            "defaultVideoEnd": "530",
            "defaultVideoId": "K3ozV9QI3xM",
            "defaultVideoStart": "120",
            "description": "Entspannung der vorderen Oberschenkelmuskulatur",
            "id": "485c0390-827f-11ec-8f7f-59dfb0f6c04f",
            "name": "Detonisierung M. Quadtrizeps Femoris"
        },
        "5cbcaed0-01b1-11ea-b2b0-0da2025906af": {
            "defaultVideoEnd": 0,
            "defaultVideoId": "",
            "defaultVideoStart": 0,
            "description": "Neben der Nutzung der normalen Einstellungsmöglichkeiten eines Bürostuhls, kann auch das Sitzen in der Hocke, im Knien oder im Schneidersitz in die tägliche Arbeit integriert werden.",
            "firebaseid": "5cbcaed0-01b1-11ea-b2b0-0da2025906af",
            "id": "5cbcaed0-01b1-11ea-b2b0-0da2025906af",
            "name": "Sitzpositionen variieren",
            "tags": [
                "verhalten",
                "ergonomie",
                "sitzen"
            ],
            "votes": 1
        },
        "6202fb50-00b3-11ea-acbf-4399ea40fa26": {
            "defaultVideoEnd": 0,
            "defaultVideoId": "WOb82ta2P_8",
            "defaultVideoStart": "14",
            "description": "Ein Favorit für den Alltag",
            "downvotes": 0,
            "firebaseid": "6202fb50-00b3-11ea-acbf-4399ea40fa26",
            "id": "6202fb50-00b3-11ea-acbf-4399ea40fa26",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F6202fb50-00b3-11ea-acbf-4399ea40fa26.png?alt=media&token=06d35a4d-e89f-4c8f-938a-65f09d5004a2",
            "lastEditedBy": {
                "email": "mail@polowinski.de",
                "id": "mkbquY8FCNU9lG4L15ydGZi8z6Y2",
                "votings": {
                    "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
                        "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                        "type": "measure",
                        "vote": 1
                    },
                    "3828ca90-74ed-11ea-ac2f-9d1b33a03834": {
                        "id": "3828ca90-74ed-11ea-ac2f-9d1b33a03834",
                        "type": "measure",
                        "vote": -1
                    },
                    "50aac390-85fd-11ea-b8ab-5589732d8728": {
                        "id": "50aac390-85fd-11ea-b8ab-5589732d8728",
                        "type": "measure",
                        "vote": -1
                    },
                    "8def5fd0-8667-11ea-b6ee-119c10ce8be3": {
                        "id": "8def5fd0-8667-11ea-b6ee-119c10ce8be3",
                        "type": "measure",
                        "vote": -1
                    },
                    "96649ef0-01b0-11ea-b2b0-0da2025906af": {
                        "id": "96649ef0-01b0-11ea-b2b0-0da2025906af",
                        "type": "measure",
                        "vote": 1
                    },
                    "9b502f10-e9fa-11e9-94a7-97260168418b": {
                        "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
                        "type": "measure",
                        "vote": 1
                    },
                    "9f4cee10-7284-11ea-90ee-d145525ddc72": {
                        "id": "9f4cee10-7284-11ea-90ee-d145525ddc72",
                        "type": "measure",
                        "vote": 1
                    },
                    "eafb5da0-6a88-11ea-a090-f5e944b75795": {
                        "id": "eafb5da0-6a88-11ea-a090-f5e944b75795",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Nackenyoga",
            "tags": [
                "nacken",
                "yoga",
                "gesamtprogramm"
            ],
            "upvotes": 1
        },
        "675fe560-3621-11ea-bf03-bfa96037777e": {
            "defaultVideoEnd": "476",
            "defaultVideoId": "Nu9bTQKvhs8",
            "defaultVideoStart": "285",
            "description": "Ähnlich Dehnübung an der Wand",
            "downvotes": 0,
            "firebaseid": "675fe560-3621-11ea-bf03-bfa96037777e",
            "id": "675fe560-3621-11ea-bf03-bfa96037777e",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F675fe560-3621-11ea-bf03-bfa96037777e.png?alt=media&token=768701bc-642d-4449-add3-30032608f053",
            "lastEditedBy": {
                "email": "mail@polowinski.de",
                "id": "mkbquY8FCNU9lG4L15ydGZi8z6Y2",
                "votings": {
                    "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
                        "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                        "type": "measure",
                        "vote": 1
                    },
                    "3828ca90-74ed-11ea-ac2f-9d1b33a03834": {
                        "id": "3828ca90-74ed-11ea-ac2f-9d1b33a03834",
                        "type": "measure",
                        "vote": -1
                    },
                    "50aac390-85fd-11ea-b8ab-5589732d8728": {
                        "id": "50aac390-85fd-11ea-b8ab-5589732d8728",
                        "type": "measure",
                        "vote": -1
                    },
                    "8def5fd0-8667-11ea-b6ee-119c10ce8be3": {
                        "id": "8def5fd0-8667-11ea-b6ee-119c10ce8be3",
                        "type": "measure",
                        "vote": -1
                    },
                    "96649ef0-01b0-11ea-b2b0-0da2025906af": {
                        "id": "96649ef0-01b0-11ea-b2b0-0da2025906af",
                        "type": "measure",
                        "vote": 1
                    },
                    "9b502f10-e9fa-11e9-94a7-97260168418b": {
                        "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
                        "type": "measure",
                        "vote": 1
                    },
                    "9f4cee10-7284-11ea-90ee-d145525ddc72": {
                        "id": "9f4cee10-7284-11ea-90ee-d145525ddc72",
                        "type": "measure",
                        "vote": 1
                    },
                    "eafb5da0-6a88-11ea-a090-f5e944b75795": {
                        "id": "eafb5da0-6a88-11ea-a090-f5e944b75795",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Nacken dehnen ohne Wand",
            "tags": [
                "nacken",
                "dehnen"
            ],
            "upvotes": 1
        },
        "6a6ac220-f43b-11e9-a1cf-c7a7a610c511": {
            "defaultVideoId": "ed3dw_TPHzQ",
            "defaultVideoStart": "80",
            "description": "Alle Übungen hier sind im Liegen. Die Arme auf der Seite liegend abwechselnd leicht ausstrecken.",
            "firebaseid": "6a6ac220-f43b-11e9-a1cf-c7a7a610c511",
            "id": "6a6ac220-f43b-11e9-a1cf-c7a7a610c511",
            "name": "Feldenkrais - Relief for Neck and Shoulders",
            "tags": [
                "gesamtprogramm",
                "feldenkrais"
            ],
            "video": {
                "id": "",
                "name": "",
                "start": 0
            },
            "votes": 1
        },
        "6cd3c180-00e1-11ea-9fae-b7f240e9f262": {
            "defaultVideoEnd": 0,
            "defaultVideoId": "",
            "defaultVideoStart": 0,
            "description": "Kissen aus Memoryschaum, besser noch aus Getreidespelzen, deformieren, um sich der Form des Liegenden anzupassen ohne federnden Gegendruck aufzubauen.",
            "firebaseid": "6cd3c180-00e1-11ea-9fae-b7f240e9f262",
            "id": "6cd3c180-00e1-11ea-9fae-b7f240e9f262",
            "imageSrc": "https://cdn.pixabay.com/photo/2014/02/26/13/19/sorghum-275257_1280.jpg",
            "name": "Nicht-elastisches Kissen verwenden",
            "tags": [
                "ergonomie",
                "schlafen"
            ],
            "votes": 0
        },
        "703229f0-f508-11e9-8439-adab4f265efd": {
            "defaultVideoEnd": "0",
            "defaultVideoId": "XR0vL8GH8Ow",
            "defaultVideoStart": "3",
            "description": "Wenn Hüfte nach vorn kippt (siehe Bild): Hüfte nach hinten kippen, Bauchnabel einziehen und und einatmen in die obere Brust. Dies ergibt automatisch eine aufrechte Haltung. Tamay Jentjens erklärt, den Unterschied zwischen anterior und posterior pelvic tilt.",
            "firebaseid": "703229f0-f508-11e9-8439-adab4f265efd",
            "id": "703229f0-f508-11e9-8439-adab4f265efd",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F703229f0-f508-11e9-8439-adab4f265efd.png?alt=media&token=58aa2c57-923a-4b77-bbce-f4b61aa3a1c5",
            "lastEditedBy": {
                "email": "jan@polowinski.de",
                "id": "PbTu9hyBo7OtkLmeVHbCYncDcYz1"
            },
            "name": "Hüfte nach hinten kippen",
            "tags": [
                "haltung",
                "hüfte",
                "brust",
                "atmung"
            ],
            "video": {
                "id": "",
                "name": "",
                "start": 0
            },
            "votes": 1
        },
        "78a47ef0-92f2-11ec-982e-8d5b5f26d663": {
            "defaultVideoEnd": "335",
            "defaultVideoId": "0N73ubE2vgw",
            "defaultVideoStart": "290",
            "description": "7 Minuten Bauchmuskeltraining für Anfänger - Effektive Übungen für zu Hause ohne Geräte!",
            "id": "78a47ef0-92f2-11ec-982e-8d5b5f26d663",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F78a47ef0-92f2-11ec-982e-8d5b5f26d663.JPG?alt=media&token=099bb484-67e0-4782-8523-52a04ee0e64d",
            "lastEditedBy": {
                "email": "dennis.gue@web.de",
                "id": "mBRvCdgko4PAoNUd2qyTvgFHUPc2",
                "votings": {
                    "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                        "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Bauchmuskeltraining für Anfänger",
            "tags": [
                "Bauchmuskeltraining",
                "Rückenschmerzen",
                "Kräftigung"
            ]
        },
        "7fd87ab0-92f1-11ec-982e-8d5b5f26d663": {
            "defaultVideoEnd": "46",
            "defaultVideoId": "WKCUnQv1GFk",
            "defaultVideoStart": "18",
            "description": "Brustwirbelblockaden in nur 60 Sekunden lösen!",
            "firebaseid": "7fd87ab0-92f1-11ec-982e-8d5b5f26d663",
            "id": "7fd87ab0-92f1-11ec-982e-8d5b5f26d663",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F7fd87ab0-92f1-11ec-982e-8d5b5f26d663.JPG?alt=media&token=f2114e26-317f-4ce5-946f-1313d3fc81df",
            "lastEditedBy": {
                "email": "mail@polowinski.de",
                "id": "mkbquY8FCNU9lG4L15ydGZi8z6Y2",
                "votings": {
                    "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
                        "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                        "type": "measure",
                        "vote": 1
                    },
                    "17bb1a20-3621-11ea-bf03-bfa96037777e": {
                        "id": "17bb1a20-3621-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    },
                    "3828ca90-74ed-11ea-ac2f-9d1b33a03834": {
                        "id": "3828ca90-74ed-11ea-ac2f-9d1b33a03834",
                        "type": "measure",
                        "vote": -1
                    },
                    "50aac390-85fd-11ea-b8ab-5589732d8728": {
                        "id": "50aac390-85fd-11ea-b8ab-5589732d8728",
                        "type": "measure",
                        "vote": -1
                    },
                    "8def5fd0-8667-11ea-b6ee-119c10ce8be3": {
                        "id": "8def5fd0-8667-11ea-b6ee-119c10ce8be3",
                        "type": "measure",
                        "vote": -1
                    },
                    "948c3d50-f6ab-11e9-8b0b-c718f4158888": {
                        "id": "948c3d50-f6ab-11e9-8b0b-c718f4158888",
                        "type": "measure",
                        "vote": 1
                    },
                    "96649ef0-01b0-11ea-b2b0-0da2025906af": {
                        "id": "96649ef0-01b0-11ea-b2b0-0da2025906af",
                        "type": "measure",
                        "vote": 1
                    },
                    "9b502f10-e9fa-11e9-94a7-97260168418b": {
                        "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
                        "type": "measure",
                        "vote": 1
                    },
                    "9f4cee10-7284-11ea-90ee-d145525ddc72": {
                        "id": "9f4cee10-7284-11ea-90ee-d145525ddc72",
                        "type": "measure",
                        "vote": 1
                    },
                    "eafb5da0-6a88-11ea-a090-f5e944b75795": {
                        "id": "eafb5da0-6a88-11ea-a090-f5e944b75795",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Brustwirbelsäulen-Blockade lösen",
            "tags": [
                "brustwirbelsäule",
                "blockade",
                "mobilisation"
            ]
        },
        "8030f060-92f0-11ec-982e-8d5b5f26d663": {
            "defaultVideoEnd": "320",
            "defaultVideoId": "IzzL9e9wsLs",
            "defaultVideoStart": "210",
            "description": "3 effektive Übungen gegen eine LWS-Blockade",
            "downvotes": 0,
            "firebaseid": "8030f060-92f0-11ec-982e-8d5b5f26d663",
            "id": "8030f060-92f0-11ec-982e-8d5b5f26d663",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F8030f060-92f0-11ec-982e-8d5b5f26d663.JPG?alt=media&token=4ec03dac-ccaa-4140-8d34-d4f6cadd35db",
            "lastEditedBy": {
                "email": "dennis.gue@web.de",
                "id": "mBRvCdgko4PAoNUd2qyTvgFHUPc2",
                "votings": {
                    "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                        "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "LWS Blockade lösen",
            "tags": [
                "Lendenwirbelsäule ",
                "Blockade "
            ],
            "upvotes": 1
        },
        "852e1680-ffe3-11e9-ad38-ab6d1937ba12": {
            "defaultVideoEnd": 0,
            "defaultVideoId": "Cr_wAIDqUbo",
            "defaultVideoStart": "10",
            "description": "Übung mit der Black Roll, hier von Strong & Flex",
            "firebaseid": "852e1680-ffe3-11e9-ad38-ab6d1937ba12",
            "id": "852e1680-ffe3-11e9-ad38-ab6d1937ba12",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F852e1680-ffe3-11e9-ad38-ab6d1937ba12.png?alt=media&token=a5ccf662-915d-47e4-b6c9-4d377a61e558",
            "lastEditedBy": {
                "email": "mail@polowinski.de",
                "id": "mkbquY8FCNU9lG4L15ydGZi8z6Y2",
                "votings": {
                    "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
                        "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                        "type": "measure",
                        "vote": 1
                    },
                    "17bb1a20-3621-11ea-bf03-bfa96037777e": {
                        "id": "17bb1a20-3621-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    },
                    "3828ca90-74ed-11ea-ac2f-9d1b33a03834": {
                        "id": "3828ca90-74ed-11ea-ac2f-9d1b33a03834",
                        "type": "measure",
                        "vote": -1
                    },
                    "50aac390-85fd-11ea-b8ab-5589732d8728": {
                        "id": "50aac390-85fd-11ea-b8ab-5589732d8728",
                        "type": "measure",
                        "vote": -1
                    },
                    "8def5fd0-8667-11ea-b6ee-119c10ce8be3": {
                        "id": "8def5fd0-8667-11ea-b6ee-119c10ce8be3",
                        "type": "measure",
                        "vote": -1
                    },
                    "948c3d50-f6ab-11e9-8b0b-c718f4158888": {
                        "id": "948c3d50-f6ab-11e9-8b0b-c718f4158888",
                        "type": "measure",
                        "vote": 1
                    },
                    "96649ef0-01b0-11ea-b2b0-0da2025906af": {
                        "id": "96649ef0-01b0-11ea-b2b0-0da2025906af",
                        "type": "measure",
                        "vote": 1
                    },
                    "9b502f10-e9fa-11e9-94a7-97260168418b": {
                        "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
                        "type": "measure",
                        "vote": 1
                    },
                    "9f4cee10-7284-11ea-90ee-d145525ddc72": {
                        "id": "9f4cee10-7284-11ea-90ee-d145525ddc72",
                        "type": "measure",
                        "vote": 1
                    },
                    "eafb5da0-6a88-11ea-a090-f5e944b75795": {
                        "id": "eafb5da0-6a88-11ea-a090-f5e944b75795",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Brustwirbelsäule öffnen",
            "tags": [
                "brustwirbelsäule",
                "abends",
                "mobilisation"
            ]
        },
        "8db8a020-361d-11ea-bf03-bfa96037777e": {
            "defaultVideoEnd": "433",
            "defaultVideoId": "lJX4129XgcU",
            "defaultVideoStart": "50",
            "description": "nach L&B",
            "firebaseid": "8db8a020-361d-11ea-bf03-bfa96037777e",
            "id": "8db8a020-361d-11ea-bf03-bfa96037777e",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F8db8a020-361d-11ea-bf03-bfa96037777e.png?alt=media&token=a6a23e4a-26e0-4fa0-b7ed-7ec25cbbfee2",
            "lastEditedBy": {
                "email": "mail@polowinski.de",
                "id": "mkbquY8FCNU9lG4L15ydGZi8z6Y2",
                "votings": {
                    "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
                        "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                        "type": "measure",
                        "vote": 1
                    },
                    "3828ca90-74ed-11ea-ac2f-9d1b33a03834": {
                        "id": "3828ca90-74ed-11ea-ac2f-9d1b33a03834",
                        "type": "measure",
                        "vote": -1
                    },
                    "50aac390-85fd-11ea-b8ab-5589732d8728": {
                        "id": "50aac390-85fd-11ea-b8ab-5589732d8728",
                        "type": "measure",
                        "vote": -1
                    },
                    "8def5fd0-8667-11ea-b6ee-119c10ce8be3": {
                        "id": "8def5fd0-8667-11ea-b6ee-119c10ce8be3",
                        "type": "measure",
                        "vote": -1
                    },
                    "96649ef0-01b0-11ea-b2b0-0da2025906af": {
                        "id": "96649ef0-01b0-11ea-b2b0-0da2025906af",
                        "type": "measure",
                        "vote": 1
                    },
                    "9b502f10-e9fa-11e9-94a7-97260168418b": {
                        "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
                        "type": "measure",
                        "vote": 1
                    },
                    "9f4cee10-7284-11ea-90ee-d145525ddc72": {
                        "id": "9f4cee10-7284-11ea-90ee-d145525ddc72",
                        "type": "measure",
                        "vote": 1
                    },
                    "eafb5da0-6a88-11ea-a090-f5e944b75795": {
                        "id": "eafb5da0-6a88-11ea-a090-f5e944b75795",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Nackenverspannung lösen an der Wand",
            "tags": [
                "nacken",
                "dehnen",
                "wand"
            ]
        },
        "948c3d50-f6ab-11e9-8b0b-c718f4158888": {
            "defaultVideoId": "",
            "defaultVideoStart": 0,
            "description": "Ausrollen des Rückens entlang der Wirbelsäule, z.B. mit einer Black Roll Duo ball",
            "downvotes": 0,
            "firebaseid": "948c3d50-f6ab-11e9-8b0b-c718f4158888",
            "id": "948c3d50-f6ab-11e9-8b0b-c718f4158888",
            "name": "Rollen entlang der Wirbelsäule",
            "tags": [
                "ausrollen",
                "rücken",
                "lendenwirbelsäule",
                "abends"
            ],
            "upvotes": 1
        },
        "961097e0-0658-11ea-b200-f9931f6c73dc": {
            "defaultVideoEnd": "1260",
            "defaultVideoId": "MDGd-dlUMkM",
            "defaultVideoStart": "342",
            "description": "",
            "firebaseid": "961097e0-0658-11ea-b200-f9931f6c73dc",
            "id": "961097e0-0658-11ea-b200-f9931f6c73dc",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F961097e0-0658-11ea-b200-f9931f6c73dc.png?alt=media&token=76adb934-0d59-468e-bca1-1f4bd2e1b93d",
            "lastEditedBy": {
                "email": "mail@polowinski.de",
                "id": "mkbquY8FCNU9lG4L15ydGZi8z6Y2",
                "votings": {
                    "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
                        "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                        "type": "measure",
                        "vote": 1
                    },
                    "3828ca90-74ed-11ea-ac2f-9d1b33a03834": {
                        "id": "3828ca90-74ed-11ea-ac2f-9d1b33a03834",
                        "type": "measure",
                        "vote": -1
                    },
                    "50aac390-85fd-11ea-b8ab-5589732d8728": {
                        "id": "50aac390-85fd-11ea-b8ab-5589732d8728",
                        "type": "measure",
                        "vote": -1
                    },
                    "8def5fd0-8667-11ea-b6ee-119c10ce8be3": {
                        "id": "8def5fd0-8667-11ea-b6ee-119c10ce8be3",
                        "type": "measure",
                        "vote": -1
                    },
                    "96649ef0-01b0-11ea-b2b0-0da2025906af": {
                        "id": "96649ef0-01b0-11ea-b2b0-0da2025906af",
                        "type": "measure",
                        "vote": 1
                    },
                    "9b502f10-e9fa-11e9-94a7-97260168418b": {
                        "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
                        "type": "measure",
                        "vote": 1
                    },
                    "9f4cee10-7284-11ea-90ee-d145525ddc72": {
                        "id": "9f4cee10-7284-11ea-90ee-d145525ddc72",
                        "type": "measure",
                        "vote": 1
                    },
                    "eafb5da0-6a88-11ea-a090-f5e944b75795": {
                        "id": "eafb5da0-6a88-11ea-a090-f5e944b75795",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Akkupressur Nacken",
            "tags": [
                "nacken",
                "gesamtprogramm",
                "akkupressur"
            ],
            "votes": 1
        },
        "99a48da0-92ef-11ec-982e-8d5b5f26d663": {
            "defaultVideoEnd": "215",
            "defaultVideoId": "ajFg46J7wpg",
            "defaultVideoStart": "99",
            "description": "4 Skoliose Übungen gegen Deine Beschwerden ➕ Selbsttest und Ursachen",
            "downvotes": 0,
            "firebaseid": "99a48da0-92ef-11ec-982e-8d5b5f26d663",
            "id": "99a48da0-92ef-11ec-982e-8d5b5f26d663",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F99a48da0-92ef-11ec-982e-8d5b5f26d663.JPG?alt=media&token=bdf0290b-8443-4a51-9f1e-16ff04ac7e71",
            "lastEditedBy": {
                "email": "dennis.gue@web.de",
                "id": "mBRvCdgko4PAoNUd2qyTvgFHUPc2",
                "votings": {
                    "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                        "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Selbsttest Skoliose ",
            "tags": [
                "Skoliose ",
                "Selbsttest ",
                "Rückentraining "
            ],
            "upvotes": 1
        },
        "9b502f10-e9fa-11e9-94a7-97260168418b": {
            "defaultVideoEnd": "640",
            "defaultVideoId": "Yxb5HgG8oWA",
            "defaultVideoStart": "127",
            "description": "Schönes Kurzprogramm",
            "downvotes": 0,
            "firebaseid": "9b502f10-e9fa-11e9-94a7-97260168418b",
            "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2F9b502f10-e9fa-11e9-94a7-97260168418b.png?alt=media&token=2a1f2f59-c13b-440a-a508-458aefb3ec02",
            "name": "Übung 3 - Nackenverspannungen lösen in nur 8 Minuten",
            "tags": [
                "gesamtprogramm",
                "nacken",
                "lindern"
            ],
            "upvotes": 2,
            "video": {
                "id": "Yxb5HgG8oWA",
                "name": "Abschnitt - Kopf seitlich rotieren",
                "start": 126
            }
        },
        "a2bd1920-92f4-11ec-982e-8d5b5f26d663": {
            "defaultVideoEnd": "210",
            "defaultVideoId": "EKJoeNhkNzU",
            "defaultVideoStart": "169",
            "description": "Rückentraining für zu Hause | Übungen ohne Geräte | Fitness & Kraftsport | Sport-Thieme",
            "id": "a2bd1920-92f4-11ec-982e-8d5b5f26d663",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2Fa2bd1920-92f4-11ec-982e-8d5b5f26d663.JPG?alt=media&token=5900367a-ff8a-4fc5-9b63-7219efd1f0a4",
            "lastEditedBy": {
                "email": "dennis.gue@web.de",
                "id": "mBRvCdgko4PAoNUd2qyTvgFHUPc2",
                "votings": {
                    "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                        "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Rückentraining / Rumpfstabilität in Seitlage",
            "tags": [
                "Rückentraining",
                "Rücken ",
                "Schmerzen",
                "Rumpfstabilität"
            ]
        },
        "a9dcba70-f0db-11e9-abf8-f1fd9fb7935f": {
            "description": "To be done",
            "firebaseid": "a9dcba70-f0db-11e9-abf8-f1fd9fb7935f",
            "id": "a9dcba70-f0db-11e9-abf8-f1fd9fb7935f",
            "name": "Nacken im Liegen dehnen",
            "tags": [
                "lindern",
                "nacken",
                "dehnen",
                "liegen"
            ],
            "video": {
                "id": "",
                "name": "",
                "start": 0
            }
        },
        "b91b5330-f508-11e9-8439-adab4f265efd": {
            "description": "Eine mögliche andere Haltung ist es, sich umgekehrt auf den Stuhl zu setzen.",
            "firebaseid": "b91b5330-f508-11e9-8439-adab4f265efd",
            "id": "b91b5330-f508-11e9-8439-adab4f265efd",
            "name": "Umgekehrt auf den Stuhl setzen",
            "tags": [
                "verhalten",
                "sitzen",
                "ergonomie"
            ],
            "video": {
                "id": "",
                "name": "",
                "start": 0
            },
            "votes": 1
        },
        "baf13ad0-f6ac-11e9-9e74-65692e2dc62a": {
            "defaultVideoId": "",
            "defaultVideoStart": "540",
            "description": "Ausrollen der Beine ab dem Knie aufwärts",
            "firebaseid": "baf13ad0-f6ac-11e9-9e74-65692e2dc62a",
            "id": "baf13ad0-f6ac-11e9-9e74-65692e2dc62a",
            "name": "Ausrollen der Beine ",
            "tags": [
                "beine",
                "ausrollen",
                "abends"
            ]
        },
        "c9bee520-92f0-11ec-982e-8d5b5f26d663": {
            "defaultVideoEnd": "1700",
            "defaultVideoId": "hB7ob9X5BGc",
            "defaultVideoStart": "1540",
            "description": "Yoga gegen Rückenschmerzen | Starker und Gesunder Rücken | Übungen für Zuhause",
            "id": "c9bee520-92f0-11ec-982e-8d5b5f26d663",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2Fc9bee520-92f0-11ec-982e-8d5b5f26d663.JPG?alt=media&token=21d9e9a5-07ab-4cc6-92cd-382944ca0292",
            "lastEditedBy": {
                "email": "dennis.gue@web.de",
                "id": "mBRvCdgko4PAoNUd2qyTvgFHUPc2",
                "votings": {
                    "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                        "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Yoga gegen Rückenschmerzen",
            "tags": [
                "Yoga ",
                "Rücken ",
                "Rückentraining ",
                "Schmerzlinderung "
            ]
        },
        "df187e50-361c-11ea-bf03-bfa96037777e": {
            "defaultVideoEnd": "726",
            "defaultVideoId": "B7FnT0amuzY",
            "defaultVideoStart": "102",
            "description": "Kiefer mit Faszienrolle bearbeiten und dehnen nach L&B",
            "firebaseid": "df187e50-361c-11ea-bf03-bfa96037777e",
            "id": "df187e50-361c-11ea-bf03-bfa96037777e",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2Fdf187e50-361c-11ea-bf03-bfa96037777e.png?alt=media&token=6e59d6a9-3945-4149-b14f-d9656aa223fe",
            "lastEditedBy": {
                "email": "mail@polowinski.de",
                "id": "mkbquY8FCNU9lG4L15ydGZi8z6Y2",
                "votings": {
                    "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
                        "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                        "type": "measure",
                        "vote": 1
                    },
                    "3828ca90-74ed-11ea-ac2f-9d1b33a03834": {
                        "id": "3828ca90-74ed-11ea-ac2f-9d1b33a03834",
                        "type": "measure",
                        "vote": -1
                    },
                    "50aac390-85fd-11ea-b8ab-5589732d8728": {
                        "id": "50aac390-85fd-11ea-b8ab-5589732d8728",
                        "type": "measure",
                        "vote": -1
                    },
                    "8def5fd0-8667-11ea-b6ee-119c10ce8be3": {
                        "id": "8def5fd0-8667-11ea-b6ee-119c10ce8be3",
                        "type": "measure",
                        "vote": -1
                    },
                    "96649ef0-01b0-11ea-b2b0-0da2025906af": {
                        "id": "96649ef0-01b0-11ea-b2b0-0da2025906af",
                        "type": "measure",
                        "vote": 1
                    },
                    "9b502f10-e9fa-11e9-94a7-97260168418b": {
                        "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
                        "type": "measure",
                        "vote": 1
                    },
                    "9f4cee10-7284-11ea-90ee-d145525ddc72": {
                        "id": "9f4cee10-7284-11ea-90ee-d145525ddc72",
                        "type": "measure",
                        "vote": 1
                    },
                    "eafb5da0-6a88-11ea-a090-f5e944b75795": {
                        "id": "eafb5da0-6a88-11ea-a090-f5e944b75795",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Kiefer dehnen",
            "tags": [
                "kiefer",
                "dehnen"
            ]
        },
        "e5381480-478d-11ea-a714-af54907cf3f8": {
            "defaultVideoEnd": 0,
            "defaultVideoId": "",
            "defaultVideoStart": 0,
            "description": "Kinderwagen von unten greifen beim Schieben. Dadurch kann man sich nicht aufstützen und vermeidet so eine starre Haltung und ein Zusammensinken.",
            "firebaseid": "e5381480-478d-11ea-a714-af54907cf3f8",
            "id": "e5381480-478d-11ea-a714-af54907cf3f8",
            "imageSrc": "https://upload.wikimedia.org/wikipedia/commons/5/56/Modeles_brouettes.jpg",
            "name": "Kinderwagen richtig schieben",
            "votes": 84
        },
        "e7712b90-92f1-11ec-982e-8d5b5f26d663": {
            "defaultVideoEnd": "175",
            "defaultVideoId": "Q5yXCJvbZ44",
            "defaultVideoStart": "46",
            "description": "5 effektive Kraft und Stabilisationsübungen ohne Geräte für Läufer",
            "id": "e7712b90-92f1-11ec-982e-8d5b5f26d663",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2Fe7712b90-92f1-11ec-982e-8d5b5f26d663.JPG?alt=media&token=2576985f-2127-4504-9666-1209c8637b65",
            "lastEditedBy": {
                "email": "dennis.gue@web.de",
                "id": "mBRvCdgko4PAoNUd2qyTvgFHUPc2",
                "votings": {
                    "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                        "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Stabilisationsübung Rumpf",
            "tags": [
                "Stabilisierung",
                "Rumpf",
                "Core ",
                "Rückenschmerzen"
            ]
        },
        "e84f8f90-92ef-11ec-982e-8d5b5f26d663": {
            "defaultVideoEnd": "375",
            "defaultVideoId": "9tmyrn3bbyU",
            "defaultVideoStart": "115",
            "description": "HWS-Syndrom: Mobilisiere und stabilisiere Deine Halswirbelsäule mit diesen 3 Übungen",
            "id": "e84f8f90-92ef-11ec-982e-8d5b5f26d663",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/measures%2Fe84f8f90-92ef-11ec-982e-8d5b5f26d663.JPG?alt=media&token=5772704c-2bf1-47c2-8a04-80db2fdd19de",
            "lastEditedBy": {
                "email": "dennis.gue@web.de",
                "id": "mBRvCdgko4PAoNUd2qyTvgFHUPc2",
                "votings": {
                    "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                        "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                        "type": "measure",
                        "vote": 1
                    }
                }
            },
            "name": "Mobilisation der Halswirbelsäule",
            "tags": [
                "Halswirbelsäule",
                "Mobilisation "
            ]
        }
    },
    "sequences": {
        "1f999sss-e9d0-11e9-a58c-ab3a522bb30b": {
            "description": "Deine persönlichen Favoriten unter allen Maßnahmen und Übungen im Community-Pool.",
            "firebaseid": "1f999sss-e9d0-11e9-a58c-ab3a522bb30b",
            "id": "1f999sss-e9d0-11e9-a58c-ab3a522bb30b",
            "measures": [
                {
                    "defaultVideoEnd": "0",
                    "defaultVideoId": "JlbQUjJzO6o",
                    "defaultVideoStart": "7",
                    "description": "Progressive Muskelentspannung (auch PMR für engl. Progressive Muskel Relaxation) ist eine Entspannungstechnik nach Jacobsen.",
                    "firebaseid": "3b3b3430-f508-11e9-8439-adab4f265efd",
                    "id": "3b3b3430-f508-11e9-8439-adab4f265efd",
                    "name": "Progressive Muskelentspannung",
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                },
                {
                    "defaultVideoId": "ed3dw_TPHzQ",
                    "defaultVideoStart": "80",
                    "description": "Alle Übungen hier sind im Liegen. Die Arme auf der Seite liegend abwechselnd leicht ausstrecken.",
                    "firebaseid": "6a6ac220-f43b-11e9-a1cf-c7a7a610c511",
                    "id": "6a6ac220-f43b-11e9-a1cf-c7a7a610c511",
                    "name": "Feldenkrais - Relief for Neck and Shoulders",
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                },
                {
                    "defaultVideoId": "XR0vL8GH8Ow",
                    "defaultVideoStart": "3",
                    "description": "Hüfte nach vorn kippen und und einatmen in die obere Brust. Dies ergibt automatisch eine aufrechte Haltung. Tamay Jentjens erklärt, den Unterschied zwischen anterior und posterior pelvic tilt.",
                    "firebaseid": "703229f0-f508-11e9-8439-adab4f265efd",
                    "id": "703229f0-f508-11e9-8439-adab4f265efd",
                    "name": "Hüfte nach vorn kippen",
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                },
                {
                    "defaultVideoEnd": 0,
                    "defaultVideoId": "Cr_wAIDqUbo",
                    "defaultVideoStart": "10",
                    "description": "Übung mit der Black Roll, hier von Strong & Flex",
                    "firebaseid": "852e1680-ffe3-11e9-ad38-ab6d1937ba12",
                    "id": "852e1680-ffe3-11e9-ad38-ab6d1937ba12",
                    "name": "Brustwirbelsäule öffnen"
                },
                {
                    "defaultVideoId": "",
                    "defaultVideoStart": 0,
                    "description": "Ausrollen des Rückens entlang der Wirbelsäule z.B. mit einer Black roll Duo roll",
                    "firebaseid": "948c3d50-f6ab-11e9-8b0b-c718f4158888",
                    "id": "948c3d50-f6ab-11e9-8b0b-c718f4158888",
                    "name": "Rollen entlang der Wirbelsäule"
                },
                {
                    "defaultVideoEnd": "640",
                    "defaultVideoId": "Yxb5HgG8oWA",
                    "defaultVideoStart": "127",
                    "description": "Schönes Kurzprogramm",
                    "firebaseid": "9b502f10-e9fa-11e9-94a7-97260168418b",
                    "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
                    "name": "Übung 3 - Nackenverspannungen lösen in nur 3 Minuten",
                    "video": {
                        "id": "Yxb5HgG8oWA",
                        "name": "Abschnitt - Kopf seitlich rotieren",
                        "start": 126
                    }
                },
                {
                    "description": "To be done",
                    "firebaseid": "a9dcba70-f0db-11e9-abf8-f1fd9fb7935f",
                    "id": "a9dcba70-f0db-11e9-abf8-f1fd9fb7935f",
                    "name": "Nacken im Liegen dehnen",
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                },
                {
                    "description": "Eine mögliche andere Haltung ist es, sich umgekehrt auf den Stuhl zu setzen.",
                    "firebaseid": "b91b5330-f508-11e9-8439-adab4f265efd",
                    "id": "b91b5330-f508-11e9-8439-adab4f265efd",
                    "name": "Umgekehrt auf den Stuhl setzen",
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                },
                {
                    "defaultVideoId": "",
                    "defaultVideoStart": "540",
                    "description": "Ausrollen der Beine ab dem Knie aufwärts",
                    "firebaseid": "baf13ad0-f6ac-11e9-9e74-65692e2dc62a",
                    "id": "baf13ad0-f6ac-11e9-9e74-65692e2dc62a",
                    "name": "Ausrollen der Beine "
                },
                {
                    "defaultVideoEnd": "446",
                    "defaultVideoId": "ngZrNjPcAwQ",
                    "defaultVideoStart": "92",
                    "description": "Morgenroutine von Einfach besser leben",
                    "firebaseid": "2c36bca0-ffe1-11e9-97c8-d53d9dd837e2",
                    "id": "2c36bca0-ffe1-11e9-97c8-d53d9dd837e2",
                    "name": "Morgenroutine 2"
                },
                {
                    "defaultVideoEnd": 0,
                    "defaultVideoId": "WOb82ta2P_8",
                    "defaultVideoStart": "14",
                    "description": "Ein Favorit für den Alltag",
                    "firebaseid": "6202fb50-00b3-11ea-acbf-4399ea40fa26",
                    "id": "6202fb50-00b3-11ea-acbf-4399ea40fa26",
                    "name": "Nackenyoga",
                    "tags": [
                        "nacken",
                        "yoga",
                        "gesamtprogramm"
                    ]
                },
                {
                    "defaultVideoEnd": "1260",
                    "defaultVideoId": "MDGd-dlUMkM",
                    "defaultVideoStart": "342",
                    "description": "",
                    "firebaseid": "961097e0-0658-11ea-b200-f9931f6c73dc",
                    "id": "961097e0-0658-11ea-b200-f9931f6c73dc",
                    "name": "Akkupressur Nacken",
                    "tags": [
                        "nacken",
                        "gesamtprogramm",
                        "akkupressur"
                    ]
                }
            ],
            "name": "Favoriten"
        },
        "2efbb3b0-00cb-11ea-bfed-1349a254339d": {
            "description": "Übungen, die bei akuten Spannungskopfschmerzen und Nackenverspannungen helfen können.",
            "firebaseid": "2efbb3b0-00cb-11ea-bfed-1349a254339d",
            "id": "2efbb3b0-00cb-11ea-bfed-1349a254339d",
            "measures": [
                {
                    "defaultVideoEnd": "0",
                    "defaultVideoId": "JlbQUjJzO6o",
                    "defaultVideoStart": "7",
                    "description": "Progressive Muskelentspannung (auch PMR für engl. Progressive Muskel Relaxation) ist eine Entspannungstechnik nach Jacobsen.",
                    "firebaseid": "3b3b3430-f508-11e9-8439-adab4f265efd",
                    "id": "3b3b3430-f508-11e9-8439-adab4f265efd",
                    "name": "Progressive Muskelentspannung",
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                },
                {
                    "defaultVideoId": "ed3dw_TPHzQ",
                    "defaultVideoStart": "80",
                    "description": "Alle Übungen hier sind im Liegen. Die Arme auf der Seite liegend abwechselnd leicht ausstrecken.",
                    "firebaseid": "6a6ac220-f43b-11e9-a1cf-c7a7a610c511",
                    "id": "6a6ac220-f43b-11e9-a1cf-c7a7a610c511",
                    "name": "Feldenkrais - Relief for Neck and Shoulders",
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                },
                {
                    "defaultVideoEnd": "640",
                    "defaultVideoId": "Yxb5HgG8oWA",
                    "defaultVideoStart": "127",
                    "description": "Schönes Kurzprogramm",
                    "firebaseid": "9b502f10-e9fa-11e9-94a7-97260168418b",
                    "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
                    "name": "Übung 3 - Nackenverspannungen lösen in nur 3 Minuten",
                    "video": {
                        "id": "Yxb5HgG8oWA",
                        "name": "Abschnitt - Kopf seitlich rotieren",
                        "start": 126
                    }
                },
                {
                    "description": "To be done",
                    "firebaseid": "a9dcba70-f0db-11e9-abf8-f1fd9fb7935f",
                    "id": "a9dcba70-f0db-11e9-abf8-f1fd9fb7935f",
                    "name": "Nacken im Liegen dehnen",
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                },
                {
                    "defaultVideoEnd": "280",
                    "defaultVideoId": "Nu9bTQKvhs8",
                    "defaultVideoStart": "70",
                    "description": "nach L&B",
                    "firebaseid": "17bb1a20-3621-11ea-bf03-bfa96037777e",
                    "id": "17bb1a20-3621-11ea-bf03-bfa96037777e",
                    "name": "Nacken dehnen 45 Grad",
                    "tags": [
                        "nacken",
                        "dehnen"
                    ]
                }
            ],
            "name": "Notfallprogramm"
        },
        "43663a70-024a-11ea-991e-41bbff49e8fc": {
            "description": "Test",
            "firebaseid": "43663a70-024a-11ea-991e-41bbff49e8fc",
            "id": "43663a70-024a-11ea-991e-41bbff49e8fc",
            "measures": [
                {
                    "defaultVideoEnd": 0,
                    "defaultVideoId": "",
                    "defaultVideoStart": 0,
                    "description": "Neben dem Ändern der Funktion auf einem normalen Stuhl kann auch das sitzen in der Hocke im Knien oder im schneidersitz in die tägliche Arbeit integriert werden.",
                    "firebaseid": "5cbcaed0-01b1-11ea-b2b0-0da2025906af",
                    "id": "5cbcaed0-01b1-11ea-b2b0-0da2025906af",
                    "name": "Sitzpositionen variieren"
                }
            ],
            "name": "Jans Lieblingsübungen"
        },
        "56725830-0f86-11ea-9abe-d5e1770129c6": {
            "description": "Übungen und Maßnahmen, um Zugfahrten heil zu überstehen - was Verspannungen betrifft. Zugfahrten stellen eine besondere Herausforderung für Nackenpatienten da. Wie lässt sich das stundenlange Sitzen überstehen?",
            "id": "56725830-0f86-11ea-9abe-d5e1770129c6",
            "measures": [
                {
                    "defaultVideoEnd": 0,
                    "defaultVideoId": "",
                    "defaultVideoStart": 0,
                    "description": "Neben dem Ändern der Funktion auf einem normalen Stuhl kann auch das sitzen in der Hocke im Knien oder im schneidersitz in die tägliche Arbeit integriert werden.",
                    "firebaseid": "5cbcaed0-01b1-11ea-b2b0-0da2025906af",
                    "id": "5cbcaed0-01b1-11ea-b2b0-0da2025906af",
                    "name": "Sitzpositionen variieren",
                    "tags": [
                        "verhalten",
                        "ergonomie"
                    ]
                },
                {
                    "defaultVideoEnd": 0,
                    "defaultVideoId": "WOb82ta2P_8",
                    "defaultVideoStart": "14",
                    "description": "Ein Favorit für den Alltag",
                    "firebaseid": "6202fb50-00b3-11ea-acbf-4399ea40fa26",
                    "id": "6202fb50-00b3-11ea-acbf-4399ea40fa26",
                    "name": "Nackenyoga",
                    "tags": [
                        "nacken",
                        "yoga",
                        "gesamtprogramm"
                    ]
                },
                {
                    "defaultVideoId": "XR0vL8GH8Ow",
                    "defaultVideoStart": "3",
                    "description": "Hüfte nach vorn kippen und und einatmen in die obere Brust. Dies ergibt automatisch eine aufrechte Haltung. Tamay Jentjens erklärt, den Unterschied zwischen anterior und posterior pelvic tilt.",
                    "firebaseid": "703229f0-f508-11e9-8439-adab4f265efd",
                    "id": "703229f0-f508-11e9-8439-adab4f265efd",
                    "name": "Hüfte nach vorn kippen",
                    "tags": [
                        "haltung",
                        "hüfte",
                        "brust",
                        "atmung"
                    ],
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                },
                {
                    "defaultVideoEnd": 0,
                    "defaultVideoId": "Cr_wAIDqUbo",
                    "defaultVideoStart": "10",
                    "description": "Übung mit der Black Roll, hier von Strong & Flex",
                    "firebaseid": "852e1680-ffe3-11e9-ad38-ab6d1937ba12",
                    "id": "852e1680-ffe3-11e9-ad38-ab6d1937ba12",
                    "name": "Brustwirbelsäule öffnen",
                    "tags": [
                        "brustwirbelsäule",
                        "mobilisieren",
                        "abends"
                    ]
                },
                {
                    "defaultVideoEnd": "1260",
                    "defaultVideoId": "MDGd-dlUMkM",
                    "defaultVideoStart": "342",
                    "description": "",
                    "firebaseid": "961097e0-0658-11ea-b200-f9931f6c73dc",
                    "id": "961097e0-0658-11ea-b200-f9931f6c73dc",
                    "name": "Akkupressur Nacken",
                    "tags": [
                        "nacken",
                        "gesamtprogramm",
                        "akkupressur"
                    ]
                },
                {
                    "defaultVideoEnd": 0,
                    "defaultVideoId": "",
                    "defaultVideoStart": 0,
                    "description": "",
                    "firebaseid": "96649ef0-01b0-11ea-b2b0-0da2025906af",
                    "id": "96649ef0-01b0-11ea-b2b0-0da2025906af",
                    "name": "Eine Tasse Kaffee am Mittag",
                    "tags": [
                        "ernährung",
                        "mittags"
                    ]
                },
                {
                    "defaultVideoEnd": "0",
                    "defaultVideoId": "JlbQUjJzO6o",
                    "defaultVideoStart": "7",
                    "description": "Progressive Muskelentspannung (auch PMR für engl. Progressive Muskel Relaxation) ist eine Entspannungstechnik nach Jacobsen. ",
                    "firebaseid": "3b3b3430-f508-11e9-8439-adab4f265efd",
                    "id": "3b3b3430-f508-11e9-8439-adab4f265efd",
                    "name": "Progressive Muskelentspannung",
                    "tags": [
                        "entspannung",
                        "psyche",
                        "stress",
                        "liegen"
                    ],
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                }
            ],
            "name": "Zugfahrten"
        },
        "6e6c63c0-fb0b-11e9-871a-af97aa54cf82": {
            "description": "Sammlung guter Morgenroutinen",
            "firebaseid": "6e6c63c0-fb0b-11e9-871a-af97aa54cf82",
            "id": "6e6c63c0-fb0b-11e9-871a-af97aa54cf82",
            "measures": [
                {
                    "defaultVideoEnd": "0",
                    "defaultVideoId": "RMPCNcKSy3k",
                    "defaultVideoStart": "33",
                    "description": "Morgenroutine von Strong & Flex",
                    "firebaseid": "1140d940-f62b-11e9-9edc-8fa03c46b765",
                    "id": "1140d940-f62b-11e9-9edc-8fa03c46b765",
                    "name": "Morgenroutine 1"
                },
                {
                    "defaultVideoEnd": "446",
                    "defaultVideoId": "ngZrNjPcAwQ",
                    "defaultVideoStart": "92",
                    "description": "Morgenroutine von Einfach besser leben",
                    "firebaseid": "2c36bca0-ffe1-11e9-97c8-d53d9dd837e2",
                    "id": "2c36bca0-ffe1-11e9-97c8-d53d9dd837e2",
                    "name": "Morgenroutine 2"
                },
                {
                    "defaultVideoEnd": "637",
                    "defaultVideoId": "J486Fn4Rhms",
                    "defaultVideoStart": "30",
                    "description": "nach Gabi Fastner",
                    "firebaseid": "eafb5da0-6a88-11ea-a090-f5e944b75795",
                    "id": "eafb5da0-6a88-11ea-a090-f5e944b75795",
                    "name": "10 min. Morgengymnastik als Faszientraining, ohne Geräte",
                    "tags": [
                        "faszien",
                        "nacken",
                        "rücken",
                        "schulter",
                        "gesamtprogramm"
                    ]
                }
            ],
            "name": "Morgenroutinen"
        },
        "9d01e570-fb0b-11e9-871a-af97aa54cf82": {
            "description": "Maßnahmen und Übungen, die auch im Liegen möglich sind und daher auch für alte Menschen, oder bei Krankheit ergriffen werden können.",
            "firebaseid": "9d01e570-fb0b-11e9-871a-af97aa54cf82",
            "id": "9d01e570-fb0b-11e9-871a-af97aa54cf82",
            "measures": [
                {
                    "defaultVideoEnd": "0",
                    "defaultVideoId": "JlbQUjJzO6o",
                    "defaultVideoStart": "7",
                    "description": "Progressive Muskelentspannung (auch PMR für engl. Progressive Muskel Relaxation) ist eine Entspannungstechnik nach Jacobsen.",
                    "firebaseid": "3b3b3430-f508-11e9-8439-adab4f265efd",
                    "id": "3b3b3430-f508-11e9-8439-adab4f265efd",
                    "name": "Progressive Muskelentspannung",
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                },
                {
                    "defaultVideoId": "",
                    "defaultVideoStart": 0,
                    "description": "Die Matte gibt dort etwas weniger Gegendruck.",
                    "firebaseid": "4f373de0-f949-11e9-9543-45e0413f99f1",
                    "id": "4f373de0-f949-11e9-9543-45e0413f99f1",
                    "name": "Kopf auf Matratzen Ende legen"
                },
                {
                    "defaultVideoId": "ed3dw_TPHzQ",
                    "defaultVideoStart": "80",
                    "description": "Alle Übungen hier sind im Liegen. Die Arme auf der Seite liegend abwechselnd leicht ausstrecken.",
                    "firebaseid": "6a6ac220-f43b-11e9-a1cf-c7a7a610c511",
                    "id": "6a6ac220-f43b-11e9-a1cf-c7a7a610c511",
                    "name": "Feldenkrais - Relief for Neck and Shoulders",
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                },
                {
                    "defaultVideoEnd": 0,
                    "defaultVideoId": "Cr_wAIDqUbo",
                    "defaultVideoStart": "10",
                    "description": "Übung mit der Black Roll, hier von Strong & Flex",
                    "firebaseid": "852e1680-ffe3-11e9-ad38-ab6d1937ba12",
                    "id": "852e1680-ffe3-11e9-ad38-ab6d1937ba12",
                    "name": "Brustwirbelsäule öffnen"
                },
                {
                    "description": "To be done",
                    "firebaseid": "a9dcba70-f0db-11e9-abf8-f1fd9fb7935f",
                    "id": "a9dcba70-f0db-11e9-abf8-f1fd9fb7935f",
                    "name": "Nacken im Liegen dehnen",
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                }
            ],
            "name": "Maßnahmen, die auch im Liegen möglich sind"
        },
        "b8844820-00e1-11ea-97c0-e7accd423a40": {
            "description": "in guten Zeiten",
            "firebaseid": "b8844820-00e1-11ea-97c0-e7accd423a40",
            "id": "b8844820-00e1-11ea-97c0-e7accd423a40",
            "measures": [
                {
                    "description": "Eine mögliche andere Haltung ist es, sich umgekehrt auf den Stuhl zu setzen.",
                    "firebaseid": "b91b5330-f508-11e9-8439-adab4f265efd",
                    "id": "b91b5330-f508-11e9-8439-adab4f265efd",
                    "name": "Umgekehrt auf den Stuhl setzen",
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                },
                {
                    "defaultVideoId": "",
                    "defaultVideoStart": "540",
                    "description": "Ausrollen der Beine ab dem Knie aufwärts",
                    "firebaseid": "baf13ad0-f6ac-11e9-9e74-65692e2dc62a",
                    "id": "baf13ad0-f6ac-11e9-9e74-65692e2dc62a",
                    "name": "Ausrollen der Beine "
                },
                {
                    "defaultVideoEnd": 0,
                    "defaultVideoId": "Cr_wAIDqUbo",
                    "defaultVideoStart": "10",
                    "description": "Übung mit der Black Roll, hier von Strong & Flex",
                    "firebaseid": "852e1680-ffe3-11e9-ad38-ab6d1937ba12",
                    "id": "852e1680-ffe3-11e9-ad38-ab6d1937ba12",
                    "name": "Brustwirbelsäule öffnen"
                },
                {
                    "defaultVideoEnd": 0,
                    "defaultVideoId": "",
                    "defaultVideoStart": 0,
                    "description": "Kissen aus memoryschaum, besser Getreidespelzen deformieren, um sich der Form des liegenden anzupassen und bauen keinen federnden Gegendruck auf.",
                    "firebaseid": "6cd3c180-00e1-11ea-9fae-b7f240e9f262",
                    "id": "6cd3c180-00e1-11ea-9fae-b7f240e9f262",
                    "name": "Nicht-elastisches Kissen verwenden"
                },
                {
                    "defaultVideoId": "XR0vL8GH8Ow",
                    "defaultVideoStart": "3",
                    "description": "Hüfte nach vorn kippen und und einatmen in die obere Brust. Dies ergibt automatisch eine aufrechte Haltung. Tamay Jentjens erklärt, den Unterschied zwischen anterior und posterior pelvic tilt.",
                    "firebaseid": "703229f0-f508-11e9-8439-adab4f265efd",
                    "id": "703229f0-f508-11e9-8439-adab4f265efd",
                    "name": "Hüfte nach vorn kippen",
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                },
                {
                    "defaultVideoEnd": 0,
                    "defaultVideoId": "",
                    "defaultVideoStart": 0,
                    "description": "Neben dem Ändern der Funktion auf einem normalen Stuhl kann auch das sitzen in der Hocke im Knien oder im schneidersitz in die tägliche Arbeit integriert werden.",
                    "firebaseid": "5cbcaed0-01b1-11ea-b2b0-0da2025906af",
                    "id": "5cbcaed0-01b1-11ea-b2b0-0da2025906af",
                    "name": "Sitzpositionen variieren"
                },
                {
                    "defaultVideoEnd": "0",
                    "defaultVideoId": "JlbQUjJzO6o",
                    "defaultVideoStart": "7",
                    "description": "Progressive Muskelentspannung (auch PMR für engl. Progressive Muskel Relaxation) ist eine Entspannungstechnik nach Jacobsen.\n\nTest",
                    "firebaseid": "3b3b3430-f508-11e9-8439-adab4f265efd",
                    "id": "3b3b3430-f508-11e9-8439-adab4f265efd",
                    "name": "Progressive Muskelentspannung",
                    "video": {
                        "id": "",
                        "name": "",
                        "start": 0
                    }
                },
                {
                    "defaultVideoEnd": 0,
                    "defaultVideoId": "",
                    "defaultVideoStart": 0,
                    "description": "Um den Körper nachts aus einer Position zu bringen, die dem Sitzen ähnelt.",
                    "firebaseid": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                    "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                    "name": "Harte Matratze verwenden"
                }
            ],
            "name": "Vorbeugen"
        },
        "e4e875e0-827f-11ec-8f7f-59dfb0f6c04f": {
            "description": "Übungen zur Stabilisierung des Kniegelenks und Detonisierung der umgebenden Muskulatur",
            "id": "e4e875e0-827f-11ec-8f7f-59dfb0f6c04f",
            "measures": [
                {
                    "defaultVideoEnd": "530",
                    "defaultVideoId": "K3ozV9QI3xM",
                    "defaultVideoStart": "80",
                    "description": "Entspannung der vorderen Oberschenkelmuskulatur",
                    "id": "485c0390-827f-11ec-8f7f-59dfb0f6c04f",
                    "lastEditedBy": {
                        "email": "dennis.gue@web.de",
                        "id": "mBRvCdgko4PAoNUd2qyTvgFHUPc2",
                        "votings": {
                            "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                                "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                                "type": "measure",
                                "vote": 1
                            }
                        }
                    },
                    "name": "Detonisierung M. Quadtrizeps Femoris"
                }
            ],
            "name": "Knieübungen"
        }
    },
    "users": {
        "7Pep33gN7zcATcwPEgwgXg4Tvph1": {
            "votings": {
                "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
                    "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                    "type": "measure",
                    "vote": 1
                }
            }
        },
        "PbTu9hyBo7OtkLmeVHbCYncDcYz1": {
            "votings": {
                "3828ca90-74ed-11ea-ac2f-9d1b33a03834": {
                    "id": "3828ca90-74ed-11ea-ac2f-9d1b33a03834",
                    "type": "measure",
                    "vote": -1
                },
                "50aac390-85fd-11ea-b8ab-5589732d8728": {
                    "id": "50aac390-85fd-11ea-b8ab-5589732d8728",
                    "type": "measure",
                    "vote": 1
                }
            }
        },
        "YD3yvrJjktgLHMF5xjiW7pnFpti2": {
            "votings": {
                "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                    "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                    "type": "measure",
                    "vote": 1
                }
            }
        },
        "fYabiCIeYhZJj74gLiVOt2iy3yO2": {
            "votings": {
                "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                    "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                    "type": "measure",
                    "vote": 1
                }
            }
        },
        "hsneVOpabWMTDfO8nvnBUyAF8q92": {
            "votings": {
                "17bb1a20-3621-11ea-bf03-bfa96037777e": {
                    "id": "17bb1a20-3621-11ea-bf03-bfa96037777e",
                    "type": "measure",
                    "vote": -1
                },
                "99a48da0-92ef-11ec-982e-8d5b5f26d663": {
                    "id": "99a48da0-92ef-11ec-982e-8d5b5f26d663",
                    "type": "measure",
                    "vote": 1
                }
            }
        },
        "mBRvCdgko4PAoNUd2qyTvgFHUPc2": {
            "votings": {
                "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                    "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                    "type": "measure",
                    "vote": 1
                }
            }
        },
        "mkbquY8FCNU9lG4L15ydGZi8z6Y2": {
            "votings": {
                "070f8f50-35e3-11ea-bf03-bfa96037777e": {
                    "id": "070f8f50-35e3-11ea-bf03-bfa96037777e",
                    "type": "measure",
                    "vote": 1
                },
                "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
                    "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                    "type": "measure",
                    "vote": 1
                },
                "17bb1a20-3621-11ea-bf03-bfa96037777e": {
                    "id": "17bb1a20-3621-11ea-bf03-bfa96037777e",
                    "type": "measure",
                    "vote": 1
                },
                "1c34d700-3714-11ea-bf03-bfa96037777e": {
                    "id": "1c34d700-3714-11ea-bf03-bfa96037777e",
                    "type": "measure",
                    "vote": 1
                },
                "3828ca90-74ed-11ea-ac2f-9d1b33a03834": {
                    "id": "3828ca90-74ed-11ea-ac2f-9d1b33a03834",
                    "type": "measure",
                    "vote": -1
                },
                "50aac390-85fd-11ea-b8ab-5589732d8728": {
                    "id": "50aac390-85fd-11ea-b8ab-5589732d8728",
                    "type": "measure",
                    "vote": -1
                },
                "6202fb50-00b3-11ea-acbf-4399ea40fa26": {
                    "id": "6202fb50-00b3-11ea-acbf-4399ea40fa26",
                    "type": "measure",
                    "vote": 1
                },
                "675fe560-3621-11ea-bf03-bfa96037777e": {
                    "id": "675fe560-3621-11ea-bf03-bfa96037777e",
                    "type": "measure",
                    "vote": 1
                },
                "8030f060-92f0-11ec-982e-8d5b5f26d663": {
                    "id": "8030f060-92f0-11ec-982e-8d5b5f26d663",
                    "type": "measure",
                    "vote": 1
                },
                "8def5fd0-8667-11ea-b6ee-119c10ce8be3": {
                    "id": "8def5fd0-8667-11ea-b6ee-119c10ce8be3",
                    "type": "measure",
                    "vote": -1
                },
                "948c3d50-f6ab-11e9-8b0b-c718f4158888": {
                    "id": "948c3d50-f6ab-11e9-8b0b-c718f4158888",
                    "type": "measure",
                    "vote": 1
                },
                "96649ef0-01b0-11ea-b2b0-0da2025906af": {
                    "id": "96649ef0-01b0-11ea-b2b0-0da2025906af",
                    "type": "measure",
                    "vote": 1
                },
                "9b502f10-e9fa-11e9-94a7-97260168418b": {
                    "id": "9b502f10-e9fa-11e9-94a7-97260168418b",
                    "type": "measure",
                    "vote": 1
                },
                "9f4cee10-7284-11ea-90ee-d145525ddc72": {
                    "id": "9f4cee10-7284-11ea-90ee-d145525ddc72",
                    "type": "measure",
                    "vote": 1
                },
                "eafb5da0-6a88-11ea-a090-f5e944b75795": {
                    "id": "eafb5da0-6a88-11ea-a090-f5e944b75795",
                    "type": "measure",
                    "vote": 1
                }
            }
        },
        "yr3gBqoP4FMezwlhYcWrdM6koK92": {
            "votings": {
                "0c10f160-00e1-11ea-9fae-b7f240e9f262": {
                    "id": "0c10f160-00e1-11ea-9fae-b7f240e9f262",
                    "type": "measure",
                    "vote": 1
                }
            }
        }
    },
    "videos": {
        "WOb82ta2P_8": {
            "id": "WOb82ta2P_8",
            "name": "Video - Nacken-Yoga",
            "start": 423
        },
        "Yxb5HgG8oWA": {
            "id": "Yxb5HgG8oWA",
            "name": "Video - Nackenverspannungen lösen in nur 6 Minuten",
            "start": 126
        }
    }
}