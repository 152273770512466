<template>
  <div>
<!--    <div v-if="loading" class="loading text-center">-->
<!--      <v-progress-circular indeterminate color="primary"></v-progress-circular>-->
<!--    </div>-->
    <div v-if="error" class="error">{{ error }}</div>
    <div v-if="sequence">
      <v-card class="mt-2 mb-8" flat>
        <v-card-title class="pt-1 px-0 d-flex justify-space-between">
          <span class="text-h3">{{sequence.name}}</span>
          <span class="d-flex" style="min-width: 50px">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span class="overlayBtn d-flex justify-center" v-on="on" @click="switchFavorite">
                  <v-icon :class="{favorited: hasFavorited}">mdi-bookmark</v-icon>
                </span>
              </template>
              <span>Als Favorit markieren</span>
            </v-tooltip>
          </span>
        </v-card-title>
        <v-card-text class="cardText px-0">
          <v-chip>{{ sequenceTools.length }} Tools</v-chip>
          <span class="ml-3">
            <v-icon v-if="sequence.published">mdi-earth</v-icon>
            <v-icon v-else>mdi-lock</v-icon>
          </span>
        </v-card-text>
        <v-card-text class="cardText px-0 pt-1">{{sequence.description}}</v-card-text>
        <v-card-actions v-if="editAllowed" class="px-0">
          <v-btn text @click="editEntity" class="mr-4">
            <v-icon class="mr-1" small>mdi-pencil</v-icon>
            <span>Edit</span>
          </v-btn>
          <v-dialog v-model="dialog" persistent max-width="290">
            <template v-slot:activator="{ on }">
              <v-btn color="secondary" text dark v-on="on">
                <v-icon class="mr-1" small>mdi-delete</v-icon>
                <span>Delete</span>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Delete?</v-card-title>
              <v-card-text>Really delete? Cannot be undone.</v-card-text>
              <v-spacer></v-spacer>
              <v-btn class="ma-4" color="cancel" @click="dialog = false">Cancel</v-btn>
              <v-btn class="ma-4" color="delete" @click="deleteTool()">Delete</v-btn>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
      <div>
        <v-row>
          <v-col v-for="tool in sequenceTools" :key="tool.id" cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
            <tool-card :tool="tool"/>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import ToolCard from "@/components/ToolCard";

export default {
  components: {ToolCard},
  props: ['id'],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    loading: function() {
      return this.$store.getters.loading;
    },
    error: function() {
      return this.$store.getters.error;
    },
    user: function() {
      return this.$store.getters.user;
    },
    sequences: function() {
      return this.$store.getters.sequences;
    },
    tools: function() {
      return this.$store.getters.tools;
    },
    sequence: function() {
      return this.sequences.find((s) => s.id === this.id);
    },
    sequenceTools: function() {
      if (!this.sequence || !this.sequence.toolIds) return [];
      const sTools = []
      this.sequence.toolIds.forEach(id => {
        const tool = this.tools.find((s) => s.id === id);
        if (tool) sTools.push(tool)
      })
      return sTools;
    },
    hasFavorited() { return this.user.sequenceFavoriteIds?.indexOf(this.id) >= 0 || false },
    editAllowed() { return this.sequence.userId === this.user.id }
  },
  methods: {
    deleteTool() {
      this.dialog = false;
      this.$store.dispatch("deleteSequence", this.sequence).then(() => this.$router.push({ name: "Dashboard" }));
    },
    editEntity() {
      this.$router.push( { name: 'SequenceEdit', params: { id: this.id }});
    },
    switchFavorite: function() {
      if (!this.user.sequenceFavoriteIds) {
        this.user.sequenceFavoriteIds = [ this.id ];
      } else if (this.hasFavorited) {
        this.user.sequenceFavoriteIds = this.user.sequenceFavoriteIds.filter((id) => id !== this.id);
      } else {
        this.user.sequenceFavoriteIds.push(this.id);
      }
      this.$store.dispatch("updateUser", this.user);
    }

  },
};
</script>


<style scoped>
.cardText {
  font-size: 100%;
}
</style>
