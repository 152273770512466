<template>
  <div>
    <v-card flat class="text-center text-white px-10 pt-4 pb-1 mt-0 mb-0 searchCard bannerCard">
      <div class="d-flex justify-center pa-2">
        <v-img contain src="@/assets/logo.svg" width="6rem" max-width="6rem" class="my-4"/>
      </div>
      <h1 class="text-h4 mb-4">{{ $t('dashboard.search.question') }}</h1>
      <div class="d-flex justify-center">
        <div style="width: 800px">
          <v-menu v-model="showSuggestMenu" offset-y
                  :open-on-click="false" :close-on-click="false"
                  :nudge-bottom="10" min-width="726px" max-width="726px" :nudge-right="37"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-on="on"
                  v-model="searchTerm"
                  background-color="appWhite"
                  class="searchField text-h6"
                  filled color="appBlack lighten-1"
                  prepend-inner-icon="mdi-magnify"
                  @focus="showSuggestMenu = true"
                  @blur="showSuggestMenu = true"
                  @input="showSuggestMenu = true"
                  @keyup.enter="showSuggestMenu = false"
                  clearable hide-details rounded
              ></v-text-field>
            </template>
            <v-list v-if="suggest.length > 0">
              <v-list-item-group color="primary" @change="suggestSelected">
                <v-list-item v-for="(suggest, index) in suggest" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>{{ suggest }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div class="mt-2 mb-6">{{ $t('dashboard.search.explanation') }}</div>
    </v-card>

    <v-card style="border-radius: 0px;">
    <v-tabs
      v-model="tab"
      background-color="primary"
      centered
      dark
      icons-and-text
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        {{ $t('dashboard.search-results.tools-caption') }} ({{ toolSearchResult.length }})
        <v-icon>mdi-tools</v-icon>
        <!-- <v-chip class="ml-2">{{ toolSearchResult.length }}</v-chip> -->
      </v-tab>

      <v-tab href="#tab-2">
        {{ $t('dashboard.search-results.sequences-caption') }} ({{ sequenceSearchResult.length }})
        <v-icon>mdi-book</v-icon>
      </v-tab>

    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        :key="1"
        :value="'tab-1'"
      >
        <v-card flat>
          <!-- <v-card-text>{{ text }}</v-card-text> -->
          <div class="mt-4 mx-4">
            <v-btn text :to="{ name: 'ToolCreate' }" class="mr-4 d-none d-sm-flex">
              <v-icon left>mdi-plus</v-icon><v-icon left>mdi-tools</v-icon>
              {{ $t('nav.top.create-tool') }} 
            </v-btn>
            <v-switch v-if="userIsFamily" v-model="showMyToolsOnly" :label='$t("dashboard.search-results.show-my-tools-only")'></v-switch>
          </div>
          <v-row v-if="showTools && toolSearchResult.length > 0" class="px-3">
            <v-col v-for="tool in toolSearchResult" :key="tool.id" cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
              <tool-card :tool="tool"/>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>

      <v-tab-item
        :key="2"
        :value="'tab-2'"
      >
        <v-card flat>
          <div class="mt-4 mx-4">
            <v-switch v-if="userIsFamily" v-model="showMySequencesOnly" :label='$t("dashboard.search-results.show-my-sequences-only")'></v-switch>
          </div>
          <v-row v-if="showSequences && sequenceSearchResult.length > 0" class="px-3">
            <v-col v-for="sequence in sequenceSearchResult" :key="sequence.id" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <sequence-card :sequence="sequence"/>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>

    <!-- old version without tabs -->
    <!-- <div class="text-left mt-24">
      <div class="mt-2 d-flex align-center justify-space-between searchResultsHeader">
        <span class="d-flex align-center searchResultsHeader" @click="switchTools">
          <v-icon v-if="showTools" x-large class="searchResultsHeader">mdi-menu-down</v-icon>
          <v-icon v-else x-large class="searchResultsHeader">mdi-menu-right</v-icon>
          <span class="text-h6 font-weight-bold">{{ $t('dashboard.search-results.tools-caption') }}</span>
          <v-chip class="ml-2">{{ toolSearchResult.length }}</v-chip>
        </span>
        <v-switch v-if="userIsFamily" v-model="showMyToolsOnly" :label='$t("dashboard.search-results.show-my-tools-only")'></v-switch>
      </div>
      <v-row v-if="showTools && toolSearchResult.length > 0">
        <v-col v-for="tool in toolSearchResult" :key="tool.id" cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
          <tool-card :tool="tool"/>
        </v-col>
      </v-row>
      <div class="mt-6 d-flex align-center justify-space-between searchResultsHeader">
        <span class="d-flex align-center searchResultsHeader" @click="switchSequences">
          <v-icon v-if="showSequences" x-large class="searchResultsHeader">mdi-menu-down</v-icon>
          <v-icon v-else x-large class="searchResultsHeader">mdi-menu-right</v-icon>
          <span class="text-h6 font-weight-bold">{{ $t('dashboard.search-results.sequences-caption') }}</span>
          <v-chip class="ml-2">{{ sequenceSearchResult.length }}</v-chip>
        </span>
        <v-switch v-if="userIsFamily" v-model="showMySequencesOnly" :label='$t("dashboard.search-results.show-my-sequences-only")'></v-switch>
      </div>
      <v-row v-if="showSequences && sequenceSearchResult.length > 0">
        <v-col v-for="sequence in sequenceSearchResult" :key="sequence.id" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <sequence-card :sequence="sequence"/>
        </v-col>
      </v-row>
    </div> -->

  </div>
</template>

<script>
import MiniSearch from 'minisearch';
import ToolCard from "@/components/ToolCard";
import SequenceCard from "@/components/SequenceCard";

export default {
  name: "Dashboard",
  components: {ToolCard,SequenceCard},
  data () {
    return {
      showSuggestMenu: false,
      showMySequencesOnly: false,
      showMyToolsOnly: false,
      searchTerm: '',
      suggest: [],
      toolSearchResult: [],
      sequenceSearchResult: [],
      tab: null,    }
  },
  mounted() {
    this.searchTerm = this.$store.getters.searchTerm;
    this.showMySequencesOnly = this.$store.getters.showMySequencesOnly;
    this.showMyToolsOnly = this.$store.getters.showMyToolsOnly;
    if (!this.searchTerm && this.tools) {
      this.toolSearchResult = this.filteredTools;
      this.sequenceSearchResult = this.filteredSequences;
    }
  },
  watch: {
    tools() {
      if (!this.searchTerm) {
        this.toolSearchResult = this.filteredTools;
      }
    },
    sequences() {
      if (!this.searchTerm) {
        this.sequenceSearchResult = this.filteredSequences;
      }
    },
    searchTerm() {
      this.$store.dispatch('updateSearchTerm', this.searchTerm);
      this.doSearch()
    },
    showMySequencesOnly() {
      this.$store.dispatch('updateShowMySequencesOnly', this.showMySequencesOnly);
      this.doSearch()
    },
    showMyToolsOnly() {
      this.$store.dispatch('updateShowMyToolsOnly', this.showMyToolsOnly);
      this.doSearch()
    }
  },
  methods: {
    switchTools() {
      this.$store.dispatch('switchTools');
    },
    switchSequences() {
      this.$store.dispatch('switchSequences');
    },
    suggestSelected(index) {
      if (index !== undefined) {
        console.debug("Suggest selected", this.suggest[index]);
        this.searchTerm = this.suggest[index];
      }
    },
    doSearch() {
      if (!this.searchTerm || this.searchTerm.length < 3) {
        this.suggest = [];
        this.toolSearchResult = this.filteredTools;
        this.sequenceSearchResult = this.filteredSequences;
        return
      }
      let miniSearch = new MiniSearch({
        fields: ['id', 'name', 'description', 'tags'], // fields to index for full-text search
        storeFields: ['id', 'type'] // fields to return with search results
      })

      // Index all documents
      miniSearch.addAll(this.filteredTools.map(tool => {
        return {
          type: 'tool',
          id: tool.id,
          name: tool.name,
          description: tool.description,
          tags: tool.tags
        }
      }));
      miniSearch.addAll(this.filteredSequences.map(sequence => {
        return {
          type: 'sequence',
          id: sequence.id,
          name: sequence.name,
          description: sequence.description
        }
      }));

      const suggest = miniSearch.autoSuggest(this.searchTerm, { combineWith: "AND", prefix: true });
      this.suggest = suggest.filter(suggestEntry => suggestEntry.suggestion.indexOf(" ") === -1).map(suggestEntry => suggestEntry.suggestion)

      // Search with default options
      const searchResult = miniSearch.search(this.searchTerm, { prefix: true }).map(r => {
        return { id: r.id, type: r.type }
      });
      this.toolSearchResult = this.tools.filter(tool => searchResult.find(r => r.type === 'tool' && r.id === tool.id));
      this.sequenceSearchResult = this.sequences.filter(sequence => searchResult.find(r => r.type === 'sequence' && r.id === sequence.id));
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    userIsAuthenticated() {
      return this.user !== null && this.user !== undefined
    },
    userIsAdmin() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("admin");
    },
    userIsFamily() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("family");
    },
    showTools() {
      return this.$store.getters.showTools;
    },
    showSequences() {
      return this.$store.getters.showSequences;
    },
    tools() {
      return this.$store.getters.tools;
    },
    filteredTools() {
      return this.tools.filter(tool => {
        if (this.user === null || this.user === undefined) {
          return tool.published;
        } else if (this.showMyToolsOnly) {
          return tool.userId === this.user.id;
        } else {
          return tool.userId === this.user.id || tool.published;
        }
       })
    },
    sequences() {
      return this.$store.getters.sequences;
    },
    filteredSequences() {
      return this.sequences.filter(sequence => {
        if (this.user === null || this.user === undefined) {
          return sequence.published;
        } else if (this.showMySequencesOnly) {
          return sequence.userId === this.user.id;
        } else {
          return sequence.userId === this.user.id || sequence.published;
        }
      })
    },
    loading () {
      return this.$store.getters.loading
    }
  }
};
</script>

<style scoped>
.searchCard {
  background-image: url("@/assets/sea.jpg");
  background-position: bottom center;
  background-size: cover;
  color: var(--v-appWhite-base);
}
.searchResultsHeader {
  color: var(--v-onTertiary-base);
  opacity: 80%;
  cursor: pointer;
  user-select: none;
}
</style>

<style>
/* this musn't be scoped and must be !important in order to work 
see https://stackoverflow.com/questions/52310060/how-to-override-vuetify-styles */
.searchField input,
.searchField .v-icon {
  color: var(--v-appBlack-base) !important;
}
</style>