<template>
  <div>
    <v-container class="text-left mt-24">
      <div class="d-flex align-center mb-8">
        <!-- <v-icon x-large>mdi-bookmark</v-icon> -->
        <span class="ml-4 text-h4">Meine Favoriten</span>
      </div>
      <div class="mt-2 d-flex align-center searchResultsHeader" @click="switchFavoriteVideoSnippets">
        <v-icon v-if="showFavoriteVideoSnippets" x-large class="searchResultsHeader">mdi-menu-down</v-icon>
        <v-icon v-else x-large class="searchResultsHeader">mdi-menu-right</v-icon>
        <span class="text-h6 font-weight-bold">Video Snippets</span>
        <v-chip class="ml-2">{{ favoriteVideoSnippets.length }}</v-chip>
      </div>
      <v-row v-if="showFavoriteVideoSnippets && favoriteVideoSnippets.length > 0">
        <v-col v-for="videoSnippet in favoriteVideoSnippets" :key="videoSnippet.id" cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
          <video-snippet-card :videoSnippet="videoSnippet"/>
        </v-col>
      </v-row>
      <div class="mt-2 d-flex align-center searchResultsHeader" @click="switchFavoriteTools">
        <v-icon v-if="showFavoriteTools" x-large class="searchResultsHeader">mdi-menu-down</v-icon>
        <v-icon v-else x-large class="searchResultsHeader">mdi-menu-right</v-icon>
        <span class="text-h6 font-weight-bold">Tools</span>
        <v-chip class="ml-2">{{ favoriteTools.length }}</v-chip>
      </div>
      <v-row v-if="showFavoriteTools && favoriteTools.length > 0">
        <v-col v-for="tool in favoriteTools" :key="tool.id" cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
          <tool-card :tool="tool"/>
        </v-col>
      </v-row>
      <div class="mt-6 d-flex align-center searchResultsHeader" @click="switchFavoriteSequences">
        <v-icon v-if="showFavoriteSequences" x-large class="searchResultsHeader">mdi-menu-down</v-icon>
        <v-icon v-else x-large class="searchResultsHeader">mdi-menu-right</v-icon>
        <span class="text-h6 font-weight-bold">Sammlungen</span>
        <v-chip class="ml-2">{{ favoriteSequences.length }}</v-chip>
      </div>
      <v-row v-if="showFavoriteSequences && favoriteSequences.length > 0">
        <v-col v-for="sequence in favoriteSequences" :key="sequence.id" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <sequence-card :sequence="sequence"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VideoSnippetCard from "@/components/VideoSnippetCard";
import ToolCard from "@/components/ToolCard";
import SequenceCard from "@/components/SequenceCard";

export default {
  name: "Favorite",
  components: {VideoSnippetCard,ToolCard,SequenceCard},
  methods: {
    switchFavoriteVideoSnippets() {
      this.$store.dispatch('switchFavoriteVideoSnippets');
    },
    switchFavoriteTools() {
      this.$store.dispatch('switchFavoriteTools');
    },
    switchFavoriteSequences() {
      this.$store.dispatch('switchFavoriteSequences');
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    showFavoriteVideoSnippets() {
      return this.$store.getters.showFavoriteVideoSnippets;
    },
    showFavoriteTools() {
      return this.$store.getters.showFavoriteTools;
    },
    showFavoriteSequences() {
      return this.$store.getters.showFavoriteSequences;
    },
    tools() {
      return this.$store.getters.tools;
    },
    videoSnippets() {
      return this.$store.getters.videoSnippets;
    },
    sequences() {
      return this.$store.getters.sequences;
    },
    favoriteTools() {
      return this.tools.filter(tool => this.user.toolFavoriteIds.indexOf(tool.id)  >= 0);
    },
    favoriteVideoSnippets() {
      return this.videoSnippets.filter(videoSnippet => this.user.videoSnippetFavoriteIds.indexOf(videoSnippet.id)  >= 0);
    },
    favoriteSequences () {
      return this.sequences.filter(filter => this.user.sequenceFavoriteIds.indexOf(filter.id)  >= 0);
    }
  }
};
</script>

<style scoped>
.searchResultsHeader {
  color: var(--v-onTertiary);
  opacity: 80%;
  cursor: pointer;
  user-select: none;
}
</style>