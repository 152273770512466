import { initializeApp } from "firebase/app";
import { initializeFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAuth, connectAuthEmulator } from "firebase/auth";

// for tests there is an extra config and firestore init in jest.setup.js

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    // databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL, // New firebase project doesn't have this
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
};

const firebaseApp = initializeApp(firebaseConfig);

console.debug("process.env.NODE_ENV: " + process.env.NODE_ENV);

const db = initializeFirestore(firebaseApp,{
    ignoreUndefinedProperties: true // https://stackoverflow.com/questions/69014671/firebase-firestore-v9-best-practices-ignoreundefinedproperties-vs-conditional
});
// const db = getFirestore(); // cannot be used with when configuration is needed
// if (process.env.NODE_ENV !== 'production') { // this does not work when using the firebase emulator
if (location.hostname === "localhost") {    
    connectFirestoreEmulator(db, 'localhost', 8080);
    // connectFirestoreEmulator(db, '127.0.0.1', 8080);
}

const storage = getStorage(firebaseApp);
if (location.hostname === "localhost") {
    connectStorageEmulator(storage, 'localhost', 9199);
}

const auth = getAuth(firebaseApp);
if (location.hostname === "localhost") {
    connectAuthEmulator(auth, 'http://localhost:9099');
}

export { db, storage };