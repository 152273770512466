import Vue from 'vue';
import Vuex from 'vuex';

import shared from './shared';
import user from './modules/user';
import video from './modules/video';
import videoSnippet from './modules/videoSnippet';
import sequence from './modules/sequence';
import tool from './modules/tool';
import tags from './modules/tags';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		searchTerm: "", // TODO: [OP-162] display an intital search term such as "What are you searching for"; needs to be restored on blur if empty and needs to be ignored for searching
		showVideoSnippets: true,
		showTools: true,
		showSequences: true,
		showFavoriteTools: true,
		showFavoriteVideoSnippets: true,
		showFavoriteSequences: true,
		filterPublishedVideoSnippets: "published",
		filterAuthorField: null,
		sortField: "created",
		sortAscending: true,
		showMyVideoSnippetsOnly: false,
		showMySequencesOnly: false,
		showMyToolsOnly: false,
	},
	mutations: {
		setSearchTerm(state, payload) {
			state.searchTerm = payload;
		},
		setFilterPublishedVideoSnippets(state, payload) {
			state.filterPublishedVideoSnippets = payload;
		},
		setFilterAuthorField(state, payload) {
			state.filterAuthorField = payload;
		},
		setSortField(state, payload) {
			state.sortField = payload;
		},
		setSortAscending(state, payload) {
			state.sortAscending = payload;
		},
		setShowMyVideoSnippetsOnly(state, payload) {
			state.showMyVideoSnippetsOnly = payload;
		},
		setShowMySequencesOnly(state, payload) {
			state.showMySequencesOnly = payload;
		},
		setShowMyToolsOnly(state, payload) {
			state.showMyToolsOnly = payload;
		},
		switchVideoSnippets(state) {
			state.showVideoSnippets = !state.showVideoSnippets;
		},
		switchTools(state) {
			state.showTools = !state.showTools;
		},
		switchSequences(state) {
			state.showSequences = !state.showSequences;
		},
		switchFavoriteVideoSnippets(state) {
			state.showFavoriteVideoSnippets = !state.showFavoriteVideoSnippets;
		},
		switchFavoriteTools(state) {
			state.showFavoriteTools = !state.showFavoriteTools;
		},
		switchFavoriteSequences(state) {
			state.showFavoriteSequences = !state.showFavoriteSequences;
		},
	},
	actions: {
		updateSearchTerm({ commit }, payload) {
			commit('setSearchTerm', payload);
		},
		updateFilterPublishedVideoSnippets({ commit }, payload) {
			commit('setFilterPublishedVideoSnippets', payload);
		},
		updateFilterAuthorField({ commit }, payload) {
			commit('setFilterAuthorField', payload);
		},
		updateSortField({ commit }, payload) {
			commit('setSortField', payload);
		},
		updateSortAscending({ commit }, payload) {
			commit('setSortAscending', payload);
		},
		updateShowMyVideoSnippetsOnly({ commit }, payload) {
			commit('setShowMyVideoSnippetsOnly', payload);
		},
		updateShowMySequencesOnly({ commit }, payload) {
			commit('setShowMySequencesOnly', payload);
		},
		updateShowMyToolsOnly({ commit }, payload) {
			commit('setShowMyToolsOnly', payload);
		},
		switchVideoSnippets({ commit }) {
			commit('switchVideoSnippets');
		},
		switchTools({ commit }) {
			commit('switchTools');
		},
		switchSequences({ commit }) {
			commit('switchSequences');
		},
		switchFavoriteVideoSnippets({ commit }) {
			commit('switchFavoriteVideoSnippets');
		},
		switchFavoriteTools({ commit }) {
			commit('switchFavoriteTools');
		},
		switchFavoriteSequences({ commit }) {
			commit('switchFavoriteSequences');
		},
	},
	getters: {
		searchTerm(state) {
			return state.searchTerm
		},
		filterPublishedVideoSnippets(state) {
			return state.filterPublishedVideoSnippets
		},
		selectedAuthorField(state) {
			return state.filterAuthorField
		},
		sortField(state) {
			return state.sortField
		},
		sortAscending(state) {
			return state.sortAscending
		},
		showMyVideoSnippetsOnly(state) {
			return state.showMyVideoSnippetsOnly
		},
		showMySequencesOnly(state) {
			return state.showMySequencesOnly
		},
		showMyToolsOnly(state) {
			return state.showMyToolsOnly
		},
		showVideoSnippets(state) {
			return state.showVideoSnippets
		},
		showTools(state) {
			return state.showTools
		},
		showSequences(state) {
			return state.showSequences
		},
		showFavoriteVideoSnippets(state) {
			return state.showFavoriteVideoSnippets
		},
		showFavoriteTools(state) {
			return state.showFavoriteTools
		},
		showFavoriteSequences(state) {
			return state.showFavoriteSequences
		},
	},
	modules: {
		shared,
		user,
		tags,
		video,
		videoSnippet,
		sequence,
		tool
	}
});
