export default {
  state: {
    loading: false,
    error: null
  },
  mutations: {
    startCall(state) {
      state.loading = true;
      state.error = null;
    },
    successCall(state) {
      state.loading = false;
      state.error = null;
    },
    errorCall(state, payload) {
      state.loading = false;
      state.error = payload;
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setError(state, payload) {
      state.error = payload
    },
    clearError(state) {
      state.error = null
    }
  },
  actions: {
    clearError({commit}) {
      commit('clearError')
    },
    errorCall({commit}, payload) {
      commit('errorCall', payload)
    },
    setError({commit}, payload) {
      commit('setError', payload)
    },
    clearEverythingRequiringAuth({commit}) {
      // console.debug("clearing everything that requires auth (tools, sequences ...)");
      // commit('clearTools');
      // commit('clearSequences');
    },
  },
  getters: {
    loading(state) {
      return state.loading
    },
    error(state) {
      return state.error
    }
  }
}
