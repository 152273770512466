import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import * as GlobalProjectProperties from '../../projectProperties'
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        application: GlobalProjectProperties.appWhiteColor,
        primary: GlobalProjectProperties.appPrimaryColor,
        secondary: GlobalProjectProperties.appSecondaryColor,
        tertiary: GlobalProjectProperties.appTertiaryColor,
        accent: GlobalProjectProperties.appPrimaryColor,
        info: colors.shades.black,
        success: colors.green.accent3,
        warning: colors.red.accent3,
        error: colors.red.accent3,
        anchor: colors.shades.black,
        // own theme variables:
        onPrimary: GlobalProjectProperties.appOnPrimaryColor,
        onSecondary: GlobalProjectProperties.appOnSecondaryColor,
        onTertiary: GlobalProjectProperties.appOnTertiaryColor,
        onDark: GlobalProjectProperties.appOnPrimaryColor,
        onLight: GlobalProjectProperties.appOnSecondaryColor,
        appWhite: GlobalProjectProperties.appWhiteColor,
        appBlack: GlobalProjectProperties.appBlackColor,
        avatar: GlobalProjectProperties.appAvatarColor,
        favorite: GlobalProjectProperties.appFavoriteColor,
        cancel: colors.green.base,
        delete: colors.red.accent3,
        submit: GlobalProjectProperties.appPrimaryColor,

        // usage example in style scoped:
        // background-color: var(--v-accent-lighten2);

        // secondary color amber
        // <color name="primaryColor">#137885</color>
        // <color name="primaryLightColor">#51a7b5</color>
        // <color name="primaryDarkColor">#004c58</color>
        // <color name="secondaryColor">#ffca28</color>
        // <color name="secondaryLightColor">#fffd61</color>
        // <color name="secondaryDarkColor">#c79a00</color>
        // <color name="primaryTextColor">#fafafa</color>
        // <color name="secondaryTextColor">#263238</color>
        
        // variant with light orange as secondary color
        // <color name="primaryColor">#137885</color>
        // <color name="primaryLightColor">#51a7b5</color>
        // <color name="primaryDarkColor">#004c58</color>
        // <color name="secondaryColor">#ffcc80</color>
        // <color name="secondaryLightColor">#ffffb0</color>
        // <color name="secondaryDarkColor">#ca9b52</color>
        // <color name="primaryTextColor">#fafafa</color>
        // <color name="secondaryTextColor">#263238</color>
      },
      dark: {
        application: GlobalProjectProperties.appBlackColor,
        primary: GlobalProjectProperties.appPrimaryColor,
        secondary: GlobalProjectProperties.appSecondaryColor,
        tertiary: GlobalProjectProperties.appOnTertiaryColor,
        anchor: colors.shades.white,
        // own theme variables:
        onPrimary: GlobalProjectProperties.appOnPrimaryColor,
        onSecondary: GlobalProjectProperties.appOnSecondaryColor,
        onTertiary: GlobalProjectProperties.appTertiaryColor,
        onDark: GlobalProjectProperties.appOnPrimaryColor,
        onLight: GlobalProjectProperties.appOnSecondaryColor,
        avatar: GlobalProjectProperties.appAvatarColor,
        appWhite: GlobalProjectProperties.appWhiteColor,
        appBlack: GlobalProjectProperties.appBlackColor,
        favorite: GlobalProjectProperties.appFavoriteColor,
        cancel: colors.green.base,
        delete: colors.red.accent3,
        //primary: colors.blue.lighten3,
      },
    },
  }
});
