async function loadYoutubeData(videoId) {
    if (!videoId) {
        throw new Error ("Can't load youtube data, since videoId is not defined or empty; value: '" + videoId + "'")
      } else {
        // TODO: [OP-156] put API key to private local properties and remove from git?
        const key = "AIzaSyAIVjKr3cCBXgRyPEGB1ajKRd9LKAFTzo8"; // jans API key from the project opp-02 not opp-1(!)
        const url = `https://www.googleapis.com/youtube/v3/videos?key=${key}&part=snippet,contentDetails&id=${videoId}`;

        try {

            const response = await fetch(url);

            // if response is not ok, then throw an error
            if (!response.ok) {
                throw new Error ("Response is not ok, status: '" + response.status + "'")
            }

            const data = await response.json();

            // if data.items is empty, then the videoId is not valid
            if (data?.items?.length === 0) {
                throw new Error ("Received no data items, maybe the video id is not valid.")
            }

            return {
                title: data.items[0].snippet?.title || "",
                description: data.items[0].snippet?.description || "",
                duration: data.items[0].contentDetails?.duration || "",
                tags: data.items[0].snippet?.tags || [],
                channelTitle: data.items[0].snippet?.channelTitle || "",
                channelId: data.items[0].snippet?.channelId || "",
                publishedAt: data.items[0].snippet?.publishedAt || "",
                thumbnailUrl: data.items[0].snippet?.thumbnails?.high?.url || ""
            };
        } catch (error) {
            throw new Error ("Can't load youtube data for videoId: '" + videoId + "', '" + error + "'")
        }

      }
}

async function loadYoutubePlaylistData(playlistId) {
    if (!playlistId) {
        throw new Error ("Can't load youtube playlist data, since playlistId is not defined or empty; value: '" + playlistId + "'")
    }
    // const key = process.env.VUE_APP_YOUTUBE_API_KEY;
    const key = "AIzaSyAIVjKr3cCBXgRyPEGB1ajKRd9LKAFTzo8"; // jans API key from the project opp-02 not opp-1(!)
    const url = `https://www.googleapis.com/youtube/v3/playlists?key=${key}&part=snippet,contentDetails&id=${playlistId}`;

    try {
        const response = await fetch(url);

        // if response is not ok, then throw an error
        if (!response.ok) {
            throw new Error ("Response is not ok, status: '" + response.status + "'")
        }

        const data = await response.json();

        // if data.items is empty, then the playlistId is not valid
        if (data?.items?.length === 0) {
            throw new Error ("Received no data items, maybe the playlist id is not valid.")
        }

        return {
            title: data.items[0].snippet?.title || "",
            description: data.items[0].snippet?.description || "",
            // tags: data.items[0].snippet?.tags || [],
            duration: data.items[0].contentDetails?.duration || "",
            channelTitle: data.items[0].snippet?.channelTitle || "",
            channelId: data.items[0].snippet?.channelId || "",
            publishedAt: data.items[0].snippet?.publishedAt || "",
            // thumbnailUrl: data.items[0].snippet?.thumbnails?.high?.url || ""
            // numberOfVideos: data.items[0].contentDetails?.itemCount || 0, // not available
        };
    } catch (error) {
        throw new Error ("Can't load youtube playlist data for playlistId: '" + playlistId + "', '" + error + "'")
    }
}

async function getVideoIdsForPlaylist(playlistId) {

    // TODO: [OP-218] allow more than 50 videos per playlist in javascript import

    const key = "AIzaSyAIVjKr3cCBXgRyPEGB1ajKRd9LKAFTzo8"; // jans API key from the project opp-02 not opp-1(!)
    const url = `https://www.googleapis.com/youtube/v3/playlistItems?key=${key}&part=snippet&playlistId=${playlistId}&maxResults=50`;

    // return a list of playlist items
    const playlistItems = await fetch(url)
        .then(response => response.json())
        .then(data => {
            return data.items;
        });

    // remove private videos
    const playlistItemsWithoutPrivateVideos = playlistItems.filter(item => item.snippet.title !== "Private video");

    // console.debug("playlistItemsWithoutPrivateVideos: ", playlistItemsWithoutPrivateVideos);

    // return a list of unique videoIds
    const videoIds = [...new Set(playlistItemsWithoutPrivateVideos.map(item => item.snippet.resourceId.videoId))];

    return videoIds;
}

function constructYoutubeThumbnailUrl(videoId) {
    const url = `https://i.ytimg.com/vi/${videoId}/default.jpg`;
    return url;
}

function constructYoutubeThumbnailUrlHq(videoId) {
    const url = `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
    return url;
}

function extractDataFromYouTubeUrl(url) {

    const data =  {
        id: undefined,
        start: undefined,
        end: undefined,
    }

    // works:
    // https://www.youtube.com/watch?v=qsC7y1FfFs4
    // https://youtube.com/watch?v=qsC7y1FfFs4&feature=share&t=5
    // https://www.youtube.com/watch?v=qsC7y1FfFs4&t=5
    // http://youtu.be/dQw4w9WgXcQ
    // http://www.youtube.com/embed/dQw4w9WgXcQ
    // http://www.youtube.com/watch?v=dQw4w9WgXcQ
    // http://www.youtube.com/?v=dQw4w9WgXcQ
    // http://www.youtube.com/v/dQw4w9WgXcQ
    // http://www.youtube.com/e/dQw4w9WgXcQ
    // http://www.youtube.com/user/username#p/u/11/dQw4w9WgXcQ
    // http://www.youtube.com/sandalsResorts#p/c/54B8C800269D7C1B/0/dQw4w9WgXcQ
    // http://www.youtube.com/watch?feature=player_embedded&v=dQw4w9WgXcQ
    // http://www.youtube.com/?feature=player_embedded&v=dQw4w9WgXcQ
    // https://www.youtube.com/watch?v=B0QFlk14T1A&list=PLVJ4AORWwxCyYuTytQJx2TShTrTciGW4J&index=3&t=85s
    // https://www.youtube.com/watch?v=iuyzO2QkY7A&t=51&xy=z
    // https://www.youtube.com/watch?v=iuyzO2QkY7A&t=51s
    // https://www.youtube.com/watch?v=iuyzO2QkY7A& 
    // https://www.youtube.com/watch?v=iuyzO2QkY7A&xy=z
    // https://www.youtube.com/watch?v=iuyzO2QkY7A
    // https://youtu.be/S2wj8VX9O0M?t=61
    // https://youtu.be/S2wj8VX9O0M
    // https://youtube.com/watch?v=qsC7y1FfFs4&feature=share
    // https://www.youtube.com/shorts/H8ISUMivP4Q

    // does not yet work:
    // https://youtube.com/playlist?list=RDCMUCeARcCUiZg79SQQ-2_XNlXQ&playnext=1
    // www.youtube.com/watch?v=qsC7y1FfFs4 // here the http:// is missing and needs to be added
    // sdfsdf https://www.youtube.com/watch?v=qsC7y1FfFs4 // here the sdfsdf and space is not removed

    // tests: regexr.com/7m8t3 

    // find the id via a regex
    const regex = /^https?:\/\/(?:www\.)?(?:youtube(?:-nocookie)?\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?|(?:shorts)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/igm;
    let m;
    while ((m = regex.exec(url)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) { regex.lastIndex++; }
        // The result can be accessed through the `m`-variable.
        // m.forEach((match, groupIndex) => {
        //     console.debug(`Found match, group ${groupIndex}: ${match}`);
        // });
        data.id = m[1];
        // if (m[3] !== null && m[3] !== undefined && m[3] !== '') {
        //   data.id =  Number(m[3]);
        // }
    }

    const urlObject = new URL(url);
    var start = urlObject.searchParams?.get('t');
    if (start) {
        try {
        const positionOfS = start?.indexOf('s');
        if (positionOfS == -1) {
            data.start =  Number(start);
        } else {
            data.start = Number(start.slice(0,positionOfS));
        }
        } catch (error) {
        throw new Error("Warning: Start value could not be determined from the URL you provided");
        }
    }

    // console.debug("extractDataFromYouTubeUrl: data: ", data);
    
    return data;
}

module.exports = {
    loadYoutubeData: loadYoutubeData,
    loadYoutubePlaylistData: loadYoutubePlaylistData,
    getVideoIdsForPlaylist: getVideoIdsForPlaylist,
    constructYoutubeThumbnailUrl: constructYoutubeThumbnailUrl,
    constructYoutubeThumbnailUrlHq: constructYoutubeThumbnailUrlHq,
    extractDataFromYouTubeUrl: extractDataFromYouTubeUrl,
};