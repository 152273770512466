<template>
  <v-container>
  <div v-if="videoSnippet && user">
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3>
        <div v-if="loading" class="loading text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <!-- <v-alert v-if="error" type="error" dismissible>{{ error.message }}</v-alert> -->
        <form @submit.prevent="saveOrUpdate" ref="form" lazy-validation>
          <v-card flat class="xs-0 mt-sm-16">
            <v-card-title class="pa-6 text-h5 h-break">
              {{ $t('video-snippet.edit.caption')}}
            </v-card-title>
            <v-card-text class="px-6 py-0 my-0 text-body-1">
              <v-text-field
                  v-model="externalVideoId"
                  :label="$t('video-snippet.edit.video-id-label')" outlined
                  :placeholder="$t('video-snippet.edit.video-id-placeholder')"
                  disabled
              ></v-text-field>
              <!-- (default) url of the referenced video as retrieved via the api on creation -->
              <v-img class="mt-0 mb-7" height="250" v-if="video?.thumbnailUrl" :src="video.thumbnailUrl"></v-img>
              <!-- stored url of the youtube image or file uploaded -->
              <v-img
                  v-if="editedImageSrc && editedImageSrc !==''"
                  :src="editedImageSrc"
                  class="mb-4"
              ></v-img>
              <!-- TODO: delete button not shown if no file stored, only an url -->
              <div v-if="editedImageSrc && videoSnippet.storageUrl && videoSnippet.storageUrl !== '' " class="mb-12"> 
                <v-btn @click="deleteImage()" color="secondary">{{ $t('video-snippet.edit.image-delete-label')}}</v-btn>
              </div>
              <v-file-input
                  v-if="userIsFamily"
                  :label="$t('video-snippet.edit.image-upload-placeholder')"
                  prepend-icon="mdi-camera"
                  filled outlined
                  accept="image/png, image/jpeg, image/bmp"
                  ref="fileInput1"
                  @change="fileInput1Changed($event)"
              ></v-file-input>
              <!-- display start end end time inputs if the user selects this -->
              <v-checkbox v-model="editStartOrEnd" :label="$t('video-snippet.create.expand-edit-start-end-label')"></v-checkbox>
              <hours-minutes-seconds-picker 
                v-if="editStartOrEnd"
                v-model="editedVideoSnippetStart" 
                :label="$t('video-snippet.edit.video-start-time-label')" 
                :placeholder="$t('video-snippet.edit.video-start-time-placeholder')"
              />
              <hours-minutes-seconds-picker
                v-if="editStartOrEnd"
                v-model="editedVideoSnippetEnd" 
                :label="$t('video-snippet.edit.video-end-time-label')" 
                :placeholder="$t('video-snippet.edit.video-end-time-placeholder')"
              />
              <!-- Tagging -->
              <v-combobox 
                v-model="editedSelectedTags"
                :items="existingTags"
                :label="$t('video-snippet.edit.tags-label')"
                multiple
                small-chips
                outlined
                deletable-chips
                :delimiters="[',',';']"
                hide-selected
                :rules="tagRules"
                class="tag-input"
                :search-input.sync="tagSearch"
                >
                  <template v-slot:no-data>
                  <v-list-item v-if="tagSearch">
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{ tagSearch }}</strong>". Press <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <!-- not in use -->
                <!--
                clearable
                @keyup.tab="updateTags"
                @paste="updateTags" 
                append-icon // causes the arrow to disappear
                dense // layout problems
                -->
              </v-combobox>
              <v-text-field
                  v-if="userIsFamily"
                  v-model="editedName"
                  :counter="120"
                  :rules="editedNameRules"
                  :label="$t('video-snippet.edit.name-label')"
                  outlined
                  :placeholder="$t('video-snippet.edit.name-placeholder')"
              >
              </v-text-field>
              <v-textarea
                  v-if="userIsFamily"
                  v-model="editedDescription"
                  :label="$t('video-snippet.edit.description-label')"
                  :placeholder="$t('video-snippet.edit.description-placeholder')"
                  auto-grow outlined
              >
              </v-textarea>
              <!-- <v-checkbox v-model="editedPublished" :label="$t('video-snippet.create.published-label')"></v-checkbox> -->
              <!-- vuetify select for three options "published" "to-approve" "hidden" -->
              <v-select
                  v-if="userIsFamily"
                  v-model="editedPublished"
                  :items="['published', 'to-approve', 'hidden']"
                  :label="$t('video-snippet.create.published-label')"
                  outlined></v-select>

            </v-card-text>
            <v-card-actions class="mx-4 mb-4">
              <v-btn type="submit" color="primary" class="px-8" :disabled="loading || !allTagsValid" :loading="loading">
                {{ editedPublished === "published" ? $t('video-snippet.edit.button-save-and-publish') : $t('video-snippet.edit.button-save') }}
              </v-btn>
              <v-btn text @click="cancel">{{ $t('nav.cancel') }}</v-btn>
              <v-spacer/>
              <!-- <v-bottom-sheet v-model="youtubeSheet">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on">
                    Mit Youtube Daten füllen ...
                  </v-btn>
                </template>
                <v-list>
                  <v-subheader>Mit Youtube Daten füllen:</v-subheader>
                  <v-list-item @click="fillYoutube(true, false, false, false)">
                    <v-list-item-title>Titel holen</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fillYoutube(false, true, false, false)">
                    <v-list-item-title>Beschreibung holen</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fillYoutube(false, false, true, false)">
                    <v-list-item-title>Thumbnail-Bild holen</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fillYoutube(false, false, false, true)">
                    <v-list-item-title>Tags holen</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-bottom-sheet> -->
            </v-card-actions>
          </v-card>
        </form>
      </v-flex>
    </v-layout>
  </div>
  <div v-else>
    {{ videoSnippet ? '' : $t('video-snippet.show.no-entity-selected-message') }}
    {{ user ? '' : 'You need to login in for editing.' }}
  </div>
  </v-container>
</template>

<script>
import {loadYoutubeData} from "@/service/youtube";
import HoursMinutesSecondsPicker from "@/components/inputs/HoursMinutesSecondsPicker";
import statelessHelpers from '@/mixins/statelessHelpers';

export default {
  name: "VideoSnippetEdit",
  components: {
    HoursMinutesSecondsPicker
  },
  props: ['id'],
  data() {
    return {
      maxTagLength: 40,
      tagSearch: null,
      // youtubeSheet: false,
      videoSnippet: null,
      video: null,
      editedName: '',
      editedDescription: '',
      editedImage: null, // the image as binary
      editedImageSrc: '', // will also contain the preview image as string-converted binary 
      editedVideoId: '',
      editStartOrEnd: false,
      editedVideoSnippetStart: 0,
      editedVideoSnippetEnd: 0,
      editedSelectedTags: [],
      editedPublished: '',
      // search: "", // not in use atm
      editedNameRules: [
        // v => !!v || this.$t('video-snippet.edit.name-rule-required'),
        v => (v?.length <= 120) || this.$t('video-snippet.edit.name-rule-length-min'),
      ],
      tagRules: [
        (v) => {
          if (v.length > 0) {
            for (let i = 0; i < v.length; i++) {
              if (v[i].length > this.maxTagLength)
                return this.$t('tag.edit.tag-rule-length-max', {maxTagLength: this.maxTagLength});
              }
            return true;
          } else
            return true;
        }
      ]
    };
  },
  watch: {
    tagSearch(newVal) {
        setTimeout(() => {
          // necessary, since the syncronization of the search input field is delayed 
          // - better solution? wait for the sync event to finish?
          if (this.tagSearch === newVal) {
            if (newVal) this.tagSearch = this.cleanTag(newVal);
          }
        }, 250);
    },
  },
  computed: {
    loading: function() {
      return this.$store.getters.loading;
    },
    externalVideoId() {
      if (!this.video)
        return '';
      return this.video.externalId;
    },
    // error: function() {
    //   return this.$store.getters.error;
    // },
    user () {
      return this.$store.getters.user;
    },
    userIsAuthenticated() {
      return this.user !== null && this.user !== undefined
    },
    userIsFamily() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("family");
    },
    allTagsValid(){
      if (this.editedSelectedTags && this.editedSelectedTags !== undefined) {
        for (let i = 0; i < this.editedSelectedTags.length; i++) {
          if (this.editedSelectedTags[i].length > this.maxTagLength)
            return false;
          }
        }
      return true;
    },
    existingTags () {
      return this.$store.getters.tagValues || []; // TODO: [OP-163] make sure on creation and usage of tags that always the lowercase is used
    }
  },
  async created() {
    // this.videoSnippet = this.$store.getters.videoSnippets.find((s) => s.id === this.id);
    // this.video = this.$store.getters.videos.find((s) => s.id === this.videoSnippet.videoId);

    if (!this.videoSnippet) {
        console.debug("trying to get video snippet from store ...")
        this.videoSnippet = this.$store.getters.videoSnippetById(this.id);
        if (!this.videoSnippet) {
          console.debug("video snippet not available yet in store, loading from db ...")
          await this.$store.dispatch('loadVideoSnippet', this.id);
          this.videoSnippet = this.$store.getters.videoSnippetById(this.id);
        }
      }
      if (this.videoSnippet) {
        this.video = this.$store.getters.videoById(this.videoSnippet.videoId);
        if (!this.video) {
          console.debug("video not available yet in store, loading from db ...")
          await this.$store.dispatch('loadVideo', this.videoSnippet.videoId);
          this.video = this.$store.getters.videoById(this.videoSnippet.videoId);
        }
      }

    this.loadForEditing();
  },
  mixins: [statelessHelpers],
  methods: {
    tagIsAlreadyAdded(tag) {
      return this.editedSelectedTags.includes(tag);
    },
    loadForEditing() {
      if (!this.loading && this.videoSnippet) {
        this.editedName = this.videoSnippet.name || '';
        this.editedDescription = this.videoSnippet.description || '';
        this.editedImageSrc = this.videoSnippet.imageSrc;
        this.editedVideoId = this.videoSnippet.videoId || '';
        this.editedVideoSnippetStart = this.videoSnippet.videoStart || 0;
        this.editedVideoSnippetEnd = this.videoSnippet.videoEnd || 0;
        this.editedSelectedTags = this.videoSnippet.tags  || [];
        this.editedPublished = this.videoSnippet.published  || 'to-approve';
      }
    },
    cancel() {
      this.$router.push({ name: 'VideoSnippetShow', params: { id: this.id }});

    },
    async saveOrUpdate() {

      this.$store.dispatch("createTags", { tagValues: this.editedSelectedTags }); 

      // update orginal object
      this.videoSnippet.name = this.editedName;
      this.videoSnippet.description = this.editedDescription;
      this.videoSnippet.imageSrc = this.editedImageSrc;
      this.videoSnippet.videoId = this.editedVideoId;
      this.videoSnippet.videoStart = this.editedVideoSnippetStart;
      this.videoSnippet.videoEnd = this.editedVideoSnippetEnd;
      this.videoSnippet.tags = this.editedSelectedTags ? this.editedSelectedTags : [];
      this.videoSnippet.published = this.editedPublished;
      this.videoSnippet.lastEditedBy = {id : this.user.id, nickname: this.user.nickname };
      this.$store.dispatch("updateVideoSnippetWithImage", { payload: this.videoSnippet, imageFile: this.editedImage })
          .then(() => this.cancel());
    },
    deleteImage() {
      this.$store.dispatch("deleteVideoSnippetImage", this.videoSnippet )
          .then(() => this.editedImageSrc = "");
    },
    updateTags() {
      // TODO: This does not work (anymore?) since search is null and the new tag is already in the editedTags array (including contained ",")
      // this.$nextTick(() => {
      //   this.editedSelectedTags.push(...this.search.split(","));
      //   this.$nextTick(() => {
      //     this.search = "";
      //   });
      // });
    },
    fileInput1Changed(event) {
      console.debug("fileInput1Changed ", event);
      
      let filename = event.name;
      if (filename.lastIndexOf('.') <= 0) {
        return alert ('Please add a file with file extension!');
      }

      const fileReader = new FileReader();
      fileReader.addEventListener('load', () => {
          this.editedImageSrc = fileReader.result; // save the image as string for preview
      })
      fileReader.readAsDataURL(event);
      this.editedImage = event; // save the image file 
    },
    // async fillYoutube(title, description, thumbnail, tags) {
    //   if (this.editedExternalVideoId === null || this.editedExternalVideoId === undefined) {
    //     throw new Error ("Can't load youtube data, since editedExternalVideoId is not defined")
    //   } else {
    //     const youtubeData = await loadYoutubeData(this.video.externalId);
    //     console.debug("youtubeData", youtubeData);
    //     if (title) this.editedName = youtubeData.title;
    //     if (description) this.editedDescription = youtubeData.description;
    //     if (thumbnail) this.editedImageSrc = youtubeData.thumbnailUrl;
    //     if (tags) this.editedSelectedTags = youtubeData.tags;
    //     this.youtubeSheet = false;
    //   }
    // },
    validYoutubeID(someString){
      return someString.length == 11;
    }
  }
};
</script>

<style scoped>
</style>
