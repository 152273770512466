import store from '../store';

export default async (to, from, next) => {
  console.debug("auth-guard is checking access to " + to.path)
  try {
    const user = store.getters.user;
    if (!user) {
      throw new Error("No user logged in, cannot check access to " + to.path);
    }
    else if (!user.roles) {
      throw new Error("Found a user, but no user roles found, cannot check access to " + to.path);
    } else {
      // console.debug("user.roles: ", user.roles);
      
      if (false) {
      } else if (to.path == "/tool/create") {
        if (user.roles.includes("family") || user.roles.includes("admin")) {
          next()
        } else {
          throw new Error(errorMsgMissingRole(to, user));
        }
      } else if (to.path == "/favorite") {
        if (user.roles.includes("family") || user.roles.includes("admin")) {
          next()
        } else {
          throw new Error(errorMsgMissingRole(to, user));
        }
      } else if (to.path == "/admin") {
        if (user.roles.includes("admin")) {
          next()
        } else {
          throw new Error(errorMsgMissingRole(to, user));
        }
      } else {
        next()
      }

    }

  } catch (error) {
    console.warn("Routing to Signin. Reason: " + error)
    next({
      name: "Signin", // back to safety route //
      query: { redirectedFrom: to.fullPath } // store the old path to redirect after successful signon
    })
  }
}

function errorMsgMissingRole(to, user) {
  return "Routing to " + to.fullPath + " prevented by auth-guard: Missing role. Available roles: " + user.roles;
}

