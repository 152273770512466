import Vue from 'vue';
import { collection, doc, getDocs, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { db, storage } from "@/plugins/firebase";
import data from '@/data';
import i18n from "@/i18n";

export default {
	state: {
		tools: []
	},
	getters: {
		tools: state => {
			return state.tools;
		}
	},
	// Actions returns Promises, see https://mclassdesigns.gitbook.io/js-journal/
	actions: {
		loadTools({ commit }) {
			commit('startCall');
			return getDocs(collection(db, "tools"))
				.then((queryTools) => {
					commit('clearTools');
					queryTools.forEach((doc) => {
						const data = doc.data();
						commit('addTool', {
							id: doc.id,
							userId: data.userId,
							published: data.published,
							name: data.name,
							description: data.description,
							videoId: data.videoId,
							videoStart: data.videoStart,
							videoEnd: data.videoEnd,
							votesUp: data.votesUp || [],
							votesDown: data.votesDown || [],
							tags: data.tags || [],
							imageSrc: data.imageSrc
						});
					});
					commit('successCall');
					return true;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		},
		createExampleTools({ commit, dispatch }, userId) {
			commit('startCall');
			for (const [id, measure] of Object.entries(data.measures)) {
				dispatch('createTool', {
					userId: userId,
					// published: true, // TODO: [OP-153] use published value
					name: measure.name,
					description: measure.description,
					videoId: measure.defaultVideoId,
					videoStart: measure.defaultVideoStart,
					videoEnd: measure.defaultVideoEnd,
					votesUp: [],
					votesDown: [],
					tags: measure.tags || [],
					imageSrc: ""
				});
			}
			commit('successCall');
		},
		createTool({ commit }, tool) {
			commit('startCall');
			return addDoc(collection(db, "tools"), tool)
				.then((createdTool) => {
					commit('addTool', {
						id: createdTool.id,
						userId: tool.userId,
						name: tool.name,
						description: tool.description,
						imageSrc: tool.imageSrc,
						videoId: tool.videoId,
						videoStart: tool.videoStart,
						videoEnd: tool.videoEnd,
						votesUp: [],
						votesDown: []
					});
					commit('successCall');
					return createdTool.id;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		},
		updateTool({ commit }, payload) {
			commit('startCall');
			return updateDoc(doc(db, "tools", payload.id), payload)
				.then(() => {
					commit('setTool', payload);
					commit('successCall');
					return true;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		},
		updateToolWithImage({ dispatch, commit }, { payload, imageFile }) {
			if (imageFile) { // first upload the image, then update the tool
				if (imageFile.size > (5 * 1024 * 1024)) {
					const error = new Error(i18n.t('error.upload.image-too-large'))
					commit('errorCall', error );
					return Promise.reject(error);
				}
				const filename = imageFile.name;
				const ext = filename.slice(filename.lastIndexOf('.'));
				const storageUrl = 'tools/' + payload.id + ext;
				const imageStorageRef = storageRef(storage, storageUrl);
				commit('setLoading', true);
				return uploadBytes(imageStorageRef, imageFile)
					.then(async (snapshot) => {
						console.debug('Uploaded a blob or file!', snapshot);

						// const orginalDownloadUrl =  getDownloadURL(imageStorageRef).then((downloadURL) => {
						// 	console.debug('File available at', downloadURL);
						// 	payload.imageSrc = downloadURL;
						// 	payload.storageUrl = storageUrl;
						// 	return dispatch("updateTool", payload);
						// });

						// this is how to access the images via the public url (profilImages don't have a public url though)
						// https://firebasestorage.googleapis.com/v0/b/opp-01-9caaf.appspot.com/o/tools%2Fresized%2FgW7WTJ3trgb1CS1HkqaL_800x800.jpeg?alt=media 
						// note that %2F is used to encode the slashs in the storage buckets file path
						// public url reading may be limited by the storage rules, token urls are not affected by the rules:
						// https://firebase.google.com/docs/storage/security/core-syntax?hl=de#version-2
						// https://stackoverflow.com/questions/75088043/firebase-cloud-storage-restrict-read-of-access-token 

						// wait until firebases image resizes extension has resized the image 
						// problematic if the time is not enough and the file is not yet resized, cause a new token will be stored 
						// TODO: [OP-181] store url of resized images base on event mechanism and delete resized images as well 
						// -> replace by waiting for event that the resize function triggers
						// await new Promise(r => setTimeout(r, 3000));

						const bucketId = 'opp-01-9caaf.appspot.com' // TODO: make this dynamic
						const resizedStorageUrl = 'tools/resized/' + payload.id + "_800x800.jpeg";
						// adding the modified parameter will trigger a reload of cached changed images
						const modified = new Date().getTime().toString();
						const resizedPublicUrl = 'https://firebasestorage.googleapis.com/v0/b/' + bucketId + '/o/' + 'tools%2Fresized%2F' + payload.id + '_800x800.jpeg?alt=media&modified=' + modified;
						// const newResizedImageStorageRef = storageRef(storage, resizedStorageUrl);
						// return getDownloadURL(newResizedImageStorageRef).then((downloadURL) => {
						// 	console.debug('File available at', downloadURL);
						// 	payload.imageSrc = downloadURL;
						// 	payload.storageUrl = resizedStorageUrl;
						// 	commit('setLoading', false);
						// 	return dispatch("updateTool", payload);
						// });

						payload.imageSrc = resizedPublicUrl;
						payload.storageUrl = resizedStorageUrl;
						// commit('setLoading', false);
						return dispatch("updateTool", payload);
					})
					.catch((error) => {
						if(error.code == "storage/unauthorized") {
							error = new Error(i18n.t('error.upload.image-too-large'));
						}
						commit('errorCall', error);
						console.error(error);
						return Promise.reject(error);
					});
			}
			return dispatch("updateTool", payload);
		},
		deleteToolImage({ dispatch, commit }, { payload }) {
			if (payload.storageUrl) { // first upload the image, then update the tool
				const imageStorageRef = storageRef(storage, payload.storageUrl);
				// Delete the file
				deleteObject(imageStorageRef).then(() => {
					// File deleted successfully
					payload.imageSrc = "";
					payload.storageUrl = "";
					return dispatch("updateTool", payload);
				}).catch((error) => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
			}
		},
		deleteTool({ commit }, payload) {
			commit('startCall');
			return deleteDoc(doc(db, "tools", payload.id))
				.then(() => {
					commit('deleteTool', payload);
					commit('successCall');
					return true;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
			// TODO Delete image
		}
	},
	mutations: {
		clearTools(state) {
			state.tools = [];
		},
		addTool(state, payload) {
			state.tools.push(payload);
		},
		setTool (state, payload) {
			const tool = state.tools.find(m => m.id === payload.id );
			if (tool) {
				tool.name = payload.name
				tool.userId = payload.userId,
				tool.description = payload.description
				tool.videoId = payload.videoId
				tool.videoStart = payload.videoStart
				tool.videoEnd = payload.videoEnd
				tool.upvotes = payload.upvotes
				tool.downvotes = payload.downvotes
				tool.imageSrc = payload.imageSrc
				tool.image = payload.image
				tool.tags = payload.tags
			}
		},
		deleteTool(state, payload) {
			Vue.delete(state.tools, state.tools.indexOf(payload));
		}
	}
}
