<template>
  <v-app>
    <!-- 
      TODO: [OP-209] evtl. rebuild app-bar  based on https://vuetifyjs.com/en/components/app-bars/#images
      Many manual adjustments necessary to make it look like the current version
    -->
    <v-app-bar app color="primary" elevate-on-scroll :dense="$vuetify.breakpoint.xsOnly">
      <v-container class="pa-0 fill-height flex-nowrap">
        <v-tooltip :disabled="$vuetify.breakpoint.mdAndDown" bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn icon @click="goBack()" v-on="{ ...tooltip }">
              <v-icon color="onPrimary" x-large>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('nav.top.navigate-before') }}</span>
        </v-tooltip>
        <!-- <v-img @click="goDashboard" class="title d-none d-sm-flex mr-2" contain height="85%" max-height="85%"
          src="@/assets/logo.svg"></v-img> -->
        <a @click="goDashboard">
          <span @dblclick="hideSignOn = false" @click="goDashboard" class="title mx-2">Open&nbsp;Physio</span>
        </a>
        <v-spacer></v-spacer>
        <v-btn v-if="!videoSearchView" :icon="$vuetify.breakpoint.smAndDown" :text="$vuetify.breakpoint.mdAndUp" :to="{ name: 'VideoSnippetSearch' }" class="mr-0 d-none d-sm-flex" dark>
            <v-icon sm-left>mdi-magnify</v-icon>
            <span class="d-none d-md-flex ml-1">{{ $t('nav.top.search-video-snippet') }} </span>
        </v-btn>
        <v-btn v-if="videoSearchView" :icon="$vuetify.breakpoint.smAndDown" :text="$vuetify.breakpoint.mdAndUp" :to="{ name: 'VideoSnippetCreate' }" class="mr-0" dark>
          <v-icon sm-left>mdi-plus-circle-outline</v-icon>
          <!-- <v-icon left>mdi-movie-plus</v-icon> -->
          <span class="d-none d-md-flex ml-1">{{ $t('nav.top.create-video-snippet-sm') }}</span>
        </v-btn>
        <template v-if="userIsAuthenticated">
          <v-menu left offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-avatar color="avatar" size="36" :style="'border: 0.15rem solid ' + $globalProjectProperties.appAvatarColor">
                  <v-img v-if="user.imageSrc" :src="user.imageSrc"></v-img>
                  <v-icon v-else color="onDark">mdi-account-circle</v-icon>
                </v-avatar>
              </v-btn>
            </template>
            <v-list>
              <v-list-item :to="{ name: 'ToolsCollectionsSearch' }" v-if="userIsFamily || userIsAdmin">
                <v-list-item-icon>
                  <v-icon>mdi-tools</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('nav.top.user.tools-sequences') }}</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item :to="{ name: 'Favorite' }" v-if="userIsFamily || userIsAdmin">
                <v-list-item-icon>
                  <v-icon>mdi-bookmark</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('nav.top.user.favorite') }}</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'Profile' }">
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('nav.top.user.profile') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="toggleTheme">
                <v-list-item-icon>
                  <v-icon>mdi-theme-light-dark</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('nav.top.user.toggle-theme') }}</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <!-- <v-list-item :to="{ name: 'AdminDashboard' }" v-if="userIsAdmin">
                <v-list-item-icon>
                  <v-icon>mdi-cog-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('nav.top.user.admin-dashboard') }}</v-list-item-title>
              </v-list-item> -->
              <v-divider v-if="userIsAdmin"></v-divider>
              <v-list-item @click="logout">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-else-if="!hideSignOn">
          <v-btn :to="{ name: 'Signin' }" text class="ml-2" dark>
            <v-icon sm-left>mdi-login</v-icon>
            <span class="d-none d-sm-block ml-1">{{ $t('nav.top.signon') }}</span>
          </v-btn>
          <v-btn :icon="$vuetify.breakpoint.smAndDown" :to="{ name: 'Signup' }" text class="ml-2 d-none d-sm-flex" dark>
            <v-icon sm-left>mdi-account-plus</v-icon>
            <span class="d-none d-md-block ml-1">{{ $t('nav.top.signup') }}</span>
          </v-btn>
        </template>
        <!-- <div class="d-none d-sm-flex mx-2 mx-sm-4"> -->
        <!-- <div class="mx-2 mx-sm-4">
          <LocaleSwitcher :languages="languages" v-on:locale-switched="changeLocale"/>
        </div> -->
      </v-container>
    </v-app-bar>
    <v-main>
        <v-alert v-if="error" type="error" dismissible prominent class="ma-2">{{ error.message }}</v-alert>
        <v-banner v-model="offline" transition="slide-y-transition" color="secondary">
          {{ $t('alert.offline') }}
          <template v-slot:actions="{ dismiss }">
            <v-btn text color="onSecondary" @click="dismiss">
              {{ $t('alert.dismiss') }}
            </v-btn>
            <!-- Reloading does not yet work, since the message is not shown again after reloading if still offline -->
            <!-- <v-btn text color="onSecondary" @click="reload()">
              {{ $t('alert.retry') }}
            </v-btn> -->
          </template>
        </v-banner>
      <!-- <v-container class="pa-0"> -->
        <router-view class="routerView"></router-view>
      <!-- </v-container> -->
    </v-main>
    <v-footer class="footer" style="height: 40px;">
      <v-container class="py-0 fill-height">
        © {{ new Date().getFullYear() }}&nbsp;<a href="https://jan.polowinski.de">Jan Polowinski</a>
         <v-spacer/>
         <a href="/impressum">Impressum</a>
         <v-spacer/>
         <a href="/about">über {{ $globalProjectProperties.appName }}</a>  
        <v-spacer/>
      </v-container>
    </v-footer>
    <!-- <v-btn v-if="userIsFamily || userIsAdmin" text :to="{ name: 'ToolCreate' }" class="mr-4">
        <v-icon left>mdi-plus</v-icon>
        {{ $t('nav.bottom.create-tool') }} 
    </v-btn> -->
  </v-app>
</template>

<script>

// import LocaleSwitcher from "@/components/LocaleSwitcher"
export default {
  components: {
    // LocaleSwitcher
  },
  data() {
    return {
      // languages currently redundant in views/user/Edit
      languages: [
        {
          id: 'en',
          title: 'English',
          // flagSrc: 'https://cdn.vuetifyjs.com/images/flags/us.png'
        },
        {
          id: 'de',
          title: 'Deutsch',
          // flagSrc: 'https://cdn.vuetifyjs.com/images/flags/us.png'
        }
      ],
      offline: false,
      hideSignOn: true,
    };
  },
  computed: {
    error: function() {
      return this.$store.getters.error;
    },
    userIsAuthenticated() {
      return this.user !== null && this.user !== undefined
    },
    user() {
      return this.$store.getters.user;
    },
    userIsAdmin() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("admin");
    },
    userIsFamily() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("family");
    },
    userLanguage () {
      if (this.user) {
        return this.user.language;
      } else
        return undefined;
    },
    videoSearchView() {
      if(this.$route.path == "/" || this.$route.path == "/video/search" ) {
        return true
      } else {
        return false
      }
    },
    createVideoSnippetView() {
      if(this.$route.path == "/video-snippet/create" ) {
        return true
      } else {
        return false
      }
    }
  },
  // only used in the ctx of components, when the error is not caught by a child component
  // https://www.w3schools.com/vue/ref_lh_errorCaptured.php
  errorCaptured(error, component, info) {
    error.message = "An error occured: " + error.message;
    this.$store.dispatch('setError', error);
    // console.error(error);
    // console.error("captured error by errorCaptured-hook: ");
    // console.error("Error in component: ");
    // console.error(component);
    // console.error("Info: " + info);
    // console.error(info);
  },
  watch: {
    userLanguage (newLang, oldLang) {
      if (newLang !== undefined && newLang !== null && newLang !== '') {
        this.$i18n.locale = newLang;
      }
      // console.debug("Lang was " + oldLang + ". Language is now " + newLang);
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.goDashboard();
    },
    goDashboard() {
      if (this.$route.name !== 'Dashboard') {
        this.$router.push({ name: 'Dashboard'});
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    reload() {
      window.location.reload();
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      if (this.userIsAuthenticated) {
        this.user.language = locale;
        // persist it via the user model, if logged in
        this.$store.dispatch("updateUser", this.user);
        // this.$router.push(this.switchLocalePath(id));
      }
    }
  },
  mounted() {
    window.addEventListener('offline', () => { this.offline = true });
    window.addEventListener('online', () => { this.offline = false });
  },
};
</script>

<style>
  @import '@/css/common.css';
</style>

<style scoped>
.title {
  cursor: pointer;
  color: var(--v-onPrimary-base);
  overflow: visible;
}
.v-main {
  background-color: var(--v-primary-base);
}
.routerView {
  background-color: var(--v-application-base);
}
</style>
<style>
/* Somehow the logo image in the title has a much too big overlay without this hack, leading to undesired clicks on the logo:
https://stackoverflow.com/questions/69921750/why-does-a-vuetify-image-have-padding-bottom-200-and-how-do-i-make-it-small/69923958#69923958 */
.title .v-responsive__sizer {
  padding-bottom: 0px !important;
}

.theme--dark.v-sheet, .theme--dark.v-card, .theme--dark.v-tabs-items,
.theme--light.v-sheet, .theme--light.v-card, .theme--light.v-tabs-items  {
  background-color: var(--v-application-base) !important;
}

/* otherwise the toolbar content has not enough space on google pixel */
.v-toolbar__content {
  padding-left: 2px !important;
  padding-right: 2x !important;
}

</style>
