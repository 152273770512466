<template>
<div class="video-preview">
  <v-card @click="openTool(tool.id)" class="my-2" hover>
    <span v-if="hasFavorited && userIsFamily" class="overlayBtnOnCards d-flex justify-center ">
      <v-icon :class="{favorited: hasFavorited}">mdi-bookmark</v-icon>
    </span>
    <v-img height="250" v-if="tool.imageSrc && tool.imageSrc !==''" :src="tool.imageSrc"></v-img>
    <v-img height="250" v-else src="https://via.placeholder.com/728x250.jpeg?text=Tool"></v-img>
    <v-card-title class="noWrap">{{ tool.name }}</v-card-title>
    <v-card-text class="cardText">
      {{ tool.description }}
    </v-card-text>
    <v-card-text>
      <v-chip small>
        <v-icon small>mdi-thumb-up</v-icon>
        <span class="pl-2">{{ tool.votesUp.length }}</span>
      </v-chip>
      <v-chip v-if="tool.videoId" small class="ml-2">
        <v-icon small>mdi-video</v-icon>
        <span v-if="videoLengthFormatted(tool)" class="pl-2">{{ videoLengthFormatted(tool) }}</span>
      </v-chip>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
export default {
  name: "ToolCard",
  props: ["tool"],
  computed: {
    user() { return this.$store.getters.user },
    userIsAuthenticated() {
      return this.user !== null && this.user !== undefined
    },
    userIsAdmin() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("admin");
    },
    userIsFamily() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("family");
    },
    hasFavorited() { 
      if (this.userIsFamily) {
        return this.user.toolFavoriteIds?.indexOf(this.tool.id) >= 0 || false 
      } else
        return false;
    }
  },
  methods: {
    suggestSelected(index) {
      this.searchTerm = this.suggest[index];
    },
    openTool(id) {
      this.$router.push({ name: "ToolShow", params: { id }});
    },
    videoLengthFormatted: function(tool) {
      if (!tool.videoId) return "";
      if ((!tool.videoStart || tool.videoStart === "0") || (!tool.videoEnd || tool.videoEnd === "0")) return "";
      return this.$options.filters.timeInHours(tool.videoEnd - tool.videoStart);
    }
  }
};
</script>

<style scoped>
.noWrap {
  display: block;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cardText {
  font-size: 100%;
  height: 62px;
  max-height: 62px;
  overflow: hidden;
}
</style>
