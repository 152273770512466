// ! not used by vue.config.js
// used by vuetify themes

const projectProperties = {
    "appName" : "Open Physio Search",
    "appVersion" : "0.0.1",
    "appPrimaryColor" : "#137885",  // open physio color
    // "appSecondaryColor" : "#FFCA28", // amber
    // "appSecondaryColor" : "#ffcc80", // variant in light orange
    "appSecondaryColor" : "#DEB887", // old avatar color (gold-like)
    "appTertiaryColor" : "#F5F5F4", // for text background (paper), was FAFAFA before
    "appOnPrimaryColor" : "#F5F5F4",  // readable on the primary color, was FAFAFA before
    "appOnSecondaryColor" : "#263238", // readable on the secondary color
    "appOnTertiaryColor" : "#263238", // text on paper
    "appWhiteColor" : "#F5F5F4", // almost white, was FAFAFA before
    "appBlackColor" : "#263238", // almost black
    "appAvatarColor" : "#DEB887",
    "appFavoriteColor" : "#e54872",
}
module.exports = projectProperties;