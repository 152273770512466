<template>
  <v-btn v-if="id" :href="youtubeLink" target="_blank" class="d-flex ma-0">
    <v-icon left>mdi-youtube</v-icon>
    {{ $t('nav.watch-on-platform') }} YouTube
  </v-btn>
</template>

<script>
export default {
  name: "YouTubeLink",
  props: {
    id: {
      type: String,
      required: true
    },
    start: {
      type: Number, // seconds
      default: 0
    },
    end: {
      type: Number // seconds
    },
    autoplay: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    endTimeArgument() {
      if (!this.end) {
        return "";
      } else {
        return "&end=" + this.end;
      }
    },
    youtubeLink() {
      //return "https://youtu.be/" + this.videoSnippet.id + "?t=" + this.videoSnippet.start ... // doesn't work for end time
      // works for end time:
      return "https://www.youtube.com/watch/" + 
        // "https://www.youtube.com/embed/" + 
        this.id + 
        "?start=" + this.start + 
        this.endTimeArgument + 
        "&autoplay=" + (this.autoplay ? '1' : '0') ;
    }
  }
};
</script>

<style scoped>
</style>
