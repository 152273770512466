<template>
  <div>
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3>
        <v-card v-if="user"  class="mt-sm-8 mt-lg-16">
          <v-img height="200" src="@/assets/sea.jpg"></v-img>
          <v-card-text class="px-6 py-0 my-0 text-body-1">
            <v-avatar color="avatar" size="128" style="margin-top: -64px; border: 5px solid white">
              <v-img v-if="user.imageSrc" :src="user.imageSrc"></v-img>
              <v-icon v-else dark x-large color="onDark">mdi-account-circle</v-icon>
            </v-avatar>
            <div style="margin-left: 180px; margin-top: -30px">
              <template v-if="user.realname">
                <p class="text-h4">{{user.realname}}</p>
                <p>{{ $t('user.profile.email-label') }}: {{user.email}}</p>
                <p>{{ $t('user.profile.nickname-label') }}: {{user.nickname || '-'}}</p>
              </template>
              <template v-else>
                <p class="text-h5">{{user.email}}</p>
                <p>{{ $t('user.profile.realname-label') }}: {{user.realname || '-'}}</p>
                <p>{{ $t('user.profile.nickname-label') }}: {{user.nickname || '-'}}</p>
              </template>
              <p>{{ $t('user.profile.language') }}: {{user.language || '-'}}</p>
            </div>
          </v-card-text>
          <v-card-actions class="px-8 pb-6 pt-16">
            <v-btn color="primary" @click="editProfile">{{ $t('crud.edit') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch('fetchUserData');
  },
  computed: {
    user () { return this.$store.getters.user}
  },
  methods: {
    editProfile() {
      this.$router.push({name: 'ProfileEdit'});
    },
  }
}
</script>