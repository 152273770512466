<!-- does not reference separate video docs, but only uses the data in the provided parameter object
  (which may be retrieved directly from search (e.g., solr ..)) -->
<template>
  <v-card
    v-if="videoSnippetSolrDoc"
    @click="openVideoSnippet(videoSnippetSolrDoc.id)"
    height="100%"
    :class="[
      userIsFamily || userIsAdmin ? '' : 'cursorDefault',
      'card flex-grow-1 d-flex flex-column my-2',
      isPublished ? '' : 'deactivated',
      isDeleted ? 'deleted' : '',
    ]"
    hover
  >
    <span
      v-if="hasFavorited && userIsFamily"
      class="overlayBtnOnCards d-flex justify-center"
    >
      <v-icon :class="{ favorited: hasFavorited }">mdi-bookmark</v-icon>
    </span>
    <div class="thumbnail-container">
      <v-img v-if="thumbnailUrl" :src="thumbnailUrl" class="thumbnail-image">
        <div
          style="
            position: absolute;
            right: 1rem;
            bottom: 0.6rem;
            color: white;
            z-index: 15;
            background-color: rgba(20, 20, 20, 0.5);
            padding: 0.01rem 0.4rem;
            border-radius: 0.2rem;
            font-size: small;
          "
        >
          {{ wholeVideoDuration }}
        </div>
      </v-img>
    </div>
    <v-card-title class="text-h6 cardTitle mx-2 my-2 pa-0">
      <!-- test
      {{ (userIsFamily && videoSnippetSolrDoc.name)? videoSnippetSolrDoc.name : videoSnippetSolrDoc.generatedName }} -->
      {{ videoSnippetSolrDoc.generatedName }}
    </v-card-title>
    <v-card-text v-if="videoSnippetSolrDoc.publisher" class="mx-2 my-2 pa-0">{{
      videoSnippetSolrDoc.publisher
    }}</v-card-text>
    <!-- c: <v-card-text v-if="videoSnippetSolrDoc.created" class="mx-2 my-2 pa-0">{{ videoSnippetSolrDoc.created }}</v-card-text> -->
    <!-- u: <v-card-text v-if="videoSnippetSolrDoc.updated" class="mx-2 my-2 pa-0">{{ videoSnippetSolrDoc.updated }}</v-card-text> -->
    <!-- i: <v-card-text v-if="videoSnippetSolrDoc.indexed" class="mx-2 my-2 pa-0">{{ videoSnippetSolrDoc.indexed }}</v-card-text> -->
    <v-card-text
      v-if="videoLengthFormatted(videoSnippetSolrDoc)"
      class="mx-2 my-2 pa-0"
    >
      <!-- <v-chip small>
        <v-icon small>mdi-thumb-up</v-icon>
        <span class="pl-2 mr-2">{{ videoSnippetSolrDoc.votesUp.length }}</span>
      </v-chip> -->
      <v-chip small>
        <v-icon small>mdi-video</v-icon>
        <span class="pl-2"
          >{{ videoLengthFormatted(videoSnippetSolrDoc) }}
          {{
            !(videoSnippetSolrDoc.videoStart && videoSnippetSolrDoc.videoEnd)
              ? ""
              : "(" + $t("video-snippet.card.snippet") + ")"
          }}</span
        >
      </v-chip>
    </v-card-text>
    <v-card-text class="cardText mx-2 my-0 px-0 pt-0 pb-3 pr-1">
      <v-chip
        v-for="(tag, i) in videoSnippetSolrDoc.tags.slice(0, 7)"
        :key="i"
        class="mr-2 mt-2"
        @click.stop="followTag(tag)"
        small
        color="primary"
        text-color="white"
      >
        {{ tag }}
      </v-chip>
    </v-card-text>
    <!-- description not useful atm, too much advertisment etc.
    <v-card-text class="text-md-body-1 text-body-2 cardText multiline-ellipsis mx-2 my-2 flex-grow-1">
      {{ videoSnippetSolrDoc.description? videoSnippetSolrDoc.description : "no description available" }}
    </v-card-text> -->
    <v-spacer></v-spacer>
    <!-- <div class="mx-2 my-2"><video-summary :video="video" :videoSnippet="videoSnippetSolrDoc"></video-summary></div> -->
    <v-card-actions
      v-if="!isDeleted && (canShare() || editAllowed)"
      class="mt-3"
    >
      <v-btn
        v-if="editAllowed"
        text
        @click="editVideoSnippet(videoSnippetSolrDoc.id)"
      >
        <v-icon class="mr-1" small>mdi-pencil</v-icon>
        {{ $t("crud.edit") }}
      </v-btn>
      <v-btn
        v-if="editAllowed"
        text
        @click.stop="toggleApproveVideoSnippet()"
        :class="{ approved: isPublished }"
      >
        <v-icon class="mr-1" small>mdi-check-decagram</v-icon>
        {{ isPublished ? $t("crud.approved") : $t("crud.approve") }}
      </v-btn>
      <v-spacer></v-spacer>

      <v-menu v-if="userIsAdmin">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <!-- <v-list-item @click="doSomething">
            <v-list-item-title>Button 3</v-list-item-title>
          </v-list-item> -->
          <!-- <v-list-item @click="doSomethingElse"> -->
          <v-list-item v-if="editAllowed">
            <v-dialog v-model="deleteDialog" persistent max-width="290">
              <template v-slot:activator="{ on }">
                <v-btn text v-on="on">
                  <v-icon class="mr-1" small>mdi-delete</v-icon>
                  {{ $t("crud.delete") }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline"
                  >{{ $t("crud.delete") }}?</v-card-title
                >
                <v-card-text>{{
                  $t("dialog.really-delete-undoable")
                }}</v-card-text>
                <v-spacer></v-spacer>
                <v-btn
                  color="cancel"
                  text
                  @click="deleteDialog = false"
                  class="ma-4"
                  >{{ $t("nav.cancel") }}</v-btn
                >
                <v-btn
                  color="delete"
                  text
                  @click="deleteVideoSnippet()"
                  class="ma-4"
                  >{{ $t("crud.delete") }}</v-btn
                >
              </v-card>
            </v-dialog>
          </v-list-item>
          <v-list-item v-if="canShare()">
            <!-- <share-button :url="'/video-snippet/show/' + videoSnippetSolrDoc.id"/> -->
            <share-button :url="'/?q=id:' + videoSnippetSolrDoc.id" />
          </v-list-item>
        </v-list>
      </v-menu>
      <share-button
        v-else-if="canShare()"
        :url="'/?q=id:' + videoSnippetSolrDoc.id"
      />
    </v-card-actions>

    <div v-if="!(userIsFamily || userIsAdmin)">
      <YouTubeLink
        v-if="
          videoSnippetSolrDoc.externalVideoId &&
          videoSnippetSolrDoc.platform === 'youtube'
        "
        :id="videoSnippetSolrDoc.externalVideoId"
        :start="videoSnippetSolrDoc.videoStart"
        :end="videoSnippetSolrDoc.videoEnd"
        autoplay
      />
      <VimeoLink
        v-else-if="
          videoSnippetSolrDoc.externalVideoId &&
          videoSnippetSolrDoc.platform === 'vimeo'
        "
        :id="videoSnippetSolrDoc.externalVideoId"
        :start="videoSnippetSolrDoc.videoStart"
        autoplay
      />
    </div>
  </v-card>
</template>

<script>
import YouTubeLink from "@/components/video/YouTubeLink.vue";
import VimeoLink from "@/components/video/VimeoLink.vue";
import VideoSummary from "@/components/video/VideoSummary.vue";
import ShareButton from "@/components/inputs/ShareButton.vue";
import statelessHelpers from "@/mixins/statelessHelpers";

export default {
  name: "VideoSnippetCardSimple",
  props: ["videoSnippetSolrDoc"],
  components: {
    YouTubeLink,
    VimeoLink,
    VideoSummary,
    ShareButton,
  },
  data() {
    return {
      deleteDialog: false,
      isDeleted: false,
      video: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    userIsAuthenticated() {
      return this.user !== null && this.user !== undefined;
    },
    userIsAdmin() {
      return (
        this.userIsAuthenticated &&
        this.user.roles &&
        this.user.roles.includes("admin")
      );
    },
    userIsFamily() {
      return (
        this.userIsAuthenticated &&
        this.user.roles &&
        this.user.roles.includes("family")
      );
    },
    hasFavorited() {
      if (this.userIsFamily) {
        return (
          this.user.videoSnippetFavoriteIds?.indexOf(
            this.videoSnippetSolrDoc.id
          ) >= 0 || false
        );
      } else return false;
    },
    editAllowed() {
      return (
        this.user &&
        (this.userIsAdmin || this.videoSnippetSolrDoc.userId === this.user.id)
      );
    },
    thumbnailUrl() {
      // console.debug("thumbnailUrl and imageSrc of ", this.videoSnippetSolrDoc?.id,  this.videoSnippetSolrDoc?.thumbnailUrl, this.videoSnippetSolrDoc?.imageSrc);
      if (this.videoSnippetSolrDoc?.imageSrc) {
        return this.videoSnippetSolrDoc.imageSrc;
      } else if (this.videoSnippetSolrDoc?.thumbnailUrl) {
        return this.videoSnippetSolrDoc.thumbnailUrl;
      } else {
        return "https://via.placeholder.com/728x250.jpeg?text=Video-Snippet"; // currently no thumbnails available for video snippets in the store
      }

      // } else if (this.videoSnippetSolrDoc?.thumbnailUrl) {
      //   // return this.videoSnippetSolrDoc.thumbnailUrl;
      //   return `https://i.ytimg.com/vi/${this.videoSnippetSolrDoc.externalVideoId}/hqdefault.jpg`;
      // } else {
      //   return "https://via.placeholder.com/728x250.jpeg?text=Video-Snippet";
      // }
    },
    isPublished() {
      return this.videoSnippetSolrDoc.published === "published";
    },
    wholeVideoDuration() {
      return this.convertedDuration();
    },
  },
  created() {
    // try {
    //   this.video = this.$store.getters.videos.find((s) => s.id === this.videoSnippetSolrDoc.videoId);
    //   if (this.video === null || this.video === undefined) {
    //     throw new Error ("Cannot find video for video snippet with id " + this.videoSnippetSolrDoc?.videoId);
    //   }
    // } catch (error) {
    //   throw error;
    // }
  },
  mixins: [statelessHelpers],
  methods: {
    deleteVideoSnippet() {
      this.deleteDialog = false;
      // TODO: [OP-206] Passing the solrDoc for video snippet deletion is problematic, because it is not the same as the videoSnippet object in the store (it has no storageUrl, for example, which is needed for deletion of the image)
      this.$store
        .dispatch("deleteVideoSnippet", this.videoSnippetSolrDoc)
        .then(() => {
          this.isDeleted = true;
          // this.$router.push({ name: "VideoSnippetSearch" });
        });
    },
    editVideoSnippet(id) {
      this.$router.push({ name: "VideoSnippetEdit", params: { id: id } });
    },
    toggleApproveVideoSnippet() {
      this.$store.dispatch(
        "toggleApproveVideoSnippet",
        this.videoSnippetSolrDoc
      ); // we pass the solrDoc (since it bundles id and publication state), but the store action will use the id to retrieve the actual videoSnippet from the store
      // .then(() => this.$router.push({ name: "VideoSnippetSearch" }));
      console.debug("toggleApproveVideoSnippet");
    },
    // suggestSelected(index) {
    //   this.searchTerm = this.suggest[index];
    // },
    openVideoSnippet(id) {
      if ((!this.isDeleted && this.userIsFamily) || this.userIsAdmin) {
        this.$router.push({ name: "VideoSnippetShow", params: { id } });
        // } else {
        //   playVideoAtProvider(id);
      }
    },
    // TODO: [OP-155] calculate length for whole videos (snippet = whole video) here as a special case or in extra video component
    videoLengthFormatted: function (videoSnippetSolrDoc) {
      if (!videoSnippetSolrDoc.videoId) return "";
      if (
        !videoSnippetSolrDoc.videoStart ||
        videoSnippetSolrDoc.videoStart === "0" ||
        !videoSnippetSolrDoc.videoEnd ||
        videoSnippetSolrDoc.videoEnd === "0"
      )
        return "";
      return this.$options.filters.timeInHours(
        videoSnippetSolrDoc.videoEnd - videoSnippetSolrDoc.videoStart
      );
    },
  },
};
</script>

<style scoped>
.noWrap {
  display: block;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cardTitle {
  /* word-wrap: break-word; */
  word-break: normal;
}
.cardText {
  overflow: hidden;
}
.multiline-ellipsis {
  line-height: 1.3em;
  height: 2.6em;
  max-height: 2.6em;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  padding: 0px;
}
.cursorDefault {
  cursor: default;
}
/* .card { */
/* height: 100%; */
/* display: flex; */
/* flex-direction: column; */
/* } */

.approved {
  color: green;
}
.deactivated {
  opacity: 0.5;
}
.deleted {
  opacity: 0.2;
  /* show in black and white */
  filter: grayscale(100%);
  pointer-events: none;
}
.thumbnail-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-image {
  object-fit: cover;
  width: 100%;
  height: 200px; /* Adjust as needed */
}
</style>
