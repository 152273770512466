<template>
      <!-- <h2>Original video</h2>
      <ul>
        <li>name: {{ video.name }}</li>
        <li>description: {{ video.description }}</li>
        <li>publisher: {{ video.publisher }}</li>
        <li>publisherId: {{ video.publisherId }}</li>
        <li>publishedAt: {{ video.publishedAt }}</li>
        <li>tags: {{ video.tags }}</li>
      </ul> -->
      <span v-if="video.id" class="source"> <!-- we can not check if the video objects exists, since sometimes an empty dummy object is provided -->
        {{ $t('reference.source') }}:
        '{{ video.name }}' {{ $t('reference.by') }} '{{ video.publisher }}' 
        <span v-if="!isWholeVideo">
          ({{ $t('reference.snippet') }}, 
          {{ $options.filters.timeInHours(videoSnippet.videoStart) }}-{{ $options.filters.timeInHours(videoSnippet.videoEnd) }}).
          <!-- {{ $t('reference.from') }} -->
        </span>
        <span v-else>.</span>  
        {{ $t('reference.published-on') }} {{ video.platform }} {{ relativePublishedAt }}.
      </span>
      <!-- // TODO: [OP-176] format date and publisher of video (snippet) nicely -->
</template>

<script>
export default {
  name: "VideoSummary",
  props: ["video", "videoSnippet"],
  computed: {
    isWholeVideo() {
      if (this.videoSnippet) {
        return !(this.videoSnippet.videoStart || this.videoSnippet.videoEnd);
      } else {
        return true;
      }
    },
    relativePublishedAt() {

      if (!this.video?.publishedAt) return "";

      // https://stackoverflow.com/questions/6108819/javascript-timestamp-to-relative-time
      
      // in miliseconds
      var units = {
        year  : 24 * 60 * 60 * 1000 * 365,
        month : 24 * 60 * 60 * 1000 * 365/12,
        day   : 24 * 60 * 60 * 1000,
        hour  : 60 * 60 * 1000,
        minute: 60 * 1000,
        second: 1000
      }

      var rtf = new Intl.RelativeTimeFormat(this.$i18n.locale, { numeric: 'auto' })

      var getRelativeTime = (d1, d2 = new Date()) => {
        var elapsed = d1 - d2

        // "Math.abs" accounts for both "past" & "future" scenarios
        for (var u in units) 
          if (Math.abs(elapsed) > units[u] || u == 'second') 
            return rtf.format(Math.round(elapsed/units[u]), u)
      }
      return getRelativeTime(new Date(this.video.publishedAt));
    }
  }
};
</script>

<style scoped>
</style>
