<template>
<div v-if="video && videoSnippet" class="video-preview cardsSameHeight">
  <v-card @click="openVideoSnippet(videoSnippet.id)" height="100%" 
    :class="[(userIsFamily || userIsAdmin) ? '' : 'cursorDefault', 'my-2']" 
    hover>
    <span v-if="hasFavorited && userIsFamily" class="overlayBtnOnCards d-flex justify-center ">
      <v-icon :class="{favorited: hasFavorited}">mdi-bookmark</v-icon>
    </span>
    <v-img height="250" v-if="thumbnailUrl" :src="thumbnailUrl"></v-img>
    <v-card-title class="cardTitle mx-2 my-2 pa-0">
      {{ (userIsFamily && videoSnippet.name)? videoSnippet.name : video.name }}
    </v-card-title>
    <v-card-text v-if="videoLengthFormatted(videoSnippet)" class="mx-2 my-2 pa-0">
      <!-- <v-chip small>
        <v-icon small>mdi-thumb-up</v-icon>
        <span class="pl-2 mr-2">{{ videoSnippet.votesUp.length }}</span>
      </v-chip> -->
      <v-chip small>
        <v-icon small>mdi-video</v-icon>
        <span class="pl-2">{{ videoLengthFormatted(videoSnippet) }} {{ !(videoSnippet.videoStart && videoSnippet.videoEnd)? '' : '(' + $t('video-snippet.card.snippet') + ')' }}</span>
      </v-chip>
    </v-card-text>
    <v-card-text class="cardText multiline-ellipsis mx-2 mt-2">
      {{ (userIsFamily && videoSnippet.description)? videoSnippet.description : "display search context" }}
    </v-card-text>
    <div class="mx-2 my-2"><video-summary :video="video" :videoSnippet="videoSnippet"></video-summary></div>
    <div v-if="!(userIsFamily || userIsAdmin)">
        <YouTubeLink 
            :id="video.externalId"
            :start="videoSnippet.videoStart"
            :end="videoSnippet.videoEnd"
            autoplay
        />
    </div> 

    <v-card-actions v-if="editAllowed" class="mt-8" style="padding: 16px">
      <v-btn text @click="editVideoSnippet(videoSnippet.id)">
        <v-icon class="mr-1" small>mdi-pencil</v-icon>
        {{ $t('crud.edit')}}
      </v-btn>
      <v-dialog v-model="deleteDialog" persistent max-width="290">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            <v-icon class="mr-1" small>mdi-delete</v-icon>
            {{ $t('crud.delete')}}
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">{{ $t('crud.delete') }}?</v-card-title>
          <v-card-text>{{ $t('dialog.really-delete-undoable') }}</v-card-text>
          <v-spacer></v-spacer>
          <v-btn color="cancel" text @click="deleteDialog = false" class="ma-4">{{ $t('nav.cancel') }}</v-btn>
          <v-btn color="delete" text @click="deleteVideoSnippet()" class="ma-4">{{ $t('crud.delete') }}</v-btn>
        </v-card>
      </v-dialog>
      <share-button :url="'/video-snippet/show/' + videoSnippet.id"/>
    </v-card-actions>

  </v-card>
</div>
</template>

<script>
import YouTubeLink from '@/components/video/YouTubeLink.vue';
import VideoSummary from '@/components/video/VideoSummary.vue';
import ShareButton from '@/components/inputs/ShareButton.vue';

export default {
  name: "VideoSnippetCard",
  props: ["videoSnippet"],
  components: {
    YouTubeLink,
    VideoSummary,
    ShareButton
  },
  data() {
    return {
    deleteDialog: false,
    video: null,
    }
  },
  computed: {
    user() { return this.$store.getters.user },
    userIsAuthenticated() {
      return this.user !== null && this.user !== undefined
    },
    userIsAdmin() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("admin");
    },
    userIsFamily() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("family");
    },
    hasFavorited() { 
      if (this.userIsFamily) {
        return this.user.videoSnippetFavoriteIds?.indexOf(this.videoSnippet.id) >= 0 || false 
      } else
        return false;
    },
    editAllowed() { return this.user && (this.userIsAdmin || (this.videoSnippet.userId === this.user.id)) },
    thumbnailUrl(){
      if (this.videoSnippet?.imageSrc) {
        return this.videoSnippet.imageSrc;
      } else if (this.video?.externalId !=='') {
        return `https://i.ytimg.com/vi/${this.video.externalId}/hqdefault.jpg`;
      } else {
        return "https://via.placeholder.com/728x250.jpeg?text=Video-Snippet";
      }
    }
  },
  created() {
    try {
      this.video = this.$store.getters.videos.find((s) => s.id === this.videoSnippet.videoId);
      if (this.video === null || this.video === undefined) {
        throw new Error ("Cannot find video for video snippet with id " + this.videoSnippet?.videoId);
      }
    } catch (error) {
      throw error;
    }
  },
  methods: {
    deleteVideoSnippet() {
      this.deleteDialog = false;
      this.$store.dispatch("deleteVideoSnippet", this.videoSnippet)
      .then(() => this.$router.push({ name: "VideoSnippetSearch" }));
    },
    editVideoSnippet(id) {
      this.$router.push( { name: 'VideoSnippetEdit', params: { id: id }});
    },
    suggestSelected(index) {
      this.searchTerm = this.suggest[index];
    },
    openVideoSnippet(id) {
      if (this.userIsFamily || this.userIsAdmin) {
        this.$router.push({ name: "VideoSnippetShow", params: { id }});
      // } else {
      //   playVideoAtProvider(id);
      }
    },
    // TODO: [OP-155] calculate length for whole videos (snippet = whole video) here as a special case or in extra video component
    videoLengthFormatted: function(videoSnippet) {
      if (!videoSnippet.videoId) return "";
      if ((!videoSnippet.videoStart || videoSnippet.videoStart === "0") || (!videoSnippet.videoEnd || videoSnippet.videoEnd === "0")) return "";
      return this.$options.filters.timeInHours(videoSnippet.videoEnd - videoSnippet.videoStart);
    }
  }
};
</script>

<style scoped>
.noWrap {
  display: block;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cardTitle {
  /* word-wrap: break-word; */
  word-break:normal
}
.cardText {
  font-size: 100%;
  overflow: hidden;
}
.multiline-ellipsis {
  line-height: 1.3em;
  height: 2.6em;
  max-height: 2.6em;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  padding: 0px;
}
.cursorDefault {
  cursor: default;
}
.cardsSameHeight {
  height: 100%;
}
</style>
