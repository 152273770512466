<template>
  <div>
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3>
        <div v-if="loading" class="loading text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <v-alert v-if="error" type="error" dismissible>{{ error.message }}</v-alert>
        <form @submit.prevent="saveOrUpdate" ref="form" lazy-validation>
          <v-card flat class="mt-16">
            <v-card-title class="pa-6 text-h3">
              Sammlung bearbeiten
            </v-card-title>
            <v-card-text class="px-6 py-0 my-0 text-body-1">
              <v-text-field
                  v-model="editedName"
                  :rules="editedNameRules"
                  label="Name"
                  required outlined
                  placeholder="Name der Sammlung"
              ></v-text-field>
              <v-textarea
                  v-model="editedDescription"
                  rows="8"
                  label="Beschreibung"
                  placeholder="Beschreibung der Sammlung"
                  auto-grow outlined hide-details
              ></v-textarea>
              <v-checkbox
                  v-model="editedPublished"
                  label="Sammlung veröffentlichen"
              ></v-checkbox>
            </v-card-text>
            <v-card-actions class="px-6 my-0">
              <v-btn type="submit" color="primary" class="px-8" :disabled="loading" :loading="loading">Speichern</v-btn>
              <v-btn text @click="cancel">{{ $t('nav.cancel') }}</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "SequenceEdit",
  props: ['id'],
  data() {
    return {
      sequence: null,
      editedName: '',
      editedDescription: '',
      editedPublished: false,
      editedNameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 120) || 'Name must be less than 120 characters',
      ],
    };
  },
  created() {
    this.sequence = this.$store.getters.sequences.find((s) => s.id === this.id);
    this.loadForEditing();
  },
  computed: {
    loading: function() {
      return this.$store.getters.loading;
    },
    error: function() {
      return this.$store.getters.error;
    },
    tools: function() {
      return this.$store.getters.tools;
    }
  },
  methods: {
    loadForEditing() {
      if (!this.loading && this.sequence) {
        this.editedName = this.sequence.name;
        this.editedDescription = this.sequence.description;
        this.editedPublished = this.sequence.published;
      }
    },
    cancel() {
      this.$router.push({ name: 'SequenceShow', params: { id: this.id }});
    },
    saveOrUpdate() {
      this.sequence.published = this.editedPublished;
      this.sequence.name = this.editedName;
      this.sequence.description = this.editedDescription;
      this.$store.dispatch("updateSequence", this.sequence).then(() => this.cancel());
    }
  }
};
</script>
