<template>
  <div>
<!--    <div v-if="loading" class="loading text-center">-->
<!--      <v-progress-circular indeterminate color="primary"></v-progress-circular>-->
<!--    </div>-->
    <div v-if="error" class="error">{{ error }}</div>
    <div v-if="tool">
      <v-card class="mt-4" flat>
        <div v-if="tool.videoId && tool.videoStart !== undefined">
          <you-tube-player 
            :id="tool.videoId"
            :start="tool.videoStart"
            :end="tool.videoEnd"
            autoplay=1
          ></you-tube-player>
        </div>
        <v-card-title class="mt-4 d-flex justify-space-between">
          <span>{{tool.name}}</span>
          <span class="d-flex" style="min-width: 100px">
            <v-menu left offset-y>
              <template v-slot:activator="{ on, attrs }">
                <span class="overlayBtn d-flex justify-center mr-3" v-on="on">
                  <v-icon color="onLight">mdi-plus</v-icon>
                </span>
              </template>
              <v-list dense>
                <v-subheader>{{ $t('tool.show.list-add') }}</v-subheader>
                <v-list-item v-for="sequence in filteredSequences" :key="sequence.id" @click="addOrRemoveToSequence(sequence)">
                  <v-list-item-icon class="mr-3">
                    <v-icon v-if="isInSequence(sequence)">mdi-check-circle-outline</v-icon>
                    <v-icon v-else>mdi-circle-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ sequence.name }}</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="sequenceDialog = true">
                  <v-list-item-title>{{ $t('tool.show.list-create') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span class="overlayBtn d-flex justify-center" v-on="on" @click="switchFavorite">
                  <v-icon :class="{favorited: hasFavorited, notFavorited: !hasFavorited}">mdi-bookmark</v-icon>
                </span>
              </template>
              <span>{{ $t('tool.show.favorite-mark') }}</span>
            </v-tooltip>
          </span>
        </v-card-title>
        <v-card-subtitle class="cardText">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                  class="button-vote" small left v-on="on" @click="voteUp" :class="{disabled: hasVotedUp}">mdi-thumb-up
              </v-icon>
            </template>
            <span>{{ $t('tool.show.vote-up') }}</span>
          </v-tooltip>
          <span class="label label-primary">{{ countVotesUp }}</span>
        </v-card-subtitle>
        <v-card-text class="cardText" style="white-space: pre-wrap">{{tool.description}}</v-card-text>
        <v-card-text class="cardText mt-0 pt-0">
          <v-chip v-for="(tag, i) in tool.tags" :key="i" class="mr-2">
            {{ tag }}
          </v-chip>
        </v-card-text>

        <v-card-actions v-if="editAllowed" class="mt-8" style="padding: 16px">
          <v-btn text @click="editTool">
            <v-icon class="mr-1" small>mdi-pencil</v-icon>
            {{ $t('crud.edit')}}
          </v-btn>
          <v-dialog v-model="deleteDialog" persistent max-width="290">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <v-icon class="mr-1" small>mdi-delete</v-icon>
                {{ $t('crud.delete')}}
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">{{ $t('crud.delete') }}?</v-card-title>
              <v-card-text>{{ $t('dialog.really-delete-undoable') }}</v-card-text>
              <v-spacer></v-spacer>
              <v-btn color="cancel" text @click="deleteDialog = false" class="ma-4">{{ $t('nav.cancel') }}</v-btn>
              <v-btn color="delete" text @click="deleteTool" class="ma-4">{{ $t('crud.delete') }}</v-btn>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </div>
    <div v-else>{{ $t('tool.show.no-entity-selected-message') }}</div>
    <v-dialog v-model="sequenceDialog" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('tool.show.list-create-dialog-caption') }}</span>
        </v-card-title>
        <v-card-text class="mt-8">
          <v-text-field :label="$t('tool.show.list-create-dialog-name-label')" v-model="sequenceName" @keyup.enter="createSequence" dense outlined required autofocus></v-text-field>
        </v-card-text>
        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-btn text @click="sequenceDialog = false">
            {{ $t('nav.cancel') }}
          </v-btn>
          <v-btn color="primary" @click="createSequence">
            {{ $t('tool.show.list-create-dialog-button') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbarSequenceCreated" timeout="2000">
      {{ $t('tool.show.list-successfully-created-message') }}
    </v-snackbar>
    <v-snackbar v-model="snackbarSequenceAdded" timeout="2000">
      {{ $t('tool.show.entity-successfully-added-message') }}
    </v-snackbar>
  </div>
</template>

<script>
import YouTubePlayer from '@/components/video/YouTubePlayer.vue';

export default {
  components: {
    YouTubePlayer
  },
  props: ['id'],
  data() {
    return {
      tool: null,
      deleteDialog: false,
      sequenceDialog: false,
      sequenceName: '',
      snackbarSequenceCreated: false,
      snackbarSequenceAdded: false
    };
  },
  computed: {
    loading() { return this.$store.getters.loading },
    error() { return this.$store.getters.error },
    user() { return this.$store.getters.user },
    sequences() { return this.$store.getters.sequences;},
    filteredSequences() { return this.sequences.filter(sequence => sequence.userId === this.user.id) },
    countVotesUp() { return this.tool.votesUp?.length || 0 },
    hasVotedUp() { return this.tool.votesUp.find((s) => s === this.user.id) !== undefined },
    hasFavorited() { return this.user.toolFavoriteIds?.indexOf(this.id) >= 0 || false },
    editAllowed() { return this.tool.userId === this.user.id }
  },
  created() {
    this.tool = this.$store.getters.tools.find((s) => s.id === this.id);
  },
  methods: {
    deleteTool() {
      this.deleteDialog = false;
      this.$store.dispatch("deleteTool", this.tool).then(() => this.$router.push({ name: "Dashboard" }));
    },
    editTool() {
      this.$router.push( { name: 'ToolEdit', params: { id: this.id }});
    },
    createSequence() {
      this.$store.dispatch("createSequence", {
        userId: this.user.id,
        published: false,
        name: this.sequenceName,
        description: '',
        toolIds: [this.id]
      }).then(() => {
        this.sequenceDialog = false;
        this.sequenceName = '';
        this.snackbarSequenceCreated = true;
      });
    },
    addOrRemoveToSequence(sequence) {
      if (this.isInSequence(sequence)) {
        sequence.toolIds.splice(sequence.toolIds.indexOf(this.id), 1);
      } else {
        sequence.toolIds.push(this.id);
      }
      this.$store.dispatch("updateSequence", sequence).then(() => {
        this.snackbarSequenceAdded = true;
      });
    },
    voteUp: function() {
      if (this.hasVotedUp) {
        this.tool.votesUp = this.tool.votesUp.filter((s) => s !== this.user.id);
      } else {
        this.tool.votesUp.push(this.user.id);
      }
      this.$store.dispatch("updateTool", this.tool);
    },
    switchFavorite: function() {
      if (!this.user.toolFavoriteIds) {
        this.user.toolFavoriteIds = [ this.id ];
      } else if (this.hasFavorited) {
        this.user.toolFavoriteIds = this.user.toolFavoriteIds.filter((id) => id !== this.id);
      } else {
        this.user.toolFavoriteIds.push(this.id);
      }
      this.$store.dispatch("updateUser", this.user);
    },
    isInSequence: function(sequence) {
      return sequence.toolIds.indexOf(this.id) >= 0;
    }
  }
};
</script>

<style scoped>
  .button-vote {
    opacity: 1;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
  }
  .button-vote:hover {
    opacity: 0.75;
    cursor: pointer;
  }
  .cardText {
    font-size: 100%;
  }
</style>
