<template>
  <div>
    <div v-if="video && videoSnippet">
      <v-card class="mt-4" flat>
        <div v-if="video.platform === 'youtube' && video.externalId">
          <you-tube-player 
            :id="video.externalId"
            :start="videoSnippet.videoStart"
            :end="videoSnippet.videoEnd"
            autoplay=1
          ></you-tube-player>
        </div>
        <div v-else-if="video.platform === 'vimeo' && video.externalId">
          <vimeo-player 
            :id="video.externalId"
            :start="videoSnippet.videoStart"
            :end="videoSnippet.videoEnd"
            autoplay=1
          ></vimeo-player>
        </div>
        <div v-else-if="!video.externalId">
          <v-alert type="warning">Video cannot be displayed because the external id is missing.</v-alert>
        </div>
        <div v-if="video && videoSnippet" class="mt-2 mx-3">
          <v-chip v-if="videoLengthFormatted" small>
            <v-icon small>mdi-video</v-icon>
            <span class="pl-2">{{ videoLengthFormatted }}</span>
          </v-chip>
          <video-summary :video="video" :videoSnippet="videoSnippet"></video-summary>
        </div>
        <v-card-title class="mt-4 d-flex justify-space-between">
          <span>{{ (userIsFamily && videoSnippet.name)? videoSnippet.name : 'Snippet from \'' + video.name + '\'' }}</span>
          <span class="d-flex" style="min-width: 100px">
            <!-- <v-menu left offset-y>
              <template v-slot:activator="{ on, attrs }">
                <span class="overlayBtn d-flex justify-center mr-3" v-on="on">
                  <v-icon color="onLight">mdi-plus</v-icon>
                </span>
              </template>
              <v-list dense>
                <v-subheader>{{ $t('video-snippet.show.list-add') }}</v-subheader>
                <v-list-item v-for="sequence in filteredSequences" :key="sequence.id" @click="addOrRemoveToSequence(sequence)">
                  <v-list-item-icon class="mr-3">
                    <v-icon v-if="isInSequence(sequence)">mdi-check-circle-outline</v-icon>
                    <v-icon v-else>mdi-circle-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ sequence.name }}</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="sequenceDialog = true">
                  <v-list-item-title>{{ $t('video-snippet.show.list-create') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span class="overlayBtn d-flex justify-center" v-on="on" @click="switchFavorite">
                  <v-icon :class="{favorited: hasFavorited, notFavorited: !hasFavorited}">mdi-bookmark</v-icon>
                </span>
              </template>
              <span>{{ $t('video-snippet.show.favorite-mark') }}</span>
            </v-tooltip>
          </span>
        </v-card-title>
        <v-card-subtitle class="cardText">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                  class="button-vote" small left v-on="on" @click="voteUp" :class="{disabled: hasVotedUp}">mdi-thumb-up
              </v-icon>
            </template>
            <span>{{ $t('video-snippet.show.vote-up') }}</span>
          </v-tooltip>
          <span class="label label-primary">{{ countVotesUp }}</span>
        </v-card-subtitle>
        <v-card-text class="cardText shortened" style="white-space: pre-wrap">
          {{ videoSnippet.description || ' Video: ' + video.description }}
        </v-card-text>
        <v-card-text class="cardText mt-2 pt-0">
          <v-chip v-for="(tag, i) in videoSnippet.tags" :key="i" class="mr-2 mt-2" @click="followTag(tag)">
            {{ tag }}
          </v-chip>
        </v-card-text>

        <v-card-actions v-if="editAllowed || canShare()" class="mt-2" style="padding: 16px">
          <v-btn v-if="editAllowed" text @click="editVideoSnippet">
            <v-icon class="mr-1" small>mdi-pencil</v-icon>
            {{ $t('crud.edit')}}
          </v-btn>
          <v-dialog v-if="editAllowed" v-model="deleteDialog" persistent max-width="290">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <v-icon class="mr-1" small>mdi-delete</v-icon>
                {{ $t('crud.delete')}}
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">{{ $t('crud.delete') }}?</v-card-title>
              <v-card-text>{{ $t('dialog.really-delete-undoable') }}</v-card-text>
              <v-spacer></v-spacer>
              <v-btn color="cancel" text @click="deleteDialog = false" class="ma-4">{{ $t('nav.cancel') }}</v-btn>
              <v-btn color="delete" text @click="deleteVideoSnippet" class="ma-4">{{ $t('crud.delete') }}</v-btn>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <!-- <share-button :url="'/video-snippet/show/' + id"/> -->
          <share-button :url="'/?q=id:' + id"/>
        </v-card-actions>
      </v-card>
    </div>
    <div v-else>{{ $t('video-snippet.show.entity-could-not-be-loaded-message') }}</div>
    <v-dialog v-model="sequenceDialog" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('video-snippet.show.list-create-dialog-caption') }}</span>
        </v-card-title>
        <v-card-text class="mt-8">
          <v-text-field :label="$t('video-snippet.show.list-create-dialog-name-label')" v-model="sequenceName" @keyup.enter="createSequence" dense outlined required autofocus></v-text-field>
        </v-card-text>
        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-btn text @click="sequenceDialog = false">
            {{ $t('nav.cancel') }}
          </v-btn>
          <v-btn color="primary" @click="createSequence">
            {{ $t('video-snippet.show.list-create-dialog-button') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbarSequenceCreated" timeout="2000">
      {{ $t('video-snippet.show.list-successfully-created-message') }}
    </v-snackbar>
    <v-snackbar v-model="snackbarSequenceAdded" timeout="2000">
      {{ $t('video-snippet.show.entity-successfully-added-message') }}
    </v-snackbar>
  </div>
</template>

<script>
import YouTubePlayer from '@/components/video/YouTubePlayer.vue';
import VimeoPlayer from '@/components/video/VimeoPlayer.vue';
import VideoSummary from '@/components/video/VideoSummary.vue';
import ShareButton from '@/components/inputs/ShareButton.vue';
import statelessHelpers from '@/mixins/statelessHelpers';

export default {
  components: {
    YouTubePlayer,
    VimeoPlayer,
    VideoSummary,
    ShareButton
  },
  props: ['id'],
  data() {
    return {
      videoSnippet: null,
      video: null,
      deleteDialog: false,
      sequenceDialog: false,
      sequenceName: '',
      snackbarSequenceCreated: false,
      snackbarSequenceAdded: false
    };
  },
  computed: {
    loading() { return this.$store.getters.loading },
    error() { return this.$store.getters.error },
    user() { return this.$store.getters.user },
    sequences() { return this.$store.getters.sequences },
    videoSnippets() { return this.$store.getters.videoSnippets },
    videos() { return this.$store.getters.videos },
    filteredSequences() { return this.sequences.filter(sequence => sequence.userId === this.user.id) },
    countVotesUp() { return this.videoSnippet.votesUp?.length || 0 },
    hasVotedUp() { return this.videoSnippet.votesUp.find((s) => s === this.user.id) !== undefined },
    hasFavorited() { return this.user.videoSnippetFavoriteIds?.indexOf(this.id) >= 0 || false },
    editAllowed() { return this.user && (this.userIsAdmin || (this.videoSnippet.userId === this.user.id)) },
    userIsAuthenticated() {
      return this.user !== null && this.user !== undefined
    },
    userIsAdmin() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("admin");
    },
    userIsFamily() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("family");
    },
    videoLengthFormatted() {
      if (!this.videoSnippet.videoEnd || this.videoSnippet.videoEnd === "0") {
        return "";
      } else {
        return this.$options.filters.timeInHours(this.videoSnippet.videoEnd - (this.videoSnippet.videoStart || 0));
      }
    }
  },
  async created() {
    // old, kept for reference. this was used when all video snippets and videos were loaded at once, maybe interesting for other smaller collections such as tools or sequences
    // // TODO: [OP-205] don't load all videos and videoSnippets just to show one videoSnippet
    // //
    // // console.debug("!this.videos || !this.videoSnippets || this.videos.length === 0 || this.videoSnippets.length === 0",
    // //    !this.videos || !this.videoSnippets || this.videos.length === 0 || this.videoSnippets.length === 0)
    // if (!this.videos || !this.videoSnippets || this.videos.length === 0 || this.videoSnippets.length === 0) {
    //   console.debug("videos or videosnippets not available yet, loading...")
    //   // await this.$store.dispatch('loadVideos');
    //   // await this.$store.dispatch('loadVideoSnippets');
    // }
    
    // this.whenLoaded();
    this.loadVideoSnippetAndVideo();
  },
  mixins: [statelessHelpers],
  methods: {
    async loadVideoSnippetAndVideo() {
      if (!this.id) {
        console.error("no id given");
        this.$store.dispatch('errorCall', $t('video-snippet.show.no-entity-selected-message'));
        return;
      }
      if (!this.videoSnippet) {
        console.debug("trying to get video snippet from store ...")
        this.videoSnippet = this.$store.getters.videoSnippetById(this.id);
        if (!this.videoSnippet) {
          console.debug("video snippet not available yet in store, loading from db ...")
          try {
            await this.$store.dispatch('loadVideoSnippet', this.id);
          } catch (error) {
            console.error("error loading video snippet", error);
            // this.$router.push({ name: "VideoSnippetSearch" }); // problem: when routing the error is cleared
            this.$store.dispatch('errorCall', error); // even if we set the error again here, it is cleared when routing occurs on the search page (e.g. du to an empty search string)
            return;
          }
          this.videoSnippet = this.$store.getters.videoSnippetById(this.id);
        }
      }
      if (this.videoSnippet) {
        this.video = this.$store.getters.videoById(this.videoSnippet.videoId);
        if (!this.video) {
          console.debug("video not available yet in store, loading from db ...")

          try {
            await this.$store.dispatch('loadVideo', this.videoSnippet.videoId);
          } catch (error) {
            console.error("error loading video", error);
            // this.$router.push({ name: "VideoSnippetSearch" }); // see above
            this.$store.dispatch('errorCall', error);
            return;
          }
          this.video = this.$store.getters.videoById(this.videoSnippet.videoId);
        }
      }
    },
    // old, kept for reference. this was used when all video snippets and videos were loaded at once, maybe interesting for other smaller collections such as tools or sequences
    // whenLoaded(){
    //   // https://stackoverflow.com/questions/69673386/vue-trigger-mounted-before-async-created-completed
    //   // still a problem: snippets and videos are loaded twice - once before authStateChanged (here) and once after it
    //   // TODO: [OP-180] load videos(snippets) only once when opening a videosnippet url
    //   if (this.id)
    //     this.videoSnippet = this.$store.getters.videoSnippets.find((s) => s.id === this.id);
    //   if (this.videoSnippet) {
    //     try {
    //       this.video = this.$store.getters.videos.find((s) => s.id === this.videoSnippet.videoId);
    //       if (!this.video) {
    //         throw new Error ("Cannot find video for video snippet with id " + this.videoSnippet?.videoId);
    //       }
    //     } catch (error) {
    //       throw error;
    //     }
    //   }
    // },
    deleteVideoSnippet() {
      this.deleteDialog = false;
      this.$store.dispatch("deleteVideoSnippet", this.videoSnippet).then(() => this.$router.push({ name: "VideoSnippetSearch" }));
    },
    editVideoSnippet() {
      this.$router.push( { name: 'VideoSnippetEdit', params: { id: this.id }});
    },
    createSequence() {
      this.$store.dispatch("createSequence", {
        userId: this.user.id,
        published: false,
        name: this.sequenceName,
        description: '',
        videoSnippetIds: [this.id]
      }).then(() => {
        this.sequenceDialog = false;
        this.sequenceName = '';
        this.snackbarSequenceCreated = true;
      });
    },
    // addOrRemoveToSequence(sequence) {
    //   if (this.isInSequence(sequence)) {
    //     sequence.videoSnippetIds.splice(sequence.videoSnippetIds.indexOf(this.id), 1);
    //   } else {
    //     sequence.videoSnippetIds.push(this.id);
    //   }
    //   this.$store.dispatch("updateSequence", sequence).then(() => {
    //     this.snackbarSequenceAdded = true;
    //   });
    // },
    voteUp: function() {
      if (this.hasVotedUp) {
        this.videoSnippet.votesUp = this.videoSnippet.votesUp.filter((s) => s !== this.user.id);
      } else {
        this.videoSnippet.votesUp.push(this.user.id);
      }
      this.$store.dispatch("updateVideoSnippet", this.videoSnippet);
    },
    switchFavorite: function() {
      if (!this.user.videoSnippetFavoriteIds) {
        this.user.videoSnippetFavoriteIds = [ this.id ];
      } else if (this.hasFavorited) {
        this.user.videoSnippetFavoriteIds = this.user.videoSnippetFavoriteIds.filter((id) => id !== this.id);
      } else {
        this.user.videoSnippetFavoriteIds.push(this.id);
      }
      this.$store.dispatch("updateUser", this.user);
    },
    isInSequence: function(sequence) {
      return sequence.videoSnippetIds.indexOf(this.id) >= 0;
    }
  }
};
</script>

<style scoped>
  .button-vote {
    opacity: 1;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
  }
  .button-vote:hover {
    opacity: 0.75;
    cursor: pointer;
  }
  .cardText {
    font-size: 100%;
  }
  .shortened {
    line-height: 1.3em;
    height: 26em;
    max-height: 26em;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 20;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    /* padding: 0px; */
  }
</style>
