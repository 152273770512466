// export default {

    function cleanTagsForStorage (tagValues) {

        if (!tagValues || tagValues.length == 0) {
            return [];
        }
        
        let tags = tagValues;

        // should already be done by the combobox on input, but when importing playlists, this is not done
        tags = tags.map(tag => tag.toLowerCase());
        // // remove #, :, ,, ;, .
        tags = tags.map(tag => tag.replace(/[#]/g, ""));
        tags = tags.map(tag => tag.replace(/[:,;.]/g, " "));

        // trim outer spaces that might have been created by removing the special characters
        tags = tags.map(tag => tag.trim());

        // remove inner redundant multiple spaces
        tags = tags.map(tag => tag.replace(/\s\s+/g, ' '));

        // remove duplicates using the set data structure
        tags = [...new Set(tags)];
        // tags = tags.filter((tag, index) => tags.indexOf(tag) === index);
        return tags;

    }
    
// }

module.exports = {
    cleanTagsForStorage: cleanTagsForStorage
}
