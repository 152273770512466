import Vue from 'vue'

import App from './App.vue'
import * as GlobalProjectProperties from './plugins/globalProjectProperties'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import i18n from './i18n'
import './registerServiceWorker'

Vue.config.productionTip = false
Vue.use(GlobalProjectProperties.GlobalProjectProperties.install)

// https://laracasts.com/discuss/channels/vue/vue-20-filter-to-show-elapsed-seconds-as-000000-format
Vue.filter('timeInHours', function(value) {
  let hours =  parseInt(Math.floor(value / 3600));
  let minutes = parseInt(Math.floor((value - (hours * 3600)) / 60));
  let seconds= parseInt((value - ((hours * 3600) + (minutes * 60))) % 60);
  let dHours = (hours > 9 ? hours : '0' + hours);
  let dMins = (minutes > 9 ? minutes : '0' + minutes);
  let dSecs = (seconds > 9 ? seconds : '0' + seconds);
  return (hours > 0 ? dHours + ":" : "") + dMins + ":" + dSecs;
});
Vue.filter('dateFormattedAbsolute', function(value) {
  return value;
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),

  created() {

    // always load these data, even if no user authenticated (public stuff)
    this.$store.dispatch('loadTags');

    const auth = getAuth();
    onAuthStateChanged(auth, async (authenticatedFirebaseUser) => {
      console.debug("auth state changed")

      if (authenticatedFirebaseUser) {
        if (authenticatedFirebaseUser.emailVerified) {
          console.debug("logging in authorized, e-mail-verified user " + authenticatedFirebaseUser.email )
          // TODO: both autoSignIn and fetchUserData set the user, triggering the watch function of Signin.vue
          // this could be improved to avoid an unnecessary loop
          //
          // we need to wait here for the login, otherwise fetch will not find a user to fetch the other user data
          const autosigninpromiseresult = await this.$store.dispatch('autoSignIn', authenticatedFirebaseUser);
          // atm no need to wait here
          // const fetchpromiseresult = await this.$store.dispatch('fetchUserData'); 
          await this.$store.dispatch('fetchUserData');

          // TODO: this does actually not need to be loaded (again) on auth state changes
          // also its currently loaded twice when the show videosnippets url is openend - once before authStateChanged and once after it (here)

          // loading all videos and videosnippets is not necessary anymore, since we load them on demand now
          // this.$store.dispatch('loadVideos');
          // await this.$store.dispatch('loadVideoSnippets');

          // this.$store.dispatch('loadSequences');
          // await this.$store.dispatch('loadTools');
        } else {
          console.debug("user " + authenticatedFirebaseUser.email + " was not logged in (e-mail not verified)" );
          this.$router.push('/signin');
        }
      } else {
        console.debug("currently no user is logged in since no user was authenticated")
        // clear things requiring auth, here (analog to loading above) or in logout?
        // this.$store.dispatch('clearEverythingRequiringAuth');

        // TODO: this does actually not need to be loaded (again) on auth state changes
        
        // load this data even if no user authenticated (public stuff)
        // loading all videos and videosnippets is not necessary anymore, since we load them on demand now
        // this.$store.dispatch('loadVideos');
        // this.$store.dispatch('loadVideoSnippets'); // must already load here, because required for loading tags
      }
    });
  }
}).$mount('#app')
