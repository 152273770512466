<template>
  <div>
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3>
        <!-- <v-alert v-if="error" type="error" dismissible>{{ error.message }}</v-alert> -->
        <v-alert v-if="mailWasSent" type="success" dismissible>Die E-Mail mit der Anleitung zum Zurücksetzen deines Passwortes ist unterwegs.</v-alert>
        <form @submit.prevent="resetPassword">
          <v-card flat class="mt-16">
            <v-card-title class="pa-6 text-h3 h-break">
              {{ $t('user.password-reset.caption') }}
            </v-card-title>
            <v-card-text class="px-6 py-0 my-0 text-body-1">
              <p class="mb-6">{{ $t('user.password-reset.message') }}</p>
              <v-text-field
                  name="email"
                  :label="$t('user.password-reset.email-label')"
                  id="email"
                  v-model="email"
                  type="email"
                  outlined required></v-text-field>
            </v-card-text>
            <v-card-actions class="px-6 my-0">
              <v-btn type="submit" color="primary" class="px-8" :disabled="loading || mailWasSent" :loading="loading">
                {{ $t('user.password-reset.password-reset-label') }}
              </v-btn>
              <v-btn text :disabled="loading" :loading="loading" class="ml-4" :to="{ name: 'Signin'}">
                {{ $t('user.password-reset.signon') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        email: '',
        mailWasSent: false
      }
    },
    computed: {
      // error () {
      //   return this.$store.getters.error
      // },
      loading () {
        return this.$store.getters.loading
      }
    },
    methods: {
      resetPassword () {
        this.$store.dispatch('resetPassword', {email: this.email}).then(() => this.mailWasSent = true)
      },
      dismissed () {
        this.$store.dispatch('clearError')
      }
    }
  }
</script>
