<template>
  <div>
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3>
        <div v-if="loading" class="loading text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <!-- <v-alert v-if="error" type="error" dismissible>{{ error.message }}</v-alert> -->
        <form @submit.prevent="saveOrUpdate" ref="form" lazy-validation>
          <v-card flat class="my-16">
            <v-card-title class="pa-6 text-h3">
              {{ $t('tool.edit.caption')}}
            </v-card-title>
            <v-card-text class="px-6 py-0 my-0 text-body-1">
              <v-text-field
                  v-model="editedName"
                  :counter="120"
                  :rules="editedNameRules"
                  :label="$t('tool.edit.name-label')"
                  required outlined
                  :placeholder="$t('tool.edit.name-placeholder')"
              >
              </v-text-field>
              <v-textarea
                  v-model="editedDescription"
                  :label="$t('tool.edit.description-label')"
                  :placeholder="$t('tool.edit.description-placeholder')"
                  auto-grow outlined
              >
              </v-textarea>
              <v-img
                  v-if="editedImageSrc && editedImageSrc !==''"
                  :src="editedImageSrc"
                  class="mb-4"
              ></v-img>
              <v-file-input
                  :label="$t('tool.edit.image-upload-placeholder')"
                  prepend-icon="mdi-camera"
                  filled outlined
                  accept="image/png, image/jpeg, image/bmp"
                  ref="fileInput1"
                  @change="fileInput1Changed($event)"
              ></v-file-input>
              <div v-if="editedImageSrc" class="mb-12">
                <v-btn @click="deleteImage()" color="secondary">{{ $t('tool.edit.image-delete-label')}}</v-btn>
              </div>
              <v-text-field
                  v-model="editedVideoId"
                  :label="$t('tool.edit.video-id-label')" outlined
                  :placeholder="$t('tool.edit.video-id-placeholder')"
                  disabled
              ></v-text-field>
              <v-text-field
                  v-model="editedVideoStart"
                  :label="$t('tool.edit.video-start-time-label')" outlined
                  :placeholder="$t('tool.edit.video-start-time-placeholder')"
              ></v-text-field>
              <v-text-field
                  v-model="editedVideoEnd"
                  :label="$t('tool.edit.video-end-time-label')" outlined
                  :placeholder="$t('tool.edit.video-end-time-placeholder')"
              ></v-text-field>

              <!-- Tagging -->
              <v-combobox multiple
                          v-model="editedSelectedTags"
                          :items="existingTags"
                          :label="$t('tool.edit.tags-label')"
                          append-icon
                          chips outlined
                          deletable-chips
                          :delimiters="[',',';']"
                          :rules="tagRules"
                          class="tag-input"
                          >
                          <!-- not in use -->
                          <!-- :search-input.sync="search"
                          @keyup.tab="updateTags"
                          @paste="updateTags" -->
              </v-combobox>
            </v-card-text>
            <v-card-actions class="px-6 my-0">
              <v-btn type="submit" color="primary" class="px-8" :disabled="loading || !allTagsValid" :loading="loading">{{ $t('crud.save') }}</v-btn>
              <v-btn text @click="cancel">{{ $t('nav.cancel') }}</v-btn>
              <v-spacer/>
              <v-bottom-sheet v-model="youtubeSheet">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on">
                    Mit Youtube Daten füllen ...
                  </v-btn>
                </template>
                <v-list>
                  <v-subheader>Mit Youtube Daten füllen:</v-subheader>
                  <v-list-item @click="fillYoutube(true, false, false)">
                    <v-list-item-title>Titel holen</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fillYoutube(false, true, false)">
                    <v-list-item-title>Beschreibung holen</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fillYoutube(false, false, true)">
                    <v-list-item-title>Thumbnail-Bild holen</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-bottom-sheet>
            </v-card-actions>
          </v-card>
        </form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import {loadYoutubeData} from "@/service/youtube";

export default {
  name: "ToolEdit",
  props: ['id'],
  data() {
    return {
      youtubeSheet: false,
      tool: null,
      editedName: '',
      editedDescription: '',
      editedImage: null, // the image as binary
      editedImageSrc: '', // will also contain the preview image as string-converted binary 
      editedVideoId: '',
      editedVideoStart: 0,
      editedVideoEnd: 0,
      editedSelectedTags: [],
      items: [],
      // search: "", // not in use atm
      editedNameRules: [
        v => !!v || this.$t('tool.edit.name-rule-required'),
        v => (v && v.length <= 120) || this.$t('tool.edit.name-rule-length-min'),
      ],
      tagRules: [
        (v) => {
          if (v.length > 0) {
            for (let i = 0; i < v.length; i++) {
              if (v[i].length > 25)
                return this.$t('tag.edit.tag-rule-length-max');
              }
            return true;
          } else
            return true;
        }
      ]
    };
  },
  computed: {
    loading: function() {
      return this.$store.getters.loading;
    },
    // error: function() {
    //   return this.$store.getters.error;
    // },
    user () {
      return this.$store.getters.user;
    },
    allTagsValid(){
      if (!this.editedSelectedTags)
        return true;
      for (let i = 0; i < this.editedSelectedTags.length; i++) {
        if (this.editedSelectedTags[i].length > 25)
          return false;
        }
      return true;
    },
    existingTags () {
      return this.$store.getters.existingTags; // TODO: [OP-163] make sure on creation and usage of tags that always the lowercase is used
    }
  },
  created() {
    this.tool = this.$store.getters.tools.find((s) => s.id === this.id);
    this.loadForEditing();
  },
  methods: {
    loadForEditing() {
      if (!this.loading && this.tool) {
        this.editedName = this.tool.name;
        this.editedDescription = this.tool.description;
        this.editedImageSrc = this.tool.imageSrc;
        this.editedVideoId = this.tool.videoId;
        this.editedVideoStart = this.tool.videoStart;
        this.editedVideoEnd = this.tool.videoEnd;
        this.editedSelectedTags = this.tool.tags;
      }
    },
    cancel() {
      this.$router.push({ name: 'ToolShow', params: { id: this.id }});

    },
    saveOrUpdate() {
      // update orginal object
      this.tool.name = this.editedName;
      this.tool.description = this.editedDescription;
      this.tool.imageSrc = this.editedImageSrc;
      this.tool.videoId = this.editedVideoId;
      this.tool.videoStart = this.editedVideoStart;
      this.tool.videoEnd = this.editedVideoEnd;
      this.tool.tags = this.editedSelectedTags ? this.editedSelectedTags : [];
      this.tool.lastEditedBy = this.user;
      this.$store.dispatch("updateToolWithImage", { payload: this.tool, imageFile: this.editedImage })
          .then(() => this.cancel());
    },
    deleteImage() {
      this.$store.dispatch("deleteToolImage", { payload: this.tool })
          .then(() => this.editedImageSrc = "");
    },
    updateTags() {
      // TODO: This does not work (anymore?) since search is null and the new tag is already in the editedTags array (including contained ",")
      // this.$nextTick(() => {
      //   this.editedSelectedTags.push(...this.search.split(","));
      //   this.$nextTick(() => {
      //     this.search = "";
      //   });
      // });
    },
    fileInput1Changed(event) {
      console.debug("fileInput1Changed ", event);
      
      let filename = event.name;
      if (filename.lastIndexOf('.') <= 0) {
        return alert ('Please add a file with file extension!');
      }

      const fileReader = new FileReader();
      fileReader.addEventListener('load', () => {
          this.editedImageSrc = fileReader.result; // save the image as string for preview
      })
      fileReader.readAsDataURL(event);
      this.editedImage = event; // save the image file 
    },
    async fillYoutube(title, description, thumbnail) {
      const youtubeData = await loadYoutubeData(this.editedVideoId);
      console.debug("youtubeData", youtubeData);
      if (title) this.editedName = youtubeData.title;
      if (description) this.editedDescription = youtubeData.description;
      if (thumbnail) this.editedImageSrc = youtubeData.thumbnailUrl;
      this.youtubeSheet = false;
    }
  }
};
</script>

<style scoped>
</style>
