import Vue from 'vue';
import { collection, doc, getDocs, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "@/plugins/firebase";
import data from "@/data";

export default {
	state: {
		sequences: []
	},
	getters: {
		sequences: state => state.sequences
	},
	actions: {
		// Actions returns Promises, see https://mclassdesigns.gitbook.io/js-journal/
		loadSequences({ commit }) {
			commit('startCall');
			return getDocs(collection(db, "sequences"))
				.then((querySequences) => {
					// console.debug("querySequences", querySequences);
					commit('clearSequences');
					querySequences.forEach((doc) => {
						const data = doc.data();
						// console.debug("data", data);
						commit('addSequence', {
							id: doc.id, // TODO: save to remove?
							userId: data.userId,
							published: data.published,
							name: data.name,
							description: data.description,
							toolIds: data.toolIds || []
						});
					});
					commit('successCall');
					return true;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		},
		createExampleSequences({ commit, dispatch }, userId) {
			commit('startCall');
			for (const [id, sequence] of Object.entries(data.sequences)) {
				dispatch('createSequence', {
					userId: userId,
					published: true, // TODO: use published value
					name: sequence.name,
					description: sequence.description,
					toolIds: [] //sequence.measures.map(tool => tool.id)
				});
			}
			commit('successCall');
		},
		createSequence({ commit }, sequence) {
			commit('startCall');
			return addDoc(collection(db, "sequences"), {
				userId: sequence.userId,
				published: sequence.published,
				name: sequence.name,
				description: sequence.description,
				toolIds: sequence.toolIds
			}).then((createdSequence) => {
					commit('addSequence', {
						id: createdSequence.id,
						userId: sequence.userId,
						published: sequence.published,
						name: sequence.name,
						description: sequence.description,
						toolIds: sequence.toolIds
					});
					commit('successCall');
					return createdSequence.id;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		},
		updateSequence({ commit }, payload) {
			commit('startCall');
			const docRef = doc(db, "sequences", payload.id);
			return updateDoc(docRef, {
				published: payload.published,
				name: payload.name,
				description: payload.description,
				toolIds: payload.toolIds
			})
				.then(() => {
					commit('setSequence', {
						id: payload.id,
						published: payload.published,
						name: payload.name,
						description: payload.description,
						toolIds: payload.toolIds
					});
					commit('successCall');
					return true;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		},
		deleteSequence({ commit }, payload) {
			commit('startCall');
			return deleteDoc(doc(db, "sequences", payload.id))
				.then(() => {
					commit('deleteSequence', payload);
					commit('successCall');
					return true;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		}
	},
	mutations: {
		clearSequences(state) {
			state.sequences = [];
		},
		addSequence(state, payload) {
			state.sequences.push(payload);
		},
		setSequence(state, payload) {
			const sequence = state.sequences.find(s => s.id === payload.id);
			if (sequence) {
				sequence.published = payload.published;
				sequence.name = payload.name;
				sequence.description = payload.description;
				sequence.toolIds = payload.toolIds;
			}
		},
		deleteSequence(state, payload) {
			Vue.delete(state.sequences, state.sequences.indexOf(payload));
		}
	}
};
