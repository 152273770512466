import Vue from 'vue';
import { collection, doc, getDoc, getDocs, addDoc, updateDoc, deleteDoc, getDocsFromServer, where, query } from "firebase/firestore";
import { db, storage } from "@/plugins/firebase";
import { cleanTagsForStorage } from '@/utils';

export default {
	state: {
		tags: []
	},
	getters: {
		tags: state => {
			return state.tags;
		},
        tagValues: state => {
            return state.tags.map(t => t.value);
        },
		tagById: state => id => {
			return state.tags.find(s => s.id === id);
		}
	},
	// Actions returns Promises, see https://mclassdesigns.gitbook.io/js-journal/
	actions: {

		// get tag by tag value
		async getTagByTagValue({ commit }, { tagValue, language }) {

			try {
				// here we use getDocs not getDocsFromServer (!) to get the cached data evtl. faster
				const querySnapshot = await getDocs(collection(db, "tags"), where("value", "==", tagValue), where("language", "==", language));
				if (querySnapshot.empty) {
					return null;
				}
				const docSnapshot = querySnapshot.docs[0];
				if (docSnapshot.exists()) {
					const data = docSnapshot.data();
					const tag = {
						id: docSnapshot.id,
						userId: data.userId,
						published: data.published,
						value: data.value,
						description: data.description,
						// votesUp: data.votesUp || [],
						// votesDown: data.votesDown || [],
						created: data.created,
						language: data.language,
						// thumbnailUrl: data.thumbnailUrl,
						// imageSrc: data.imageSrc,
						// storageUrl: data.storageUrl
					};
					commit('addTag', tag);
					return tag;
				}
				return null;
			}
			catch (error) {
				// commit('errorCall', error);
				return Promise.reject(error);
			}

		},

		// check if tag is already in the database (not used yet, we only check if tag exists in the store)
		async checkIfTagExists({ commit }, { tagValue, language }) {

			try {
				const q = query(collection(db, "tags"), where("value", "==", tagValue), where("platform", "==", language));
				// here we use getDocsFromServer to get the latest data
				const querySnapshot = await getDocsFromServer(q); 
				if (querySnapshot.empty) {
					return false;
				} else {
					return true;
				}
			} catch (error) {
				// commit('errorCall', error);
				return Promise.reject(error);
			}

		},

		// load a single tag by id
		async loadTag({ commit }, id) {
			commit('startCall');

			try {
				const docRef = doc(db, 'tags', id);
				const docSnapshot = await getDoc(docRef);
				if (docSnapshot.exists()) {
				  const data = docSnapshot.data();
				  const tag = {
					id: docSnapshot.id,
					userId: data.userId,
					published: data.published,
					value: data.value,
					description: data.description,
					// votesUp: data.votesUp || [],
					// votesDown: data.votesDown || [],
					created: data.created,
					language: data.language,
					// thumbnailUrl: data.thumbnailUrl,
					// imageSrc: data.imageSrc,
					// storageUrl: data.storageUrl
				  };
				  commit('addTag', tag);
				  commit('successCall');
				  return true;
				} else {
				  throw new Error(`Tag with ID ${id} not found in the database`);
				}
			  } catch (error) {
				commit('errorCall', error);
				return Promise.reject(error);
			  }
		},


		loadTags({ commit }) {
			commit('startCall');
			return getDocs(collection(db, "tags"))
				.then((queryTags) => {
					commit('clearTags');
					queryTags.forEach((doc) => {
						const data = doc.data();
						commit('addTag', {
							id: doc.id,
							userId: data.userId,
							published: data.published,
							value: data.value,
							description: 'tag description (or whole tag) should be loaded on demand ' + data.description	,
							// votesUp: data.votesUp || [],
							// votesDown: data.votesDown || [],
							created: data.created,
							language: data.language,
							// thumbnailUrl: data.thumbnailUrl,
							// imageSrc: data.imageSrc,
							// storageUrl: data.storageUrl
						});
					});
					commit('successCall');
					return true;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		},
		createTag({ commit }, tag) {
			commit('startCall');
			return addDoc(collection(db, "tags"), tag)
				.then((createdTag) => {
					commit('addTag', {
						id: createdTag.id,
						userId: tag.userId,
						value: tag.value,
						description: tag.description || "",
						created: tag.created,
						language: tag.language,
						// thumbnailUrl: tag.thumbnailUrl,
						// imageSrc: tag.imageSrc,
						// storageUrl: tag.storageUrl,
						// votesUp: [],
						// votesDown: []
					});
					commit('successCall');
					return createdTag.id;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		},
		// TODO: Currently deactivated until we handle tags more efficiently
		// create the tags excluding those that are already in the store
		// tag values are passed here as payload.tagValues
		// batch is not used, since this seems to require providing an id for each document
		async createTags({ commit, getters, dispatch }, payload) {
			console.debug("remember tags (collection) creation is currently deactivated");
			return true;
			// try {
			// 	commit('startCall');

			// 	console.debug("tag values before cleaning: ", payload.tagValues);

			// 	const cleanedTagValues = cleanTagsForStorage(payload.tagValues);

			// 	console.debug("cleaned tag values: ", cleanedTagValues);

			// 	// get the tags that are not already in the store
			// 	// TODO: [OP-223] check without loading all tags as soon as tags are loaded on demand. Atm we check in the store, which may be outdated (but this is acceptable for now)
			// 	const newTagValues = cleanedTagValues.filter(t => !getters.tagValues.includes(t)); 

			// 	// save all tags not already in the firebase tags collection
			// 	await Promise.all(newTagValues.map((tagValue) => {
			// 		return dispatch("createTag", { 
			// 			userId: getters.user?.id | null , // if no user logged in we create an anonymous tag
			// 			value: tagValue,
			// 			published: "published",
			// 			// description: "",
			// 			created: new Date().toISOString(), 
			// 			language: "unknown",
			// 		}).catch((error) => {
			// 			commit('errorCall', error);
			// 			return Promise.reject(error);
			// 		});
			// 	}));

			// 	commit('successCall');
			// 	return true;
			// } catch (error) {
			// 	commit('errorCall', error);
			// 	return false;
			// }
		},
		updateTag({ commit }, payload) {
			commit('startCall');
			return updateDoc(doc(db, "tags", payload.id), payload)
				.then(() => {
					commit('setTag', payload);
					commit('successCall');
					return true;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		},
		updateTagWithImage({ dispatch }, { payload, imageFile }) {
			if (imageFile) { // first upload the image, then update the tag
				const filename = imageFile.name;
				const ext = filename.slice(filename.lastIndexOf('.'));
				const storageUrl = 'tags/' + payload.id + ext;
				const imageStorageRef = storageRef(storage, storageUrl);
				return uploadBytes(imageStorageRef, imageFile)
					.then((snapshot) => {
						console.debug('Uploaded a blob or file!', snapshot);
						return getDownloadURL(imageStorageRef).then((downloadURL) => {
							console.debug('File available at', downloadURL);
							payload.imageSrc = downloadURL;
							payload.storageUrl = storageUrl;
							return dispatch("updateTag", payload);
						});
					});
			}
			return dispatch("updateTag", payload);
		},
		async deleteTagImage({ dispatch, commit }, payload) {
            if (payload.storageUrl) {
				try {
					const imageStorageRef = storageRef(storage, payload.storageUrl);
					// Delete the file
					await deleteObject(imageStorageRef);
					// File deleted successfully
					payload.imageSrc = "";
					payload.storageUrl = "";
					await dispatch("updateTag", payload);
					return true;
				} catch (error) {
					commit('errorCall', error);
					return false;
				}
			} else {
				const error = new Error("Image file could not be deleted (storageUrl was null or undefined)");
				commit('errorCall', error);
				return false;
			}
        },
        async deleteTag({ dispatch, commit }, payload) {
            commit('startCall');
			// if (payload.storageUrl && payload.storageUrl !== '') {
			// 	await dispatch("deleteTagImage", payload) 
			// }
			try {
				await deleteDoc(doc(db, "tags", payload.id));
				commit('deleteTag', payload);
				commit('successCall');
				return true;
			} catch (error) {
				commit('errorCall', error);
				return false;
			}
        }
	},
	mutations: {
		clearTags(state) {
			state.tags = [];
		},
		addTag(state, payload) {
			state.tags.push(payload);
		},
		setTag (state, payload) {
			const tag = state.tags.find(m => m.id === payload.id );
			if (tag) {
				tag.value = payload.value;
                tag.description = payload.description;
                tag.language = payload.language;
                tag.published = payload.published;
                tag.created = payload.created;
                tag.userId = payload.userId;
                // tag.thumbnailUrl = payload.thumbnailUrl;
                // tag.imageSrc = payload.imageSrc;
                // tag.storageUrl = payload.storageUrl;
                // tag.votesUp = payload.votesUp;
                // tag.votesDown = payload.votesDown;
			}
		},
		deleteTag(state, payload) {
			Vue.delete(state.tags, state.tags.indexOf(payload));
		}
	}
}
