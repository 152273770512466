<template>
  <div>
    <v-banner v-if="missingEmailVerification" type="info" transition="slide-y-transition" color="secondary">
      {{ $t('user.email-verify.message-not-verified') }}
      <template v-slot:actions="{ dismiss }">
        <v-btn text color="onSecondary" @click="sendEmailVerification()">
          {{ $t('user.email-verify.email-verify-label') }}
        </v-btn>
      </template>
    </v-banner>
    <v-banner v-else-if="verificationMailWasRecentlySent" type="info" transition="slide-y-transition" color="secondary">
      {{ $t('user.email-verify.message-mail-sent') }}
      <template v-slot:actions="{ dismiss }">
        <v-btn text color="onSecondary" @click="dismiss">
          {{ $t('alert.dismiss') }}
        </v-btn>
      </template>
    </v-banner>
    <v-layout row>
      <v-flex xs12 sm8 offset-sm2 md6 offset-md3>
        <!-- <v-alert v-if="error" type="error" dismissible>{{ error.message }}</v-alert> -->
        <form @submit.prevent="signon">
          <v-card flat class="mt-0 mt-sm-16">
            <v-card-title class="pa-6 text-h3 h-break">
              {{ $t('user.signon.caption') }}
            </v-card-title>
            <v-card-text class="px-6 py-0 my-0 text-body-1">
              <p class="mb-6">{{ $t('user.signon.message') }}</p>
              <v-text-field
                name="email"
                :label="$t('user.signon.email-label')"
                id="email"
                :rules="[rules.required]"
                v-model="email"
                type="email"
                outlined required></v-text-field>
              <v-text-field
                name="password"
                :label="$t('user.signon.password-label')"
                id="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                @click:append="(showPassword = !showPassword)"
                outlined required></v-text-field>
            </v-card-text>
            <v-card-actions class="px-6 my-0 flex-wrap">
              <v-btn block type="submit" color="primary" class="px-4 mb-4 d-flex" :disabled="loading" :loading="loading">
                {{ $t('user.signon.login') }}
              </v-btn>
              <v-btn text :disabled="loading" :to="{ name: 'Signup'}">
                {{ $t('user.signon.no-login') }}
              </v-btn>
              <v-spacer/>
              <v-btn text :disabled="loading" :to="{ name: 'ResetPassword'}">
                {{ $t('user.signon.reset-password') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        email: '',
        password: '',
        showPassword: false,
        rules: {
          required: value => !!value || 'Required.',
          // emailMatch: () => (`The email and password you entered don't match`), // TODO
        },
        missingEmailVerification: false,
      }
    },
    computed: {
      verificationMailWasRecentlySent() {
        return this.$store.getters.verificationMailWasRecentlySent
      },
      user() {
        return this.$store.getters.user
      },
      // error() {
      //   return this.$store.getters.error
      // },
      loading() {
        return this.$store.getters.loading
      }
    },
    watch: {
      user(value) {
        if (value !== null && value !== undefined && value.roles) {
          if (this.$route.query && !(Object.keys(this.$route.query).length === 0)) {
            console.debug("user changed >>> routing back to " + this.$route.query.redirectedFrom + " ...");
            this.$router.replace(this.$route.query.redirectedFrom);
          } else {
            console.debug("user changed, but no redirectedFrom (original route) found >>> routing to home page ...");
            this.$router.push('/');
          }
        }
      }
    },
    methods: {
      signon() {
        // console.debug("signon() method called in Signin view")
        this.$store.dispatch('signUserIn', {email: this.email, password: this.password})
        .catch(error => {
          if (error.code == "auth/emailnotverified") {
            this.missingEmailVerification = true;
            this.$store.dispatch('clearError');
          } else if (error.code == "auth/user-not-found") {
            this.$store.dispatch('errorCall', {message: this.$t('user.signon.error-user-not-found')});
          } else if (error.code == "auth/wrong-password") {
            this.$store.dispatch('errorCall', {message: this.$t('user.signon.error-wrong-password')});
          } else {
            this.$store.dispatch('errorCall', {message: this.$t('user.signon.error-unknown')});
          }
        });
      },
      sendEmailVerification () {
        this.$store.dispatch('sendEmailVerification')
      },
      dismissed() {
        this.$store.dispatch('clearError')
      }
    }
  }
</script>

<style scoped>

</style>