<!-- // https://developer.mozilla.org/en-US/docs/Web/Manifest/share_target -->
<!-- // https://developer.chrome.com/articles/web-share-target/ -->
  <template>
  <div>
    <h1>Share Target</h1>
    <p>You sent the following data to the Open Physio App:</p>
    <v-card>
      <ul class="pa-4 ma-4">
        <li>Shared title: {{ sharedTitle }}</li>
        <li>Shared text: {{ sharedText }}</li>
        <li>Shared url: {{ sharedUrl }}</li>
        <p><router-link to="/sharetarget?title=testtitle&text=testtext&url=testurl">/sharetarget?title= ...</router-link></p>
        <p><router-link :to="'/sharetarget?title=testtitle2&text=' + encodeURIComponent('https://www.youtube.com/watch?v=J486Fn4Rhms&t=40s') + '&url=testurl2'">/sharetarget?title= ..2..</router-link></p>
        <p><router-link :to="'/sharetarget?title=testtitle3&text=' + encodeURIComponent('https://www.youtube.com/watch?v=HwmOqLFGsWc') + '&url=testurl2'">/sharetarget?title= ..3..</router-link></p>
      </ul>
    </v-card>
    <div>
      <p><router-link to="/">Back to home screen.</router-link></p>
    </div>
    <p><router-link to="/sharetarget?title=testtitle&text=testtext&url=testurl">/sharetarget?title= ...</router-link></p>
    <p><router-link to="/sharetarget?title=testtitle2&text=testtext2&url=testurl2">/sharetarget?title= ..2..</router-link></p>
  </div>
</template>

<script async defer>

// self.addEventListener('fetch', event => {
//     const url = new URL(event.request.url);
//     // If this is an incoming POST request for the
//     // registered "action" URL, respond to it.
//     if (event.request.method === 'POST' &&
//         url.pathname === '/bookmark') {
//     event.respondWith((async () => {
//         const formData = await event.request.formData();
//         const link = formData.get('link') || '';
//         const responseUrl = await saveBookmark(link);
//         return Response.redirect(responseUrl, 303);
//     })());
//     }
// });

export default {
  name: "ShareTarget",
  data() {
    return {
      sharedTitle: '',
      sharedText: '',
      sharedUrl: ''
    }
  },
  computed: {
  },
  watch: {
    '$route.params.username': {
      handler: function() {

        const parsedUrl = new URL(window.location);
        // console.debug(parsedUrl.searchParams.get('title'));
        this.sharedTitle = parsedUrl.searchParams.get('title');
        this.sharedText = parsedUrl.searchParams.get('text');
        this.sharedUrl= parsedUrl.searchParams.get('url');
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('DOMContentLoaded', () => {
      const parsedUrl = new URL(window.location);
      // searchParams.get() will properly handle decoding the values.
      // console.debug('Title shared: ' + parsedUrl.searchParams.get('title'));
      // console.debug('Text shared: ' + parsedUrl.searchParams.get('text'));
      // console.debug('URL shared: ' + parsedUrl.searchParams.get('url'));
    });
  },
  methods: {
  }
}

</script>