<template>
<v-container>
  <v-layout row>
    <v-flex xs12 sm6 offset-sm3>
      <div v-if="loading" class="loading text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-alert v-if="error" type="error" dismissible>{{ error.message }}</v-alert>
      <form ref="form" lazy-validation>
        <v-card flat class="mt-xs-0 mt-sm-16">
          <v-card-title class="pa-6 text-h5 h-break">
            {{ $t('video-snippet.import.playlist.caption') }}
          </v-card-title>
          <v-card-text class="px-6 py-0 my-0 text-body-1">
            <v-select
                  v-model="editedPlatform"
                  :items="['youtube']"
                  :label="$t('video-snippet.create.platform-label')"
                  outlined></v-select>
            <v-text-field
                class="mb-0"
                v-model="editedExternalPlaylistId"
                ref="externalPlaylistIdField"
                :rules="externalPlaylistIdRules"
                :label="$t('video-snippet.import.playlist.playlist-id-label')"
                :placeholder="$t('video-snippet.import.playlist.playlist-id-placeholder')"
                required outlined
                @paste="processPastedURLorID"
                @dblclick="editedExternalPlaylistId = 'PLVJ4AORWwxCy5ik0glUc7xF2tOg0Ciiwi'"
                ></v-text-field>
                <v-simple-table v-if="editedName" class="mb-7">
                  <template v-slot:default>
                    <!-- <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Calories
                        </th>
                      </tr>
                    </thead> -->
                    <tbody>
                      <tr>
                        <td>{{ $t('video-snippet.import.playlist.playlist-name-label') }}</td>
                        <td>{{ editedName }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('video-snippet.import.playlist.playlist-description-label') }}</td>
                        <td>{{ editedDescription }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('video-snippet.import.playlist.playlist-author-label') }}</td>
                        <td>{{ editedChannelTitle }}</td>
                      </tr>
                      <tr>
                        <td># Videos</td>
                        <td>{{ numberOfVideos }} <div style="color:red" v-if="numberOfVideos >= 50"><br/>Max 50 videos can be imported, if your playlist has more, only the first 50 will be imported</div></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

            <!-- Tagging -->
            <v-combobox
              v-model="editedSelectedTags"
              :items="existingTags"
              :label="$t('video-snippet.edit.tags-label')"
              multiple
              small-chips
              outlined
              deletable-chips
              :delimiters="[',',';']"
              hide-selected
              :rules="tagRules"
              class="tag-input"
              :search-input.sync="tagSearch"
              >
              <template v-slot:no-data>
                <v-list-item v-if="tagSearch">
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ tagSearch }}</strong>". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
            <v-select
                  v-if="userIsFamily"
                  v-model="editedPublished"
                  :items="['published', 'to-approve', 'hidden']"
                  :label="$t('video-snippet.create.published-label')"
                  outlined></v-select>
            <div v-if="statusbar.show" class="mb-4">{{ statusbar.message }}</div>

          </v-card-text>
          <v-card-actions class="mx-4 mb-4">
            <v-btn v-if="!(state === 'executing')" type="button" @click="importPlaylist" color="primary" :disabled="loading || !allTagsValid || !editedExternalPlaylistId" :loading="loading">
              {{ editedPublished === "published"  ? $t('video-snippet.import.playlist.button-save-and-publish') : $t('video-snippet.import.playlist.button-save-suggest') }}
              <!-- (#{{ numberOfVideos }})  -->
            </v-btn>
            <v-btn v-if="state === 'executing'" type="button" text @click="cancelImport">Import {{ $t('nav.cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-flex>
  </v-layout>
  <v-snackbar v-model="snackbar.show" timeout="20000">
    <v-icon left color="green">mdi-check</v-icon>
    {{ snackbar.message }}
    <v-btn class="ml-4" @click="closeSnackbar">
      {{ $t('nav.close') }}
    </v-btn>
  </v-snackbar>
</v-container>
</template>

<script>
// import {loadVimeoData, extractDataFromVimeoUrl } from "@/service/vimeo";
import {loadYoutubePlaylistData, loadYoutubeData, extractDataFromYouTubeUrl, getVideoIdsForPlaylist} from "@/service/youtube";
import statelessHelpers from '@/mixins/statelessHelpers';

export default {
  name: "VideoSnippetImportPlaylist",
  components: {
},
  data() {
    return {
      maxTagLength: 40,
      tagSearch: null,
      videoIds: [],
      editedPlatform: "youtube",
      editedName: '',
      editedDescription: '',
      editedChannelTitle: '',
      editedExternalPlaylistId: '',
      updatingExternalIdAndPlatformTogether: false,
      editedSelectedTags: [],
      editedPublished: "to-approve",
      succesfullyLoadedPlaylistData: false,
      tagsToImport: [],
      externalPlaylistIdRules: [],
      youtubePlaylistIdRules: [
        v => !!v || this.$t('video-snippet.import.playlist.playlist-id-rule-required'),
        v => (!!v && this.validYoutubePlaylistID(v)) || this.$t('video-snippet.import.playlist.playlist-id-rule-no-valid-youtube-id'),
        v => (!!v && this.succesfullyLoadedPlaylistData) || this.$t('video-snippet.import.playlist.playlist-id-rule-no-loadable-playlist'),
      ],
      vimeoPlaylistIdRules: [
        v => !!v || this.$t('video-snippet.import.playlist.playlist-id-rule-required'),
        v => (!!v && this.validVimeoPlaylistID(v)) || this.$t('video-snippet.import.playlist.playlist-id-rule-no-valid-vimeo-id'),
      ],
      generalPlaylistIdRules: [
        v => !!v || this.$t('video-snippet.import.playlist.playlist-id-rule-required'),
      ],
      tagRules: [
        (v) => {
          if (v.length > 0) {
            for (let i = 0; i < v.length; i++) {
              if (v[i].length > this.maxTagLength)
              return this.$t('tag.edit.tag-rule-length-max', {maxTagLength: this.maxTagLength});
              }
            return true;
          } else
            return true;
        }
      ],
      state: "initial",
      snackbar: {
        show: false,
        message: ''
      },
      statusbar: {
        show: true,
        message: ''
      },
    };
  },
  mounted() {
    this.$refs.externalPlaylistIdField.focus();

    // Initialize externalPlaylistIdRules based on the initially selected platform
    if (this.editedPlatform === 'youtube') {
      this.externalPlaylistIdRules = this.youtubePlaylistIdRules;
    } else if (this.editedPlatform === 'vimeo') {
      this.externalPlaylistIdRules = this.vimeoPlaylistIdRules;
    } else {
      this.externalPlaylistIdRules = this.generalPlaylistIdRules;
    }
  },
  watch: {
    editedPlatform(){
      // update the playlist ID rules based on the selected platform
      if (this.editedPlatform === 'youtube') {
        this.externalPlaylistIdRules = youtubePlaylistIdRules;
        if (!this.updatingExternalIdAndPlatformTogether) {
          this.evaluateExternalPlaylistIdField();
        }
      } else if (this.editedPlatform === 'vimeo') {
        this.externalPlaylistIdRules = this.vimeoPlaylistIdRules;
        if (!this.updatingExternalIdAndPlatformTogether) {
          this.evaluateExternalPlaylistIdField();
        }
      } else {
        this.externalPlaylistIdRules = this.generalPlaylistIdRules;
      }

      // Reset validation state of input field
      this.$refs.externalPlaylistIdField.resetValidation();
    },
    editedExternalPlaylistId(){
      this.evaluateExternalPlaylistIdField();
      // console.debug("editedExternalPlaylistId changed");
    },
    '$route.params': {
      // see also ShareTarget.vue component, which is only used for testing atm
      handler: function() {
        const parsedUrl = new URL(window.location);
        const sharedText = parsedUrl?.searchParams?.get('text');
        if (sharedText) {
          this.extractDataFromUrl(sharedText);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    loading: function() { return this.$store.getters.loading; },
    error: function() { return this.$store.getters.error; },
    user() { return this.$store.getters.user },
    userIsAuthenticated() {
      return this.user !== null && this.user !== undefined
    },
    userIsFamily() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("family");
    },
    allTagsValid(){
      if (this.editedSelectedTags && this.editedSelectedTags !== undefined) {
        for (let i = 0; i < this.editedSelectedTags.length; i++) {
          if (this.editedSelectedTags[i].length > this.maxTagLength)
            return false;
          }
        }
      return true;  
    },
    existingTags() {
      return this.$store.getters.tagValues || []; // TODO: [OP-163] make sure on creation and usage of tags that always the lowercase is used
    },
    numberOfVideos() {
      return this.videoIds?.length || 0;
    },
  },
  mixins: [statelessHelpers],
  methods: {
    clearFormExceptId() {
      // clears the form fields, except the external playlist ID field
      console.info("clearing form ... ");


      // this.editedSelectedTags = [];
      // this.editedPublished = "to-approve";
      this.editedName = '';
      this.editedDescription = '';
      this.editedChannelTitle = '';
    },
    evaluateExternalPlaylistIdField() {
      // if the field still contains a URL, try to extract the ID from it
      if (this.editedExternalPlaylistId.indexOf("http") == 0 ) {
        this.extractDataFromUrl(this.editedExternalPlaylistId);
      } else { // if the field contains an ID, try to load the playlist data
        if (this.editedPlatform === 'youtube' && this.validYoutubePlaylistID(this.editedExternalPlaylistId)) {
          this.fillYoutubePlaylist();
        } else if (this.editedPlatform === 'vimeo' && this.validVimeoPlaylistID(this.editedExternalPlaylistId)) {
          // this.fillVimeo();
        }
      }
    },
    cancelImport() {
      this.state="cancelled";
      this.statusbar.show = false;
      this.snackbar.show = true;
      this.snackbar.message = this.$t('video-snippet.import.playlist.playlist-import-cancelled-message');
    },
    closeSnackbar() {
      this.snackbar.show = false;
    },
    async importPlaylist () {

      this.state="executing";

      // if the platform is youtube, use the youtube service to load the playlist data
      if (this.editedPlatform === 'youtube') {
        console.debug("importing youtube playlist: ", this.editedExternalPlaylistId);

        if (!this.videoIds || this.videoIds.length == 0) {
          this.videoIds = await getVideoIdsForPlaylist(this.editedExternalPlaylistId);
        }

        // import each video in the playlist
        for (let i = 0; i < this.videoIds.length; i++) {

            if (this.state === "cancelled") {
              return;
            } else {
              const videoId = this.videoIds[i];
              // console.debug("importing video: ", videoId);
              await this.importVideo(videoId);
              this.statusbar.show = true;
              this.statusbar.message = "Added video " + videoId + " (" + (i+1) + "/" + this.videoIds.length + ")";
              
              // wait for 1 second before importing the next video
              await new Promise(resolve => setTimeout(resolve, 1000));
            }

          }

      } else if (this.editedPlatform === 'vimeo') {
        // console.debug("importing vimeo playlist: ", this.editedExternalPlaylistId);
        console.debug("importing vimeo playlist not yet implemented");
      }

      // extend the list of tags to import with the tags from the form
      this.tagsToImport = [...this.tagsToImport, ...this.editedSelectedTags];
      
      if (this.tagsToImport && this.tagsToImport.length > 0) {
        await this.$store.dispatch("createTags", { tagValues: this.tagsToImport });
      }

      this.clearFormExceptId();
      this.editedExternalPlaylistId = ''; // not cleared by clearFormExceptId()
      this.statusbar.show = false;
      this.snackbar.show = true;
      this.snackbar.message = this.$t('video-snippet.import.playlist.playlist-successfully-imported-message');
      this.state="done";
      console.debug("state was set to done");

    },
    async importVideo (videoId) {

      // check if the video is already in the database
      const videoExists = await this.$store.dispatch("checkIfVideoExists", {
        externalId: videoId,
        platform: this.editedPlatform,
      });

      if (videoExists) {
        console.debug("video already exists, skipping ... ", videoId);
        return;
      }

      // if the platform is youtube, use the youtube service to load the playlist data
      if (this.editedPlatform === 'youtube') {
        console.debug("importing youtube video: ", videoId);

        let someOfTheVideoMetaData = {};
        try {
            someOfTheVideoMetaData = await loadYoutubeData(videoId);
            // console.debug("videoMetaData: ", someOfTheVideoMetaData);
            await this.saveVideoSnippet({
              id: videoId,
              platform: "youtube",
              name: someOfTheVideoMetaData.title,
              description: someOfTheVideoMetaData.description,
              tags: someOfTheVideoMetaData.tags,
              publisher: someOfTheVideoMetaData.channelTitle,
              publisherId: someOfTheVideoMetaData.channelId,
              publishedAt: someOfTheVideoMetaData.publishedAt,
              thumbnailUrl: someOfTheVideoMetaData.thumbnailUrl,
            });
        } catch (error) {
            console.debug("Couldn't import youtube video " + videoId);
        }
        try {
          // extend the list of tags to import with the tags of the video
          this.tagsToImport = [...this.tagsToImport, ...someOfTheVideoMetaData.tags];
          console.debug("tagsToImport: ", this.tagsToImport);
        } catch (error) {
          console.debug("Couldn't import tags for youtube video " + videoId);
        }
      } else if (this.editedPlatform === 'vimeo') {
        console.debug("importing vimeo video: ", videoId);
      }
    },
    async saveVideoSnippet(video) {
      const createdVideoId = await this.$store.dispatch("createVideo", { // TODO: Reuse videos if they are already referenced by a snippet (and delete only if no more references exist (or never, if videos can be stored also without a snippet))
        externalId: video.id,
        platform: video.platform,
        name: video.name,
        description: video.description,
        tags: video.tags,
        publisher: video.publisher,
        publisherId: video.publisherId,
        publishedAt: video.publishedAt,
        thumbnailUrl: video.thumbnailUrl,
      });

      // combine tags from video and from form
      const combinedTags = [...new Set([...video.tags, ...this.editedSelectedTags])];

      this.$store.dispatch("createVideoSnippet", {
        videoId: createdVideoId,
        userId: this.user ? this.user.id : null , // if no user logged in we create an anonymous snippet
        tags: combinedTags ? combinedTags : [],
        published: this.editedPublished
      });
    },
    validYoutubePlaylistID(someString){
      return someString.length >= 18 && someString.length <= 34;
    },
    validVimeoPlaylistID(someString){
      return someString.length >= 7 && someString.length <= 9;
    },
    async processPastedURLorID(event) {
      // var urlOrId = event.target.value; // also possible
      var urlOrId = event.clipboardData.getData('text')
      // wait some time until the paste is complete before changing the URL string in the input field to an ID string
      // otherwise the paste might overwrite our change
      setTimeout(async () => {
        await this.extractDataFromUrl(urlOrId);
        // wait before returning, so that when editing the id text field programatically, 
        // it won't become overwritten by the pasted value, which takes some time
      }, 250);
    },
    async extractDataFromUrl(urlOrId) {

      // console.debug("pasted: ", urlOrId);
      // this.editedExternalPlaylistId = urlOrId + " (edited)";

      if (urlOrId.indexOf("http") == 0 ) {

        var data = {};

        if (urlOrId.indexOf("youtube") > -1 || urlOrId.indexOf("youtu.be") > -1) {
          this.updatingExternalIdAndPlatformTogether = true; // prevent the editedPlatform field from triggering this function again on change
            this.editedPlatform = "youtube";
            data = extractDataFromYouTubeUrl(urlOrId);
          this.updatingExternalIdAndPlatformTogether = false;
        } else if (urlOrId.indexOf("vimeo") > -1) {
          this.updatingExternalIdAndPlatformTogether = true; // prevent the editedPlatform field from triggering this function again on change
            this.editedPlatform = "vimeo";
            data = extractDataFromVimeoUrl(urlOrId);
          this.updatingExternalIdAndPlatformTogether = false;
        }
        console.debug(data);
          
        if (data.id) {
          this.editedExternalPlaylistId = data.id;
        }
      } else {
        // do nothing, since this is probably a pure ID
        // alert(urlOrId);
      }
    },
    async fillYoutubePlaylist() {
      if (!this.editedExternalPlaylistId) {
        console.error("Can't load YouTube playlist, since editedExternalPlaylistId is not defined")
      } else {

        console.debug("loading youtube playlist: ", this.editedExternalPlaylistId);

        try {
          const playlistMetaData = await loadYoutubePlaylistData(this.editedExternalPlaylistId);

          this.editedName = playlistMetaData.title;
          this.editedDescription = playlistMetaData.description;
          this.editedChannelTitle = playlistMetaData.channelTitle;
         
          console.debug("youtubeData: ", playlistMetaData);

          this.videoIds = await getVideoIdsForPlaylist(this.editedExternalPlaylistId);

          this.succesfullyLoadedPlaylistData = true;
          // Reset validation state of input field
          this.$refs.externalPlaylistIdField.resetValidation();

        } catch (error) {
          this.clearFormExceptId();
          
          // TODO: [OP-221] although the error is handled, the error message is only displayed by the rule after the focus is lost
          this.succesfullyLoadedPlaylistData = false;
          // Reset validation state of input field
          this.$refs.externalPlaylistIdField.resetValidation();

          console.debug ("Can't load youtube playlist: ", error);
        }

      }
    },
  }
};
</script>
