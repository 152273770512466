<template>
  <v-container>
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3>
        <div v-if="loading" class="loading text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <v-alert v-if="error" type="error" dismissible>{{ error.message }}</v-alert>
        <form @submit.prevent="save" ref="form" lazy-validation>
          <v-card flat class="mt-xs-0 mt-sm-16">
            <v-card-title class="pa-6 text-h5 h-break">
              {{ $t('video-snippet.create.caption-suggest') }}
            </v-card-title>
            <v-card-text class="px-6 py-0 my-0 text-body-1">
              <v-select v-model="editedPlatform" :items="['youtube', 'vimeo']"
                :label="$t('video-snippet.create.platform-label')" outlined></v-select>
              <!-- button for adding a whole playlist if platform is youtube -->
              <v-btn block class="mt-0 mb-7" v-if="userIsAuthenticated && editedPlatform === 'youtube'"
                to="/video-snippet/import/playlist">
                {{ $t('video-snippet.create.import-playlist') }}
              </v-btn>
              <v-text-field class="mb-0" v-model="editedExternalVideoId" ref="externalVideoIdField"
                :rules="externalVideoIdRules" :label="$t('video-snippet.create.video-id-label')"
                :placeholder="$t('video-snippet.create.video-id-placeholder')" required outlined
                @paste="processPastedURLorID" @dblclick="editedExternalVideoId = 'lt77LscZcn0'"></v-text-field>
              <!-- @input="" -->
              <v-img class="mt-0 mb-2" height="250" v-if="originalVideo.thumbnailUrl"
                :src="originalVideo.thumbnailUrl"></v-img>
              <div class="mt-0 mb-7"><video-summary :video="originalVideo"
                  :videoSnippet="tempVideoSnippet"></video-summary></div>
              <!-- <video-snippet-card :videoSnippet="videoSnippet"/> -->
              <!-- display start end end time inputs if the user selects this -->
              <!-- <v-checkbox v-model="editStartOrEnd" :label="$t('video-snippet.create.expand-edit-start-end-label')"></v-checkbox> -->
              <!-- let the user switch between option a and b via a radio button -->
              <!-- <v-radio-group v-model="editStartOrEnd" row>
              <v-radio :label="$t('video-snippet.create.full-video')" value="false"></v-radio>
              <v-radio :label="$t('video-snippet.create.edit-start-end-label')" value="true"></v-radio>
            </v-radio-group> -->
              <v-expansion-panels v-if="userIsFamily" accordion class="mb-7">
                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('video-snippet.create.expand-edit-start-end-label')
                    }}</v-expansion-panel-header>
                  <v-expansion-panel-content>

                    {{ $t('video-snippet.create.expand-edit-start-end-placeholder') }}

                    <hours-minutes-seconds-picker class="mt-7" v-model="editedVideoSnippetStart"
                      :label="$t('video-snippet.create.video-start-time-label')"
                      :placeholder="$t('video-snippet.create.video-start-time-placeholder')" outlined />
                    <hours-minutes-seconds-picker v-model="editedVideoSnippetEnd"
                      :label="$t('video-snippet.create.video-end-time-label')"
                      :placeholder="$t('video-snippet.create.video-end-time-placeholder')" outlined />

                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-expansion-panels v-if="userIsFamily" accordion class="mb-7">
                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('video-snippet.create.expand-own-description-and-title-label')
                    }}</v-expansion-panel-header>
                  <v-expansion-panel-content>

                    {{ $t('video-snippet.create.expand-own-description-and-title-placeholder') }}


                    <v-text-field class="mt-7" v-if="userIsFamily" v-model="editedName" :rules="editedNameRules"
                      :label="$t('video-snippet.create.name-label')" outlined
                      :placeholder="$t('video-snippet.create.name-placeholder')"></v-text-field>
                    <v-textarea v-if="userIsFamily" v-model="editedDescription"
                      :label="$t('video-snippet.create.description-label')"
                      :placeholder="$t('video-snippet.create.description-placeholder')" auto-grow outlined>
                    </v-textarea>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- Tagging -->
              <v-combobox v-model="editedSelectedTags" :items="existingTags"
                :label="$t('video-snippet.edit.tags-label')" multiple small-chips outlined deletable-chips
                :delimiters="[',', ';']" hide-selected :rules="tagRules" class="tag-input"
                :search-input.sync="tagSearch">
                <template v-slot:no-data>
                  <v-list-item v-if="tagSearch">
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{ tagSearch }}</strong>". Press <kbd>enter</kbd> to create a new
                        one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
              <!-- not in use -->
              <!--
            clearable
            @keyup.tab="updateTags"
            @paste="updateTags"
            append-icon // causes the arrow to disappear
            dense // layout problems
            -->

              <!-- <v-checkbox v-model="editedPublished" :label="$t('video-snippet.create.published-label')"></v-checkbox> -->
              <!-- vuetify select for three options "published" "to-approve" "hidden" -->
              <v-select v-if="userIsFamily" v-model="editedPublished" :items="['published', 'to-approve', 'hidden']"
                :label="$t('video-snippet.create.published-label')" outlined></v-select>

            </v-card-text>
            <v-card-actions class="mx-4 mb-4">
              <v-btn type="submit" color="primary" :disabled="loading || !allTagsValid || videoAlreadyInDatabase"
                :loading="loading">
                {{ editedPublished === "published" ? $t('video-snippet.create.button-save-and-publish') :
          $t('video-snippet.create.button-save-suggest') }}
              </v-btn>
              <v-spacer />
              <v-btn text to="/">{{ $t('nav.cancel') }}</v-btn>
              <!-- <v-btn @click="fillYoutube()" :disabled="editedExternalVideoId === ''">Mit Youtube Daten füllen</v-btn> -->
            </v-card-actions>
          </v-card>
        </form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { loadVimeoData, extractDataFromVimeoUrl } from "@/service/vimeo";
import { loadYoutubeData, extractDataFromYouTubeUrl } from "@/service/youtube";
import HoursMinutesSecondsPicker from "@/components/inputs/HoursMinutesSecondsPicker"
import VideoSummary from '@/components/video/VideoSummary.vue';
// import VideoSnippetCard from "@/components/VideoSnippetCard";
import statelessHelpers from '@/mixins/statelessHelpers';

export default {
  name: "VideoSnippetCreate",
  components: {
    // VideoSnippetCard,
    HoursMinutesSecondsPicker,
    VideoSummary,
  },
  data() {
    return {
      maxTagLength: 40,
      tagSearch: null,
      originalVideo: {
        id: "", // e.g. youtube id: lt77LscZcn0 or vimeo id: 234965351
        name: "", // e.g. youtube title
        description: "", // e.g. full youtube description
        duration: "",
        tags: [],
        publisher: "", // e.g. youtube channel title
        publisherId: "", // e.g. youtube channel id
        publishedAt: "", // e.g. youtube
        // source: "", // e.g. https://www.youtube.com/watch?v=lt77LscZcn0
        platform: "", // e.g. youtube, vimeo, ...
        thumbnailUrl: "" // e.g. https://i.ytimg.com/vi/lt77LscZcn0/hqdefault.jpg 
      },
      editedPlatform: "youtube",
      editedName: '',
      editedDescription: '',
      imageSrc: '',
      editedExternalVideoId: '',
      updatingExternalIdAndPlatformTogether: false,
      editStartOrEnd: false,
      editedVideoSnippetStart: 0,
      editedVideoSnippetEnd: 0,
      editedSelectedTags: [],
      editedPublished: "to-approve",
      succesfullyLoadedVideoData: true,
      videoAlreadyInDatabase: false,
      externalVideoIdRules: [],
      youtubeVideoIdRules: [
        // TODO: this needs to be redundant atm, to be set also when the platform is not yet changed ()
        v => !!v || this.$t('video-snippet.create.video-id-rule-required'),
        v => (!!v && this.validYoutubeID(v)) || this.$t('video-snippet.create.video-id-rule-no-valid-youtube-id'),
        v => (!!v && !this.videoAlreadyInDatabase) || this.$t('video-snippet.create.video-id-rule-already-in-database'),
        v => (!!v && this.succesfullyLoadedVideoData) || this.$t('video-snippet.create.video-id-rule-no-loadable-video'),
      ],
      vimeoVideoIdRules: [
        v => !!v || this.$t('video-snippet.create.video-id-rule-required'),
        v => (!!v && this.validVimeoID(v)) || this.$t('video-snippet.create.video-id-rule-no-valid-vimeo-id'),
        v => (!!v && !this.videoAlreadyInDatabase) || this.$t('video-snippet.create.video-id-rule-already-in-database'),
      ],
      generalVideoIdRules: [
        v => !!v || this.$t('video-snippet.create.video-id-rule-required'),
        v => (!!v && !this.videoAlreadyInDatabase) || this.$t('video-snippet.create.video-id-rule-already-in-database'),
      ],
      editedNameRules: [
        // v => !!v || this.$t('video-snippet.create.name-rule-required'),
        v => (v?.length <= 120) || this.$t('video-snippet.create.name-rule-length-min'),
      ],
      tagRules: [
        (v) => {
          if (v.length > 0) {
            for (let i = 0; i < v.length; i++) {
              if (v[i].length > this.maxTagLength)
                return this.$t('tag.edit.tag-rule-length-max', { maxTagLength: this.maxTagLength });
            }
            return true;
          } else
            return true;
        }
      ]
    };
  },
  mounted() {
    // console.debug("mounted");
    this.$refs.externalVideoIdField.focus();

    // Initialize externalPlaylistIdRules based on the initially selected platform
    if (this.editedPlatform === 'youtube') {
      this.externalVideoIdRules = this.youtubeVideoIdRules;
    } else if (this.editedPlatform === 'vimeo') {
      this.externalVideoIdRules = this.vimeoVideoIdRules;
    } else {
      this.externalVideoIdRules = this.generalVideoIdRules;
    }
  },
  watch: {
    tagSearch(newVal) {
      setTimeout(() => {
        // necessary, since the syncronization of the search input field is delayed 
        // - better solution? wait for the sync event to finish?
        if (this.tagSearch === newVal) {
          if (newVal) this.tagSearch = this.cleanTag(newVal);
        }
      }, 250);
    },
    editedPlatform() {
      if (this.editedPlatform === 'youtube') {
        this.externalVideoIdRules = this.youtubeVideoIdRules;
        if (!this.updatingExternalIdAndPlatformTogether) {
          this.evaluateExternalVideoIdField();
        }
      } else if (this.editedPlatform === 'vimeo') {
        this.externalVideoIdRules = this.vimeoVideoIdRules;
        if (!this.updatingExternalIdAndPlatformTogether) {
          this.evaluateExternalVideoIdField();
        }
      } else {
        this.externalVideoIdRules = this.generalVideoIdRules;
      }

      // Reset validation state of input field
      this.$refs.externalVideoIdField.resetValidation();
    },
    editedExternalVideoId() {
      this.evaluateExternalVideoIdField();
      // console.debug("editedExternalVideoId changed");

      // if empty clear the form
      if (this.editedExternalVideoId === '') {
        this.clearForm();
      }
    },
    videoAlreadyInDatabase() {
      this.$refs.externalVideoIdField.resetValidation();
      // hack to show the validation error message
      setTimeout(() => {
        this.$refs.externalVideoIdField.blur();
        this.$refs.externalVideoIdField.focus();
      }, 500);
    },
    '$route.params': {
      // see also ShareTarget.vue component, which is only used for testing atm
      handler: function () {
        const parsedUrl = new URL(window.location);
        const sharedText = parsedUrl?.searchParams?.get('text');
        if (sharedText) {
          this.extractDataFromUrl(sharedText);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    tempVideoSnippet() { // for the video summary
      return {
        name: this.editedName || "",
        description: this.editedDescription || "",
        videoStart: this.editedVideoSnippetStart || 0,
        videoEnd: this.editedVideoSnippetEnd || 0,
        tags: this.editedSelectedTags || [],
      }
    },
    loading: function () { return this.$store.getters.loading; },
    error: function () { return this.$store.getters.error; },
    user() { return this.$store.getters.user },
    userIsAuthenticated() {
      return this.user !== null && this.user !== undefined
    },
    userIsFamily() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("family");
    },
    allTagsValid() {
      if (this.editedSelectedTags && this.editedSelectedTags !== undefined) {
        for (let i = 0; i < this.editedSelectedTags.length; i++) {
          if (this.editedSelectedTags[i].length > this.maxTagLength)
            return false;
        }
      }
      return true;
    },
    existingTags() {
      return this.$store.getters.tagValues;
    },
    cleanedOriginalVideoTags() {
      const cleanedTags = this.cleanTagsForStorage(this.originalVideo.tags);
      const cleanedTagsBelowMaxTagLength = cleanedTags.filter(tag => tag.length <= this.maxTagLength);
      if (cleanedTagsBelowMaxTagLength.length < cleanedTags.length) {
        const removedTags = cleanedTags.filter(tag => tag.length > this.maxTagLength);
        console.info("Some tags were removed, since they were longer than the max tag length of " + this.maxTagLength + " characters: " + removedTags.join(", "));
      }
      return cleanedTagsBelowMaxTagLength;
    },
    // videoSnippetPreview() { // TODO: problem: video snippet card needs the video as well (it cannot find it yet in the store, since it is not saved)
    //   return {
    //     name: this.editedName || "gen name",
    //     description: this.editedDescription || "gen descr",
    //   }
    // }
  },
  mixins: [statelessHelpers],
  methods: {
    tagIsAlreadyAdded(tag) {
      return this.editedSelectedTags.includes(tag);
    },
    clearForm() {
      console.info("clearing form ... ");
      this.originalVideo.id = "";
      this.originalVideo.name = "";
      this.originalVideo.description = "";
      //this.originalVideo.duration = "";
      this.originalVideo.tags = [];
      this.originalVideo.publisher = "";
      this.originalVideo.publisherId = "";
      this.originalVideo.publishedAt = "";
      // this.originalVideo.source = "";
      this.originalVideo.platform = "";
      this.originalVideo.thumbnailUrl = "";
      // this.editedPlatform = "youtube";
      this.editedName = '';
      this.editedDescription = '';
      this.imageSrc = '';
      // this.editedExternalVideoId = '';
      this.editedVideoSnippetStart = 0;
      this.editedVideoSnippetEnd = 0;
      this.editedSelectedTags = [];
      this.editedPublished = "to-approve";
    },
    evaluateExternalVideoIdField() {
      // if the field still contains a URL, try to extract the ID from it
      if (this.editedExternalVideoId.indexOf("http") == 0) {
        this.extractDataFromUrl(this.editedExternalVideoId);
      } else { // if the field contains an ID, try to load the video data
        if (this.editedPlatform === 'youtube' && this.validYoutubeID(this.editedExternalVideoId)) {
          this.fillYoutube();
        } else if (this.editedPlatform === 'vimeo' && this.validVimeoID(this.editedExternalVideoId)) {
          this.fillVimeo();
        }
      }
    },
    async save() {

      this.$store.dispatch("createTags", { tagValues: this.editedSelectedTags });
      // the tags from the original video are not included here, since they should not receive the users id
      // evtl. store them without using the users id)

      const createdVideoId = await this.$store.dispatch("createVideo", { // TODO: Reuse videos if they are already referenced by a snippet (and delete only if no more references exist (or never, if videos can be stored also without a snippet))
        externalId: this.originalVideo.id,
        platform: this.editedPlatform,
        name: this.originalVideo.name,
        description: this.originalVideo.description,
        duration: this.originalVideo.duration,
        tags: this.originalVideo.tags,
        publisher: this.originalVideo.publisher,
        publisherId: this.originalVideo.publisherId,
        publishedAt: this.originalVideo.publishedAt,
        thumbnailUrl: this.originalVideo.thumbnailUrl,
        // source: "",
        // platform: this.originalVideo.platform
      });
      this.$store.dispatch("createVideoSnippet", {
        videoId: createdVideoId,
        userId: this.user ? this.user.id : null, // if no user logged in we create an anonymous snippet
        name: this.editedName,
        description: this.editedDescription,
        imageSrc: this.imageSrc,
        videoStart: this.editedVideoSnippetStart,
        videoEnd: this.editedVideoSnippetEnd,
        tags: this.editedSelectedTags ? this.editedSelectedTags : [],
        published: this.editedPublished
      }).then((id) => {
        if (this.user) { // show expects authenticated user atm
          this.$router.push({ name: 'VideoSnippetShow', params: { id } });
        } else {
          this.$router.push({ name: 'VideoSnippetSearch' }); // TODO: highlight just added entity
        }
      }
      );
    },
    async fillVimeo() {

      if (!this.editedExternalVideoId) {
        console.error("Can't load vimeo data, since editedExternalVideoId is not defined")
      } else {

        this.clearForm();

        // show a message if the video is already in the database
        this.videoAlreadyInDatabase = await this.$store.dispatch("checkIfVideoExists", {
          externalId: this.editedExternalVideoId,
          platform: this.editedPlatform,
        });

        if (this.videoAlreadyInDatabase) {
          console.debug("video already exists: ", this.editedExternalVideoId);
        }

        try {
          const vimeoData = await loadVimeoData(this.editedExternalVideoId);

          if (!vimeoData) {
            throw new Error("Can't load vimeo data, since vimeoData is not defined")
          }

          // console.debug("vimeoData", vimeoData);
          this.originalVideo.platform = this.editedPlatform;
          this.originalVideo.id = this.editedExternalVideoId;
          this.originalVideo.name = vimeoData.title;
          this.originalVideo.description = vimeoData.description;
          this.originalVideo.tags = vimeoData.tags;
          this.originalVideo.publisher = vimeoData.user_name;
          this.originalVideo.publisherId = vimeoData.user_id;
          // TODO: Should the video loaders already provide transformed data? In the loader scripts its more reusable.
          this.originalVideo.publishedAt = new Date(vimeoData.upload_date).toISOString(); // unify dates: youtube uses "2020-07-25T07:01:47Z" versus vimeo "2017-09-22 02:36:58"
          // split tags by comma and remove empty tags
          this.originalVideo.tags = vimeoData.tags.split(",").filter(tag => tag.trim() !== "");
          this.editedSelectedTags = this.cleanedOriginalVideoTags;
          this.originalVideo.thumbnailUrl = vimeoData.thumbnail_large;

        } catch (error) {
          this.clearForm();
          console.error("Can't fill form with vimeo data: ", error);
        }

      }
    },
    async fillYoutube() {
      if (!this.editedExternalVideoId) {
        console.error("Can't load YouTube data, since editedExternalVideoId is not defined")
      } else {

        this.clearForm();

        // show a message if the video is already in the database
        this.videoAlreadyInDatabase = await this.$store.dispatch("checkIfVideoExists", {
          externalId: this.editedExternalVideoId,
          platform: this.editedPlatform,
        });

        if (this.videoAlreadyInDatabase) {
          console.debug("video already exists: ", this.editedExternalVideoId);
          return;
        }

        try {
          const youtubeData = await loadYoutubeData(this.editedExternalVideoId);
          // const youtubeData = {
          //   channelId:
          //   "UCgmcPHueYRarnCkihtNIRlw",
          //   channelTitle:
          //   "LearnVue",
          //   description:
          //   "The Web Share API can be a useful way to leverage a device's built in sharing features to boost your app's shares!",
          //   publishedAt:
          //   "2022-03-30T12:00:33Z",
          //   tags:
          //   ["vuejs", "web development", "technology"],
          //   thumbnailUrl:
          //   "https://i.ytimg.com/vi/lt77LscZcn0/hqdefault.jpg",
          //   title:
          //   "The Web Share API Simplified",
          // }
          // console.debug("youtubeData", youtubeData);
          this.originalVideo.platform = this.editedPlatform;
          this.originalVideo.id = this.editedExternalVideoId;
          this.originalVideo.name = youtubeData.title;
          this.originalVideo.description = youtubeData.description;
          this.originalVideo.duration = youtubeData.duration;
          // remove empty tags
          this.originalVideo.tags = youtubeData.tags.filter(tag => tag.trim() !== "");
          this.originalVideo.publisher = youtubeData.channelTitle;
          this.originalVideo.publisherId = youtubeData.channelId;
          this.originalVideo.publishedAt = youtubeData.publishedAt;
          this.originalVideo.thumbnailUrl = youtubeData.thumbnailUrl;
          // this.originalVideo.source = "";

          // this.editedName = this.originalVideo.name;
          // this.editedDescription = this.originalVideo.description;
          this.editedSelectedTags = this.cleanedOriginalVideoTags;
          // this.imageSrc = youtubeData.thumbnailUrl;

          this.succesfullyLoadedVideoData = true;
          // reset validation state of input field
          this.$refs.externalVideoIdField.resetValidation();

        } catch (error) {
          this.clearForm();

          this.succesfullyLoadedVideoData = false;
          // reset validation state of input field
          this.$refs.externalVideoIdField.resetValidation();

          console.debug("Can't fill form with youtube data: ", error);
        }

      }
    },
    validYoutubeID(someString) {
      return someString.length == 11;
    },
    validVimeoID(someString) {
      return someString.length >= 7 && someString.length <= 9;
    },
    async processPastedURLorID(event) {
      // var urlOrId = event.target.value; // also possible
      var urlOrId = event.clipboardData.getData('text')
      // wait some time until the paste is complete before changing the URL string in the input field to an ID string
      // otherwise the paste might overwrite our change
      setTimeout(async () => {
        await this.extractDataFromUrl(urlOrId);
        // wait before returning, so that when editing the id text field programatically, 
        // it won't become overwritten by the pasted value, which takes some time
      }, 250);
    },
    async extractDataFromUrl(urlOrId) {

      // console.debug("pasted: ", urlOrId);
      // this.editedExternalVideoId = urlOrId + " (edited)";

      if (urlOrId.indexOf("http") == 0) {

        var data = {};

        if (urlOrId.indexOf("youtube") > -1 || urlOrId.indexOf("youtu.be") > -1) {
          this.updatingExternalIdAndPlatformTogether = true; // prevent the editedPlatform field from triggering this function again on change
          this.editedPlatform = "youtube";
          data = extractDataFromYouTubeUrl(urlOrId);
          this.updatingExternalIdAndPlatformTogether = false;
        } else if (urlOrId.indexOf("vimeo") > -1) {
          this.updatingExternalIdAndPlatformTogether = true; // prevent the editedPlatform field from triggering this function again on change
          this.editedPlatform = "vimeo";
          data = extractDataFromVimeoUrl(urlOrId);
          this.updatingExternalIdAndPlatformTogether = false;
        }
        console.debug(data);

        if (data.id) {
          this.editedExternalVideoId = data.id;
          if (data.start) {
            this.editedVideoSnippetStart = data.start;
          }
          if (data.end) {
            this.editedVideoSnippetEnd = data.end;
          }
        }
      } else {
        // do nothing, since this is probably a pure ID
        // alert(urlOrId);
      }
    }
  }
};
</script>
