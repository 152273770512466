<template>

  <div>
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3>
        <div v-if="loading" class="loading text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <v-alert v-if="error" type="error" dismissible>{{ error.message }}</v-alert>
        <form @submit.prevent="saveOrUpdate">
          <v-card flat class="mt-16">
            <v-card-title class="pa-6 text-h3">
              Profil bearbeiten
            </v-card-title>
            <v-card-text class="px-6 py-0 my-0 text-body-1">
              <v-text-field v-model="editedNickname" :label="$t('user.edit.nickname-label')" outlined></v-text-field>
              <v-text-field v-model="editedRealname" :label="$t('user.edit.realname-label')" outlined></v-text-field>
              <v-avatar v-if="editedImageSrc && editedImageSrc !==''" size="128" class="mb-4">
                <v-img :src="editedImageSrc"></v-img>
              </v-avatar>
              <v-file-input
                  :label="$t('user.edit.image-upload-placeholder')"
                  prepend-icon="mdi-camera"
                  filled outlined
                  accept="image/png, image/jpeg, image/bmp"
                  ref="fileInput1"
                  @change="fileInput1Changed($event)"
              ></v-file-input>
              <div v-if="editedImageSrc" class="mb-12">
                <v-btn @click="deleteImage()" color="secondary">{{ $t('user.edit.image-delete-label') }}</v-btn>
              </div>
              <v-select prepend-inner-icon="mdi-earth"
                    v-model="editedLanguage"
                    :items="languages"
                    :label="$t('user.profile.language')"
                    item-text="title"
                    item-value="id"
                    outlined
                ></v-select>
            </v-card-text>
            <v-card-actions class="px-6 my-0">
              <v-btn type="submit" color="primary" class="px-8" :disabled="loading" :loading="loading">{{ $t('crud.save') }}</v-btn>
              <v-btn @click="cancel()" color="secondary" text>{{ $t('nav.cancel') }}</v-btn>&nbsp;
            </v-card-actions>
          </v-card>
        </form>
      </v-flex>
    </v-layout>
  </div>

</template>

<script>
export default {
  name: "ProfileEdit",
  data() {
    return {
      editedNickname: '',
      editedRealname: '',
      editedImage: null, // the image as binary
      editedImageSrc: '', // will also contain the preview image as string-converted binary 
      // languages currently redundant in App.vue
      editedLanguage: '',
      languages: [
        {
          id: 'en',
          title: 'English',
          // flagSrc: 'https://cdn.vuetifyjs.com/images/flags/us.png'
        },
        {
          id: 'de',
          title: 'Deutsch',
          // flagSrc: 'https://cdn.vuetifyjs.com/images/flags/us.png'
        }
      ]
    };
  },
  computed: {
    loading: function() {
      return this.$store.getters.loading;
    },
    error: function() {
      return this.$store.getters.error;
    },
    user () {
      return this.$store.getters.user;
    }
  },
  created() {
    this.editedNickname = this.user.nickname;
    this.editedRealname = this.user.realname;
    this.editedImageSrc = this.user.imageSrc;
    this.editedLanguage = this.user.language;
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'Profile' });
    },
    saveOrUpdate() {
      // update orginal object
      this.user.nickname = this.editedNickname;
      this.user.realname = this.editedRealname;
      this.user.language = this.editedLanguage;
      this.$store.dispatch("updateUserWithImage", { payload: this.user, imageFile: this.editedImage })
          .then(() => this.cancel());
    },
    deleteImage() {
      this.$store.dispatch("deleteUserImage", { payload: this.user })
          .then(() => this.editedImageSrc = "");
    },
    fileInput1Changed(event) {
      console.debug("fileInput1Changed ", event);
      
      let filename = event.name;
      if (filename.lastIndexOf('.') <= 0) {
        return alert ('Please add a file with file extension!');
      }

      const fileReader = new FileReader();
      fileReader.addEventListener('load', () => {
          this.editedImageSrc = fileReader.result; // save the image as string for preview
      })
      fileReader.readAsDataURL(event);
      this.editedImage = event; // save the image file 
    }
  }
};
</script>

<style scoped>
</style>
