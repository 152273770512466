<template>
<div class="video-container">
  <!-- TODO: embedding the youtube player like this throws an unhandled error in chrome, so this should be reviewed if it needs an update -->
  <iframe
    :src="`https://www.youtube-nocookie.com/embed/${id}?autoplay=false&origin=${host}${startTimeArgument}${endTimeArgument}`"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</div>
</template>

<script>
export default {
  name: "YouTubePlayer",
  props: ["id", "start", "end", "autoplay"],
  computed: {
    startTimeArgument() {
      if (!this.start || this.start === '0') {
        return "";
      } else {
        return "&start=" + this.start;
      }
    },
    endTimeArgument() {
      if (!this.end || this.end === '0') {
        return "";
      } else {
        return "&end=" + this.end;
      }
    },
    host() {
      return window.location.host;
    }
  }
};
</script>

<style>
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
