// https://github.com/vuejs/router

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

import NotFound from '@/views/NotFound'

// only for testing, shares go to VideoSnippetCreate atm:
import ShareTarget from '@/views/ShareTarget'

import Favorite from '@/views/user/Favorite'
import Profile from '@/views/user/Profile'
import ProfileEdit from '@/views/user/Edit'
import Signup from '@/views/user/Signup'
import Signin from '@/views/user/Signin'
import ResetPassword from '@/views/user/ResetPassword'
import AuthGuard from './auth-guard'

import Dashboard from '@/views/Dashboard'
import About from '@/views/About'
import Impressum from '@/views/Impressum'

import VideoSnippetSearch from '@/views/video-snippet/Search';
import VideoSnippetShow from '@/views/video-snippet/Show';
import VideoSnippetCreate from '@/views/video-snippet/Create';
import VideoSnippetImportPlaylist from '@/views/video-snippet/ImportPlaylist';
import VideoSnippetEdit from '@/views/video-snippet/Edit';

import SequenceShow from '@/views/sequence/Show';
import SequenceCreate from '@/views/sequence/Create';
import SequenceEdit from '@/views/sequence/Edit';

import ToolShow from '@/views/tool/Show';
import ToolCreate from '@/views/tool/Create';
import ToolEdit from '@/views/tool/Edit';

import AdminDashboard from '@/views/admin/AdminDashboard'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Dashboard', component: VideoSnippetSearch },
  { path: '/about', name: 'About', component: About },
  { path: '/impressum', name: 'Impressum', component: Impressum },
  { path: '/signup', name: 'Signup', component: Signup },
  { path: '/signin', name: 'Signin', component: Signin },
  { path: '/resetPassword', name: 'ResetPassword', component: ResetPassword },
  
  { path: '/profile', name: 'Profile', component: Profile, beforeEnter: AuthGuard },
  { path: '/profile/edit', name: 'ProfileEdit', component: ProfileEdit, beforeEnter: AuthGuard },
  { path: '/favorite', name: 'Favorite', component: Favorite, beforeEnter: AuthGuard },

  { path: '/video/search', name: 'VideoSnippetSearch', component: VideoSnippetSearch },
  { path: '/video-snippet/show/:id', name: 'VideoSnippetShow', component: VideoSnippetShow, props: true, beforeEnter: AuthGuard },
  { path: '/video-snippet/create', name: 'VideoSnippetCreate', component: VideoSnippetCreate },
  { path: '/video-snippet/import/playlist', name: 'VideoSnippetImportPlaylist', component: VideoSnippetImportPlaylist, props: true, beforeEnter: AuthGuard },
  { path: '/video-snippet/edit/:id', name: 'VideoSnippetEdit', component: VideoSnippetEdit, props: true, beforeEnter: AuthGuard },

  { path: '/tools-collections/search', name: 'ToolsCollectionsSearch', component: Dashboard },

  { path: '/sequence/show/:id', name: 'SequenceShow', component: SequenceShow, props: true, beforeEnter: AuthGuard },
  { path: '/sequence/create', name: 'SequenceCreate', component: SequenceCreate, beforeEnter: AuthGuard },
  { path: '/sequence/edit/:id', name: 'SequenceEdit', component: SequenceEdit, props: true, beforeEnter: AuthGuard },

  { path: '/tool/show/:id', name: 'ToolShow', component: ToolShow, props: true, beforeEnter: AuthGuard },
  { path: '/tool/create', name: 'ToolCreate', component: ToolCreate, beforeEnter: AuthGuard },
  { path: '/tool/edit/:id', name: 'ToolEdit', component: ToolEdit, props: true, beforeEnter: AuthGuard },

  { path: '/admin/', name: 'AdminDashboard', component: AdminDashboard, beforeEnter: AuthGuard,
    children: [
      { path: 'admin-dashboard', name: 'LoadExampleData', component: AdminDashboard }
    ] },

  { path: '/sharetarget', name: 'ShareTarget', component: VideoSnippetCreate },

  // due to firebase configuration all not-found paths now point to index.html
  // --> catchAll necessary
  // https://v3.router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations:
    { path: '/:catchAll(.*)', name: 'NotFound', component: NotFound },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('clearError');
  next();
})

export default router
