// https://v2.vuejs.org/v2/guide/migration.html#Two-Way-Filters-replaced
var hoursMinutesSeconds2WayFilter = {

    format: function (number) {
      // console.debug("number in validator when formatting: ", number);
      let hours =  parseInt(Math.floor(number / 3600));
      let minutes = parseInt(Math.floor((number - (hours * 3600)) / 60));
      let seconds= parseInt((number - ((hours * 3600) + (minutes * 60))) % 60);
      let dHours = (hours > 9 ? hours : '0' + hours);
      let dMins = (minutes > 9 ? minutes : '0' + minutes);
      let dSecs = (seconds > 9 ? seconds : '0' + seconds);
      return (hours > 0 ? dHours + ":" : "00:") + dMins + ":" + dSecs; // (!) this always returns hh:mm:ss, never hh:mm or mm:ss
    },
  
    parse: function (newString) {

      var timeToSeconds = function (timeString) {
        var partsOfStr = timeString.split(':');
        var seconds = 0;
        // handle  hh:mm ...
        if (partsOfStr.length === 2) {
            partsOfStr[2] = 0;
        } 
        if (partsOfStr.length === 3) {
            seconds = Number(partsOfStr[0])*3600 + Number(partsOfStr[1])*60 + Number(partsOfStr[2]);
        } else {
            throw new Error("time format incorrect, use hh:mm[:ss] (seconds are optional)");
        }
        // console.debug(seconds);
        return seconds;
      };

      var CleanParse = function (value) {
        return { value: value };
      };
  
      var newNumber = timeToSeconds(newString);
      return new CleanParse(newNumber);
    },
  };
  
  module.exports = {
    hoursMinutesSeconds2WayFilter: hoursMinutesSeconds2WayFilter
  };
  