<!-- There is only a clock-shaped time-picker in vuetify 2 and none in vuetify 3 so far  -->
<!-- https://v2.vuetifyjs.com/en/api/v-time-picker/#props -->
<!-- https://github.com/vuetifyjs/vuetify/issues/13480 -->
<!-- Instead we use: -->
<!-- https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time -->
<!-- However step="1" is not supported in Safari, IE and Opera Mini, so no seconds would be displayed -->
<!-- https://caniuse.com/input-datetime -->
<!-- in this case we use a fallback, atm the vuetify 2 time-picker -->
<template>
<div>
   <v-text-field
        :label="label"
        ref="input"
        v-model="valueString"
        :readonly="useFallBack ? true : false"
        @click="useFallBack ? openDialog() : null"
        :type="useFallBack ? 'false' : 'time'"
        :step="useFallBack ? 'false' : '1'"
        outlined
        :placeholder="placeholder"
        :suffix="useFallBack ? '' : 'hh:mm:ss'"
        :rules="rules"
        ></v-text-field>
        <!-- :label="label"  -->
        <!-- v-on:input="updateValue2($event)" -->
        <!-- v-on:focus="selectAll" -->
        <!-- v-on:blur="formatValue" -->

    <v-dialog v-if="useFallBack" v-model="dialog">
        <v-time-picker
            v-model="valueString"
            use-seconds
            format="24hr"
            flat
            :rules="rules"
            >
            <v-btn text color="primary" @click="confirmDialog()">{{ $t('nav.confirm') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="cancelDialog()">{{ $t('nav.cancel') }}</v-btn>
        </v-time-picker>
            <!-- scrollable -->
            <!-- full-width -->
    </v-dialog>
    
</div>
</template>

<script>
import {hoursMinutesSeconds2WayFilter } from "@/components/inputs/hoursMinutesSeconds2WayFilter";
export default {
    props: {
        value: {
            type: Number,
            default: 0
        },
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        plainText: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            valueString: "00:00:00",
            oldValueString: "00:00:00",
            fieldErrorMessage: "",
            useFallBack: false,
            rules: [
                v => !this.fieldErrorMessage || this.fieldErrorMessage,
            ],
            dialog: false,
            browser: "unknown"
        }
    },
    watch: {
        valueString() {
            this.updateValue();
        },
        value() {
            this.formatValue();
        }
    },
    mounted: function () {
       this.formatValue();
       const browser = this.detectBrowser();
    //    this.useFallBack = true;
       this.useFallBack = (browser === 'IE') || (browser === 'Safari on iOS') || (browser === 'Opera') || this.plainText;
    },
    methods: { 
        openDialog() {
            this.dialog = true;
            this.oldValueString = this.valueString;
        },
        confirmDialog() {
            this.dialog = false;
            this.oldValueString = this.valueString;
        },
        cancelDialog() {
            this.dialog = false;
            this.valueString = this.oldValueString;
        },
        updateValue: function () {
            try {
                var result = hoursMinutesSeconds2WayFilter.parse(this.valueString, this.value);
                this.fieldErrorMessage = "";
                this.$emit("input", result.value);
            } catch (error) {
                console.error(error);
                this.fieldErrorMessage = error.message;
            }
        },
        formatValue: function () {
            // console.debug("value in component when formatting: ", this.value);
            this.valueString = hoursMinutesSeconds2WayFilter.format(this.value);
        },
        // selectAll: function (event) {
        //     // Workaround for Safari bug
        //     // http://stackoverflow.com/questions/1269722/selecting-text-on-focus-using-jquery-not-working-in-safari-and-chrome
        //     setTimeout(function () {
        //         event.target.select();
        //     }, 0);
        // },
        detectBrowser() {

            // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browsers/9851769#9851769
            if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
                return 'Opera';
            }
            else if (navigator.userAgent.indexOf("Edg") != -1) {
                return 'Edge';
            }
            else if (navigator.userAgent.indexOf("Chrome") != -1) {
                return 'Chrome';
            }
            else if (navigator.userAgent.indexOf('Safari') != -1) {
                return this.isIOS() ? 'Safari on iOS' : 'Safari desktop';
            }
            else if (navigator.userAgent.indexOf("Firefox") != -1) {
                return 'Firefox';
            }
            else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
            {
                return 'IE';
            }
            else {
                return 'unknown';
            }
        },
        isIOS() {
            // return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            // deprecated but works for iPad on iOS 13 (otherwise detected as desktop Safari) 
            return /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
        }
    }
};
</script>
<style lang="css" scoped></style>