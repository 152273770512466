<template>
  <v-layout row>
    <v-flex xs12 sm6 offset-sm3>
      <div v-if="loading" class="loading text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-alert v-if="error" type="error" dismissible>{{ error.message }}</v-alert>
      <form @submit.prevent="save" ref="form" lazy-validation>
        <v-card flat class="mt-16">
          <v-card-title class="pa-6 text-h3">
            Neue Sammlung erstellen
          </v-card-title>
          <v-card-text class="px-6 py-0 my-0 text-body-1">
            <p class="mb-6">Anschließend können sie Tools zu ihrer Sammlung hinzufügen</p>
            <v-text-field
                v-model="editedName"
                :counter="120"
                :rules="editedNameRules"
                label="Name"
                required outlined
                placeholder="Name der Sammlung"
            ></v-text-field>
            <v-textarea
                v-model="editedDescription"
                rows="8"
                label="Beschreibung"
                placeholder="Beschreibung der Sammlung"
                auto-grow outlined hide-details
            ></v-textarea>
            <v-checkbox
                v-model="publishedSequence"
                label="Sammlung veröffentlichen"
            ></v-checkbox>
          </v-card-text>
          <v-card-actions class="px-6 my-0">
            <v-btn type="submit" color="primary" class="px-8" :disabled="loading" :loading="loading">Erstellen</v-btn>
            <v-btn text to="/">{{ $t('nav.cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      editedName: '',
      editedDescription: '',
      publishedSequence: false,
      editedNameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 120) || 'Name must be less than 120 characters',
      ],
    };
  },
  computed: {
    user: function() {
      return this.$store.getters.user;
    },
    loading: function() {
      return this.$store.getters.loading;
    },
    error: function() {
      return this.$store.getters.error;
    },
  },
  methods: {
    save() {
      this.$store.dispatch("createSequence", {
        userId: this.user.id,
        published: this.publishedSequence,
        name: this.editedName,
        description: this.editedDescription
      }).then((id) => this.$router.push({ name: 'SequenceShow', params: { id }}) );
    }
  }
};
</script>
