<template>
<div class="video-container">
    <!--`h=73e682a399&autoplay=1&loop=1&color=e20074&title=0&byline=0&portrait=0" -->
    <iframe 
    :src="`https://player.vimeo.com/video/`+ id + `?` + `&autoplay=0&loop=0&dnt=1`+ startTimeArgument"
    width="640" 
    height="360" 
    frameborder="0" 
    allow="autoplay; fullscreen; picture-in-picture" 
    allowfullscreen
  ></iframe>
  </div>
</template>

<script>

// https://help.vimeo.com/hc/en-us/articles/12425821012497-Start-playback-at-a-specific-timecode
// attention: the start time argument must be attached to the end of the url with a hash (#) instead of a question mark (?)

export default {
  name: "VimeoPlayer",
  props: ["id", "start", "end", "autoplay"],
  computed: {
    startTimeArgument() {
      if (!this.start || this.start === '0') {
        return "";
      } else {
        const minutes = Math.floor(this.start / 60);
        const seconds = this.start - minutes * 60;
        return `#t=${minutes}m${seconds}s`;
      }
    },
    // probably more difficult to implement since vimeo seems to have no end time parameter
    // https://help.vimeo.com/hc/en-us/articles/12426260232977-Player-parameters-overview
    // TODO: [OP-203] vimeo player ignores the end time parameter of snippets
    //
    // endTimeArgument() {
    //   if (!this.end || this.end === '0') {
    //     return "";
    //   } else {
    //     return "&end=" + this.end;
    //   }
    // },
    host() {
      return window.location.host;
    }
  }
};
</script>

<style>
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
