<template>
  <v-layout row>
    <v-flex xs12 sm6 offset-sm3>
      <div v-if="loading" class="loading text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-alert v-if="error" type="error" dismissible>{{ error.message }}</v-alert>
      <form @submit.prevent="save" ref="form" lazy-validation>
        <v-card flat class="mt-16">
          <v-card-title class="pa-6 text-h3 h-break">
            {{ $t('tool.create.caption') }}
          </v-card-title>
          <v-card-text class="px-6 py-0 my-0 text-body-1">
            <v-text-field
                v-model="editedVideoId"
                :label="$t('tool.create.video-id-label')"
                :placeholder="$t('tool.create.video-id-placeholder')"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="editedName"
                :rules="editedNameRules"
                :label="$t('tool.create.name-label')"
                required outlined
                :placeholder="$t('tool.create.name-placeholder')"
            ></v-text-field>
            <v-textarea
                v-model="editedDescription"
                :label="$t('tool.create.description-label')"
                :placeholder="$t('tool.create.description-placeholder')"
                auto-grow outlined
            >
            </v-textarea>
            <v-text-field
                v-model="editedVideoStart"
                :label="$t('tool.create.video-start-time-label')"
                :placeholder="$t('tool.create.video-start-time-placeholder')"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="editedVideoEnd"
                :label="$t('tool.create.video-end-time-label')"
                :placeholder="$t('tool.create.video-end-time-placeholder')"
                outlined
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="px-6 my-0">
            <v-btn type="submit" color="primary" class="px-8" :disabled="loading" :loading="loading">{{ $t('crud.create') }}</v-btn>
            <v-btn text to="/">{{ $t('nav.cancel') }}</v-btn>
            <v-spacer/>
            <v-btn @click="fillYoutube()" :disabled="editedVideoId === ''">Mit Youtube Daten füllen</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-flex>
  </v-layout>
</template>

<script>
import {loadYoutubeData} from "@/service/youtube";

export default {
  data() {
    return {
      editedName: '',
      editedDescription: '',
      imageSrc: '',
      editedVideoId: '',
      editedVideoStart: 0,
      editedVideoEnd: 0,
      valid: true,
      editedNameRules: [
        v => !!v || this.$t('tool.create.name-rule-required'),
        v => (v && v.length <= 120) || this.$t('tool.create.name-rule-length-min'),
      ],
    };
  },
  computed: {
    loading: function() { return this.$store.getters.loading; },
    error: function() { return this.$store.getters.error; },
    user() { return this.$store.getters.user },
  },
  methods: {
    async save() {
      this.$store.dispatch("createTool", {
        userId: this.user.id,
        name: this.editedName,
        description: this.editedDescription,
        imageSrc: this.imageSrc,
        videoId: this.editedVideoId,
        videoStart: this.editedVideoStart,
        videoEnd: this.editedVideoEnd
      }).then((id) => this.$router.push({ name: 'ToolShow', params: { id }}) );
    },
    async fillYoutube() {
      const youtubeData = await loadYoutubeData(this.editedVideoId);
      console.debug("youtubeData", youtubeData);
      this.editedName = youtubeData.title;
      this.editedDescription = youtubeData.description;
      this.imageSrc = youtubeData.thumbnailUrl;
    }
  }
};
</script>
