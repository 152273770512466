async function loadVimeoData(videoId) {
    if (videoId === null || videoId === undefined) {
        throw new Error ("Can't load vimeo data, since videoId is not defined")
    } else {

        // const vimeoVideoDummyData = {
        //     "title": "Vimeo Video Title",
        //     "description": "Vimeo Video Description",
        //     "tags": ["vimeo", "video", "tags"],
        //     "channelTitle": "Vimeo Channel Title",
        //     "channelId": "Vimeo Channel Id",
        //     "publishedAt": "2021-01-01T00:00:00Z",
        //     "thumbnailUrl": "https://i.vimeocdn.com/video/234965351.jpg" // this does not work, always returns the same image
        // }

        // return vimeoVideoDummyData;

        const videoData = {};

        try {
            
            // TODO: The vimeo v2 api is deprecated, use the vimeo v3 api instead
            // fetch via the vimeo v2 api: 
            const response = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`);
            
            if (!response.ok) {
                const responseText = await response.text();
                throw new Error ("Response is not ok, status: '" + response.status + "'" + " response text: '" + responseText + "'")
            }

            const data = await response.json();

            if (data?.length === 0) {
                throw new Error ("Received no data items, maybe the video id is not valid.")
            }

            console.debug(data);
            return data[0];
          } catch (error) {
            throw new Error ("Can't load vimeo data for videoId: '" + videoId + "', '" + error + "'")
          }

        // return fetch(url)
        //     .then((response) => response.json())
        //     .then((data) =>
        //         ({
        //             title: data.items[0].snippet?.title || "",
        //             description: data.items[0].snippet?.description || "",
        //             tags: data.items[0].snippet?.tags || [],
        //             channelTitle: data.items[0].snippet?.channelTitle || "",
        //             channelId: data.items[0].snippet?.channelId || "",
        //             publishedAt: data.items[0].snippet?.publishedAt || "",
        //             thumbnailUrl: data.items[0].snippet?.thumbnails?.high?.url || ""
        //         })
        //     );
    }
}


function extractDataFromVimeoUrl(url) {

    const data =  {
        id: undefined,
        start: undefined,
        end: undefined,
    }

    // works:
    // https://vimeo.com/876040533 (no start time)
    // https://vimeo.com/876040533?t=5 (start time)
    // https://vimeo.com/channels/staffpicks/65107797
    // vimeo.com/123456789
    // vimeo.com/channels/mychannel/123456789
    // vimeo.com/groups/shortfilms/videos/123456789
    // player.vimeo.com/video/123456789
    // http://vimeo.com/123456789
    // https://vimeo.com/channels/mychannel/123456789
    // https://vimeo.com/groups/shortfilms/videos/123456789
    // https://www.player.vimeo.com/video/123456789
    // https://vimeo.com/876040533?t=5

    // does not yet work:

    // find the id via a regex // note the start time is not captured by the regex (no capturing group) since it is extracted as parameter below
    // const oldregex = /^https?:\/\/(?:www\.)?vimeo\.com\/(?:video\/)?(?:channels\/staffpicks\/)?(\d+)(?:\?t=(\d+))?$/gm;
    const regex = /^(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)(?:\?t=\d+)?$/gm;

    let m;
    while ((m = regex.exec(url)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) { regex.lastIndex++; }
        // The result can be accessed through the `m`-variable.
        // m.forEach((match, groupIndex) => {
        //     console.debug(`Found match, group ${groupIndex}: ${match}`);
        // });
        data.id = m[1];
        // if (m[3] !== null && m[3] !== undefined && m[3] !== '') {
        //   data.id =  Number(m[3]);
        // }
    }

    const urlObject = new URL(url);
    var start = urlObject.searchParams?.get('t');
    if (start) {
        try {
        const positionOfS = start?.indexOf('s');
        if (positionOfS == -1) {
            data.start =  Number(start);
        } else {
            data.start = Number(start.slice(0,positionOfS));
        }
        } catch (error) {
        throw new Error("Warning: Start value could not be determined from the URL you provided");
        }
    }

    // console.debug("extractDataFromVimeoUrl: data: ", data);
    
    return data;
}




module.exports = {
    loadVimeoData: loadVimeoData,
    extractDataFromVimeoUrl: extractDataFromVimeoUrl,
};