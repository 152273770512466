<template>
  <div>
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3>
        <!-- <v-alert v-if="error" type="error" dismissible>{{ error.message }}</v-alert> -->
        <form @submit.prevent="signup">
          <v-card flat class="mt-0 mt-sm-16">
            <v-card-title class="pa-6 text-h3 h-break">
              {{ $t('user.signup.caption') }}
            </v-card-title>
            <v-card-text class="px-6 py-0 my-0 text-body-1">
              <p class="mb-6">{{ $t('user.signup.message') }}</p>
              <v-text-field
                  name="email"
                  :label="$t('user.signup.email-label')"
                  id="email"
                  v-model="email"
                  type="email"
                  outlined required></v-text-field>
              <v-text-field
                  name="password"
                  :label="$t('user.signup.password-confirm-label')"
                  id="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="(showPassword = !showPassword)"
                  outlined required></v-text-field>
              <v-text-field
                  name="confirmPassword"
                  :label="$t('user.signup.password-label')"
                  id="confirmPassword"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="confirmPassword"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="[comparePasswords]"
                  @click:append="(showPassword = !showPassword)"
                  outlined required></v-text-field>
            </v-card-text>
            <v-card-actions class="px-6 my-0">
              <v-btn type="submit" color="primary" class="px-8 mr-4" :disabled="loading" :loading="loading">
                {{ $t('user.signup.signup') }}
              </v-btn>
              <v-spacer/>
              <v-btn text :disabled="loading" :loading="loading" :to="{ name: 'Signin'}">
                {{ $t('user.signup.signon') }}
              </v-btn>
            </v-card-actions>

          </v-card>
        </form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        email: '',
        password: '',
        confirmPassword: '',
        showPassword: false,
        rules: {
          required: value => !!value || this.$t('user.signup.password-rule-required'),
          min: value => value.length >= 8 || this.$t('user.signup.password-rule-length-min'),
        },
      }
    },
    computed: {
      comparePasswords () {
        return this.password !== this.confirmPassword ? this.$t('user.signup.password-confirm-rule-no-match') : true
      },
      user () {
        return this.$store.getters.user
      },
      // error () {
      //   return this.$store.getters.error
      // },
      loading () {
        return this.$store.getters.loading
      }
    },
    watch: {
      user (value) {
        if (value !== null && value !== undefined) {
            // console.debug("routing to / by signup watch user");
            this.$router.push('/')
        }
      }
    },
    methods: {
      signup () {
        this.$store.dispatch('signUserUp', {email: this.email, password: this.password})
          // .then(() => this.$router.push('signin')) // already done elsewhere
          .catch(error => {

            if (error.code == 'auth/email-already-in-use') {
              this.$store.dispatch('errorCall', {message: this.$t('user.signup.error-email-already-in-use')})
            } else if (error.code == 'auth/weak-password') {
              this.$store.dispatch('errorCall', {message: this.$t('user.signup.error-weak-password')})
            } else {
              error.message = this.$t('user.signup.error-unknown')
              console.error("error.code: " + error.code + ", error.message: " + error.message)
              this.$store.dispatch('errorCall', error)
            }
          })
      },
      dismissed () {
        this.$store.dispatch('clearError')
      }
    }
  }
</script>
