<template>
    <!-- Sharing only worked in Safari on Mac -->
    <v-btn v-if="canShare()" text @click.stop="share">
        <v-icon class="mr-1" small>mdi-share</v-icon>
        Share
    </v-btn>
</template>

<script>
import statelessHelpers from '@/mixins/statelessHelpers';

export default {
    name: "ShareButton",
    props: {
        title: {
            type: String,
            default: "Open Physio"
        },
        text: {
            type: String,
            default: "Checkout Open Physio Search"
        },
        url: {
            type: String,
            default: "http://app.open-physio.de"
        }
    },
    // mounted(){
    //     console.debug("sharing " + this.url, this.asAbsoluteUrl(this.url));
    //     console.debug("sharing http://app.open-physio.de", this.asAbsoluteUrl("http://app.open-physio.de"));
    //     console.debug("sharing http://localhost:8081/something", this.asAbsoluteUrl("http://localhost:8081/something"));
    // },
    mixins: [statelessHelpers],
    methods: { 
        share(){
            // Sharing only worked in Safari on the Mac
            // console.debug("sharing ", this.asAbsoluteUrl(this.url));
            if (navigator.share) {
                navigator.share({
                title: this.title,
                text: this.text,
                url: this.url,
                })
                .then(() => console.info('Successful share'))
                .catch((error) => console.error('Error sharing', error));
            }
        },
        // asAbsoluteUrl(path){
        //     // not necessary, since browsers prefix with http ... when sharing leading to doubled hosts in the URL
        //     // if still necessary, try prefiying with http(s):// + host instead ...
        //     // return (path.indexOf("http") === 0 || path.indexOf("localhost") === 0) ? path : this.host() + path;
        //     return path;
        // },
        host() {
            return window.location.host;
            // return "http://localhost:8081/";
        }
    }
};
</script>
<style lang="css" scoped></style>