import Vue from 'vue';
import { collection, doc, getDoc, getDocs, addDoc, updateDoc, deleteDoc, getDocsFromServer, where, query } from "firebase/firestore";
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { db, storage } from "@/plugins/firebase";
import data from '@/data';

export default {
	state: {
		videos: []
	},
	getters: {
		videos: state => {
			return state.videos;
		},
		videoById: state => id => {
			return state.videos.find(s => s.id === id);
		}
	},
	// Actions returns Promises, see https://mclassdesigns.gitbook.io/js-journal/
	actions: {

		// get video by externalId and platform
		async getVideoByExternalId({ commit }, { externalId, platform }) {

			try {
				// here we use getDocs not getDocsFromServer (!) to get the cached data evtl. faster
				const querySnapshot = await getDocs(collection(db, "videos"), where("externalId", "==", externalId), where("platform", "==", platform));
				if (querySnapshot.empty) {
					return null;
				}
				const docSnapshot = querySnapshot.docs[0];
				if (docSnapshot.exists()) {
					const data = docSnapshot.data();
					const video = {
						id: docSnapshot.id,
						userId: data.userId,
						published: data.published,
						name: data.name,
						description: data.description,
						externalId: data.externalId,
						videoDuration: data.videoDuration,
						votesUp: data.votesUp || [],
						votesDown: data.votesDown || [],
						tags: data.tags || [],
						publisher: data.publisher,
						publisherId: data.publisherId,
						publishedAt: data.publishedAt,
						platform: data.platform,
						thumbnailUrl: data.thumbnailUrl,
						imageSrc: data.imageSrc,
						storageUrl: data.storageUrl
					};
					commit('addVideo', video);
					return video;
				}
				return null;
			}
			catch (error) {
				// commit('errorCall', error);
				return Promise.reject(error);
			}

		},

		// check if video is already in the database
		async checkIfVideoExists({ commit }, { externalId, platform }) {

			try {
				const q = query(collection(db, "videos"), where("externalId", "==", externalId), where("platform", "==", platform));
				// here we use getDocsFromServer to get the latest data
				const querySnapshot = await getDocsFromServer(q); 
				if (querySnapshot.empty) {
					return false;
				} else {
					return true;
				}
			} catch (error) {
				// commit('errorCall', error);
				return Promise.reject(error);
			}

		},

		// load a single video by id
		async loadVideo({ commit }, id) {
			commit('startCall');

			try {
				const docRef = doc(db, 'videos', id);
				const docSnapshot = await getDoc(docRef);
				if (docSnapshot.exists()) {
				  const data = docSnapshot.data();
				  const video = {
					id: docSnapshot.id,
					userId: data.userId,
					published: data.published,
					name: data.name,
					description: data.description,
					externalId: data.externalId,
					videoDuration: data.videoDuration,
					votesUp: data.votesUp || [],
					votesDown: data.votesDown || [],
					tags: data.tags || [],
					publisher: data.publisher,
					publisherId: data.publisherId,
					publishedAt: data.publishedAt,
					platform: data.platform,
					thumbnailUrl: data.thumbnailUrl,
					imageSrc: data.imageSrc,
					storageUrl: data.storageUrl
				  };
				  commit('addVideo', video);
				  commit('successCall');
				  return true;
				} else {
				  throw new Error(`Video with ID ${id} not found in the database`);
				}
			  } catch (error) {
				commit('errorCall', error);
				return Promise.reject(error);
			  }
		},


		// out of use atm, we are loading single videos on demand
		loadVideos({ commit }) {
			commit('startCall');
			return getDocs(collection(db, "videos"))
				.then((queryVideos) => {
					commit('clearVideos');
					queryVideos.forEach((doc) => {
						const data = doc.data();
						commit('addVideo', {
							id: doc.id,
							userId: data.userId,
							published: data.published,
							name: data.name,
							description: 'video description (or whole video) should be loaded on demand ' + data.description	,
							externalId: data.externalId,
							videoDuration: data.videoDuration,
							votesUp: data.votesUp || [],
							votesDown: data.votesDown || [],
							tags: data.tags || [],
							publisher: data.publisher,
							publisherId: data.publisherId,
							publishedAt: data.publishedAt,
							platform: data.platform,
							thumbnailUrl: data.thumbnailUrl,
							imageSrc: data.imageSrc,
							storageUrl: data.storageUrl
						});
					});
					commit('successCall');
					return true;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		},
		// TODO not yet tested and no data in examples
		createExampleVideos({ commit, dispatch }, userId) {
			commit('startCall');
			for (const [id, video] of Object.entries(data.videos)) {
				dispatch('createVideo', {
					userId: userId,
					name: video.name,
					description: video.description,
					externalId: video.defaultExternalId,
					videoDuration: video.defaultDuration,
					votesUp: [],
					votesDown: [],
					tags: video.tags || [],
					publisher: video.publisher,
					publisherId: video.publisherId,
					publishedAt: video.publishedAt,
					platform: video.platform,
					thumbnailUrl: video.thumbnailUrl,
					imageSrc: "",
					storageUrl: ""
				});
			}
			commit('successCall');
		},
		createVideo({ commit }, video) {
			commit('startCall');
			return addDoc(collection(db, "videos"), video)
				.then((createdVideo) => {
					commit('addVideo', {
						id: createdVideo.id,
						// userId: video.userId,
						externalId: video.externalId,
						name: video.name,
						description: video.description,
						tags: video.tags,
						publisher: video.publisher,
						publisherId: video.publisherId,
						publishedAt: video.publishedAt,
						platform: video.platform,
						thumbnailUrl: video.thumbnailUrl,
						// imageSrc: video.imageSrc,
						// storageUrl: video.storageUrl,
						// videoDuration: video.videoDuration,
						// votesUp: [],
						// votesDown: []
					});
					commit('successCall');
					return createdVideo.id;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		},
		updateVideo({ commit }, payload) {
			commit('startCall');
			return updateDoc(doc(db, "videos", payload.id), payload)
				.then(() => {
					commit('setVideo', payload);
					commit('successCall');
					return true;
				})
				.catch(error => {
					commit('errorCall', error);
					return Promise.reject(error);
				});
		},
		updateVideoWithImage({ dispatch }, { payload, imageFile }) {
			if (imageFile) { // first upload the image, then update the video
				const filename = imageFile.name;
				const ext = filename.slice(filename.lastIndexOf('.'));
				const storageUrl = 'videos/' + payload.id + ext;
				const imageStorageRef = storageRef(storage, storageUrl);
				return uploadBytes(imageStorageRef, imageFile)
					.then((snapshot) => {
						console.debug('Uploaded a blob or file!', snapshot);
						return getDownloadURL(imageStorageRef).then((downloadURL) => {
							console.debug('File available at', downloadURL);
							payload.imageSrc = downloadURL;
							payload.storageUrl = storageUrl;
							return dispatch("updateVideo", payload);
						});
					});
			}
			return dispatch("updateVideo", payload);
		},
		async deleteVideoImage({ dispatch, commit }, payload) {
            if (payload.storageUrl) {
				try {
					const imageStorageRef = storageRef(storage, payload.storageUrl);
					// Delete the file
					await deleteObject(imageStorageRef);
					// File deleted successfully
					payload.imageSrc = "";
					payload.storageUrl = "";
					await dispatch("updateVideo", payload);
					return true;
				} catch (error) {
					commit('errorCall', error);
					return false;
				}
			} else {
				const error = new Error("Image file could not be deleted (storageUrl was null or undefined)");
				commit('errorCall', error);
				return false;
			}
        },
        async deleteVideo({ dispatch, commit }, payload) {
            commit('startCall');
			if (payload.storageUrl && payload.storageUrl !== '') {
				await dispatch("deleteVideoImage", payload) 
			}
			try {
				await deleteDoc(doc(db, "videos", payload.id));
				commit('deleteVideo', payload);
				commit('successCall');
				return true;
			} catch (error) {
				commit('errorCall', error);
				return false;
			}
        }
	},
	mutations: {
		clearVideos(state) {
			state.videos = [];
		},
		addVideo(state, payload) {
			state.videos.push(payload);
		},
		setVideo (state, payload) {
			const video = state.videos.find(m => m.id === payload.id );
			if (video) {
				video.name = payload.name
				video.userId = payload.userId,
				video.description = payload.description
				video.externalId = payload.externalId
				video.videoDuration = payload.videoDuration
				video.upvotes = payload.upvotes
				video.downvotes = payload.downvotes
				video.thumbnailUrl = payload.thumbnailUrl
				video.imageSrc = payload.imageSrc
				video.storageUrl = payload.storageUrl
				video.image = payload.image
				video.tags = payload.tags
				video.publisher = payload.publisher
				video.publisherId = payload.publisherId
				video.publishedAt = payload.publishedAt
				video.platform = payload.platform
			}
		},
		deleteVideo(state, payload) {
			Vue.delete(state.videos, state.videos.indexOf(payload));
		}
	}
}
