<template>
  <div>
    <v-card flat class="text-center text-white px-3 pt-3 pb-1 mt-0 mb-1 mb-md-4 searchCard bannerCard">
      <div class="d-none d-md-flex justify-center pa-2">
        <v-img contain src="@/assets/logo.svg" width="6rem" max-width="6rem" class="my-4" />
      </div>
      <!-- <h1 class="text-h4 d-none d-md-block mb-6">{{ $t('video-snippet.search.question') }}</h1> -->
      <div class="d-flex justify-center">
        <div style="width: 800px">
          <v-menu v-model="showSuggestMenu" offset-y :open-on-click="false" :close-on-click="false" :nudge-bottom="10"
            min-width="726px" max-width="726px" :nudge-right="37">
            <template v-slot:activator="{ on }">
              <v-text-field v-on="on" v-model="searchTerm" background-color="appWhite" class="searchField text-h6 pb-2"
                filled color="appBlack lighten-1" prepend-inner-icon="mdi-magnify" @focus="showSuggestMenu = true"
                @blur="showSuggestMenu = true" @input="showSuggestMenu = true" @keyup.enter="showSuggestMenu = false"
                clearable hide-details rounded autofocus></v-text-field>
            </template>
            <v-list v-if="suggest.length > 0">
              <v-list-item-group color="primary" @change="suggestSelected">
                <v-list-item v-for="(suggest, index) in suggest" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>{{ suggest }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div class="text-h6 mt-3 mt-sm-6 mb-3 mb-sm-6 d-none d-sm-block">
        {{ $t("video-snippet.search.explanation") }}
      </div>
    </v-card>

    <!-- <div>
      <v-btn text :to="{ name: 'VideoSnippetCreate' }" class="mr-4 d-none d-sm-flex">
        <v-icon left>mdi-movie-plus</v-icon>
        {{ $t('nav.top.create-video-snippet') }} 
      </v-btn>
    </div> -->

    <v-container class="text-left">
      <!-- <div class="mt-2 d-flex align-center justify-space-between searchResultsHeader"> -->
      <!-- <span class="d-flex align-center searchResultsHeader" @click="switchVideoSnippets">
          <v-icon v-if="showVideoSnippets" x-large class="searchResultsHeader">mdi-menu-down</v-icon>
          <v-icon v-else x-large class="searchResultsHeader">mdi-menu-right</v-icon>
          <span class="text-h6 font-weight-bold">{{ $t('video-snippet.search-results.video-snippets-caption') }}</span>
          <v-chip class="ml-2">{{ videoSnippetSearchResult.length }}</v-chip>
        </span> -->
      <!-- </div> -->

      <v-container fluid :class="{
            'pa-0': $vuetify.breakpoint.xs,
            'pa-2': $vuetify.breakpoint.mdAndUp,
          }">
        <v-row class="d-flex align-baseline justify-space-between searchResultsHeader">
          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="d-flex flex-column">
            <v-select class="mb-0" :items="computedSortFields" :label="$t('video-snippet.search.sort-by-field')"
              v-model="selectedSortField" item-text="text" item-value="value" outlined hide-details></v-select>
            <v-radio-group v-model="sortAscending" hide-details row class="mt-2">
              <v-radio :label="$t('video-snippet.search.sort-direction-asc')" :value="true"></v-radio>
              <v-radio :label="$t('video-snippet.search.sort-direction-desc')" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="d-flex flex-column">
            <v-select class="mb-0" :items="[
            'AOK Der Gesundheitskanal',
            'Amiena Zylla',
            'Bewegungsumfang  Visna Stief',
            'CSS Gesundheitspartnerin',
            'Christian Schroth  Active Physio',
            'Christian Steingässer',
            'Daniel Nesyba',
            'Dennis Eitner',
            'Der Physiast',
            'Die Techniker',
            'DoktorWeigl',
            'Dr. Peter Poeckh',
            'Eckart von Hirschhausen',
            'Einfach besser leben',
            'Ergotopia',
            'Faszienchannel',
            'FormelFroböse',
            'Gabi Fastner',
            'Haltungsexperte',
            'HappyAndFitPilates',
            'HappyAndFitYoga',
            'Ihr Sportarzt',
            'Improving ability',
            'KLINIK am RING  Die Gelenkspezialisten',
            'Knie Marathon',
            'Liebscher  Bracht  Die Schmerzspezialisten',
            'Medical Fitness Trainer Luke Brandenburg',
            'Moving Monkey',
            'Nico Lorenz',
            'Nutritious Movement',
            'Physio Inga',
            'Physio Plan  Osteopathie und Schmerztherapie',
            'PhysioCircle  Sven Quilitz',
            'Physiotherapeut Marvin Seidel',
            'Privat Physio Bonn',
            'Roman Pallesits',
            'Rücken und BodyFit',
            'STURFDOC  Dr. Christian Behrendt',
            'SanoGym  Fit  Schmerzfrei',
            'Sascha Huber',
            'Sascha Seifert',
            'Soda Plus',
            'Spiraldynamik',
            'Steffen Barth  Reha Experte',
            'StrongandFlexTV',
            'Tamara Haas',
            'Tamay Jentjens',
            'Think Flow Grow  Holistic Health  Fitness',
            'YOGABASICS',
            'funcFIT Personal Training',
            'motivationaldoc',
            'muskelundgelenkschmerzen',
            'runnersflow',
          ]" :label="$t('video-snippet.search.filter-authors')" v-model="selectedAuthorField" outlined hide-details
              clearable></v-select>
          </v-col>
          <v-col v-if="userIsAdmin" cols="12" sm="6" md="4" lg="3" xl="3" class="d-flex flex-column">
            <v-select v-model="filterPublished" :items="['published', 'to-approve', 'hidden']"
              :label="$t('video-snippet.search.filter-published')" outlined hide-details></v-select>
          </v-col>

          <v-col v-if="userIsFamily" cols="12" sm="6" md="4" lg="3" xl="3" class="d-flex flex-column">
            <v-switch hide-details class="mt-0" v-model="showMyVideoSnippetsOnly" :label="$t('video-snippet.search-results.show-my-video-snippets-only')
            "></v-switch>
          </v-col>
        </v-row>
      </v-container>

      <v-container fluid :class="{
            'pa-0': $vuetify.breakpoint.xs,
            'pa-2': $vuetify.breakpoint.mdAndUp,
          }">
        <v-row v-if="showVideoSnippets && videoSnippetSearchResult.length > 0">
          <v-col v-for="videoSnippetSolrDoc in videoSnippetSearchResult" :key="videoSnippetSolrDoc.id" cols="12" xs="12"
            sm="6" md="6" lg="4" xl="3" class="d-flex flex-column">
            <video-snippet-card-simple :videoSnippetSolrDoc="videoSnippetSolrDoc" />
          </v-col>
        </v-row>
        <div v-else>{{ $t("video-snippet.search.no-results") }}</div>
      </v-container>
    </v-container>
  </div>
</template>

<script>
// import MiniSearch from 'minisearch';
import VideoSnippetCardSimple from "@/components/VideoSnippetCardSimple";
import { VContainer } from "vuetify/lib";
import _ from "lodash";

export default {
  name: "VideoSnippetSearch",
  components: { VideoSnippetCardSimple, VContainer },
  data() {
    return {
      showSuggestMenu: false,
      showMyVideoSnippetsOnly: false,
      filterPublished: "published",
      selectedSortField: "created",
      selectedAuthorField: null,
      sortAscending: true,
      searchTerm: "",
      suggest: [],
      videoSnippetSearchResult: [],
    };
  },
  mounted() {
    // not needed anymore, since we watch the route now
    // let qQueryParameter = this.$route.query.q;
    // // console.debug(qQueryParameter); // logs the value of 'myParam' query parameter
    // if (qQueryParameter) {
    //   this.searchTerm = qQueryParameter;
    // } else {
    //   this.searchTerm = this.$store.getters.searchTerm;
    // }

    this.showMyVideoSnippetsOnly = this.$store.getters.showMyVideoSnippetsOnly;
    this.filterPublished = this.$store.getters.filterPublishedVideoSnippets;
    this.selectedSortField = this.$store.getters.sortField;
    this.selectedAuthorField = this.$store.getters.selectedAuthorField;
    this.sortAscending = this.$store.getters.sortAscending;
    this.doSearch();
    // wait for 1.5 second(s) and search again, since the solr index is not yet updated via cloud functions
    // this works most of the time, but sometimes the search results are still not up to date
    // a better solution would be to wait for the cloud function to finish and then update the index
    // however also solr needs some time to update the index
    setTimeout(() => {
      this.doSearch();
    }, 1500);
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        let qQueryParameter = to.query.q;
        if (qQueryParameter) {
          this.searchTerm = qQueryParameter;
        } else {
          this.searchTerm = this.$store.getters.searchTerm;
        }
        this.doSearch();
      },
    },
    searchTerm(newValue, oldValue) {
      // don't let the searchTerm become null or undefined (e.g. when deleting the search term in the search field)
      let newValueNotNullOrUndefined = newValue || "";

      this.$store.dispatch("updateSearchTerm", newValueNotNullOrUndefined);
      this.doSearch();

      // Avoid redundant navigation
      if (this.$route.query.q !== newValueNotNullOrUndefined) {
        // Update the URL
        this.$router
          .replace({ query: { q: newValueNotNullOrUndefined } })
          .catch((err) => { });
      }
    },
    showMyVideoSnippetsOnly() {
      this.$store.dispatch(
        "updateShowMyVideoSnippetsOnly",
        this.showMyVideoSnippetsOnly
      );
      this.doSearch();
    },
    filterPublished() {
      this.$store.dispatch(
        "updateFilterPublishedVideoSnippets",
        this.filterPublished
      );
      this.doSearch();
    },
    selectedAuthorField() {
      this.$store.dispatch("updateFilterAuthorField", this.selectedAuthorField);
      this.doSearch();
    },
    selectedSortField() {
      this.$store.dispatch("updateSortField", this.selectedSortField);
      this.doSearch();
    },
    sortAscending() {
      this.$store.dispatch("updateSortAscending", this.sortAscending);
      this.doSearch();
    },
    user() {
      this.doSearch(); // reload search results when user changes (e.g. login/logout)
    },
  },
  methods: {
    switchVideoSnippets() {
      this.$store.dispatch("switchVideoSnippets");
    },
    suggestSelected(index) {
      if (index !== undefined) {
        console.debug("Suggest selected", this.suggest[index]);
        this.searchTerm = this.suggest[index];
      }
    },
    prepareAndCleanQueryWords(searchString) {
      // if a word has a trailing colon, or the word is empty, delete the whole word, since querying a field without a value is not allowed in the solr query (would throw an error)

      let queryWords = searchString.trim().split(" ");
      queryWords = queryWords.filter(
        (word) => !word.endsWith(":") && word.length > 0
      );

      // split the words containing a colon into a pair of key and value and lowercase the key, then join the words again

      queryWords = queryWords.map((word) => {
        if (word.includes(":")) {
          const key = word.split(":")[0].toLowerCase();
          const value = word.split(":")[1];

          // if the key is "externalid" replace it with "externalId" (since the field name in solr is "externalId")
          if (key === "externalid") {
            return "externalId:" + value;
          } else if (key === "userid") {
            return "userId:" + value;
          } else if (key === "publishedat") {
            return "publishedAt:" + value;
          } else {
            return key + ":" + value;
          }
        } else {
          return word;
        }
      });

      // console.debug("Query words: ", queryWords);

      return queryWords.join(" ");
    },
    escapeSearchString(unescapedSearchString) {
      // escape special characters (except some) for solr query
      // see https://solr.apache.org/guide/6_6/the-standard-query-parser.html#TheStandardQueryParser-EscapingSpecialCharacters
      // const regexSolr = /([\+\-\&\|\!\(\)\{\}\[\]\^\"\~\*\?\:\/])/g;
      // const regexSolrKeepColon = /([\+\-\&\|\!\(\)\{\}\[\]\^\"\~\*\?\\\/])/g; // colon is kept, see below
      const regexSolrKeepColonAndStar =
        /([\+\-\&\|\!\(\)\{\}\[\]\^\"\~\?\\\/])/g; // colon and star are kept, see below

      // escapedSearchString = unescapedSearchString.replace(regexSolrKeepColon, "\\$1");
      // attention: most special characters are simply removed COMPLETELY atm!
      const escapedSearchString = unescapedSearchString.replace(
        regexSolrKeepColonAndStar,
        ""
      ); // simply delete the special characters
      // escapedSearchString = unescapedSearchString.replace(regexSolr, "\\$1");
      // escapedSearchString = unescapedSearchString.replace(":", "%3A"); // keep the colon for field queries and escape it

      return escapedSearchString;
    },
    buildSolrJSONQuery() {
      // https://solr.apache.org/guide/solr/latest/getting-started/searching-in-solr.html
      // https://solr.apache.org/guide/solr/latest/query-guide/query-syntax-and-parsers.html

      // (for pure suggestion?) instead of spellcheck better use: https://solr.apache.org/guide/solr/9_0/query-guide/suggester.html

      const url = "/solr/videosnippets/spell";
      let spellcheck = true;

      // prepare the search string and search parameters

      let modifiedSearchString = this.trimmedSearchTerm;
      if (modifiedSearchString.length === 0) {
        modifiedSearchString = "*:*"; // search for everything, if no user input
        spellcheck = false; // don't do spellcheck, if no user input
      }
      const unescapedSearchString = modifiedSearchString
        ? this.prepareAndCleanQueryWords(modifiedSearchString)
        : "";
      const escapedSearchString = this.escapeSearchString(
        unescapedSearchString
      );

      let fieldQuery = "published:published";

      if (this.userIsAdmin && this.filterPublished) {
        fieldQuery = "published:" + this.filterPublished;
      }

      if (this.selectedAuthorField) {
        fieldQuery += ` AND publisher:"${this.selectedAuthorField}"`;
      }

      if (this.userIsFamily && this.showMyVideoSnippetsOnly) {
        fieldQuery += " AND userId:" + this.user.id;
      }

      let sortString = "name_str asc";

      if (this.selectedSortField) {
        sortString =
          this.selectedSortField + " " + (this.sortAscending ? "asc" : "desc");
      }

      const data = {
        query: escapedSearchString, // search query
        filter: fieldQuery, // filter query
        params: {
          // df: "description", // default field to search in, alternatively use qf
          // https://solr.apache.org/guide/solr/latest/query-guide/dismax-query-parser.html
          defType: "edismax", // use a specific query parser, e.g. edismax or dismax
          qf: "name tags", // query fields, only works with edismax and dismax ------ don't forget to put "description" back in after cleaning the data is solved
          q_op: "OR", // "AND" or "OR"
          spellcheck: spellcheck, // enable spellcheck
          "spellcheck.collate": true,
          "spellcheck.q": escapedSearchString,
          wt: "json", // return json
          rows: 10, // max number of results
          indent: true, // pretty print
          "spellcheck.build": true, // build the spellcheck index
          "spellcheck.reload": true, // reload the spellcheck index
          // attention: dates must be single valued in solr, otherwise the query will fail
          // sort: "name_str asc, created desc", // name is not sortable, use name_str instead
          sort: sortString,
          // sort: "name asc, publishedAt desc", // publishedAt is still multi valued, so this doesn't work
        },
      };

      return { url, data };
    },

    // debounce the search function to avoid too many requests
    doSearch: _.debounce(
      async function () {
        return new Promise(async (resolve, reject) => {
          (async () => {
            if (
              this.trimmedSearchTerm.length != 0 &&
              this.trimmedSearchTerm.length < 3
            ) {
              this.videoSnippetSearchResult = [];
              this.suggest = [];
              resolve([]);
              return;
            }

            const solrJsonQuery = this.buildSolrJSONQuery();

            console.debug("Solr query JSON data: ", solrJsonQuery.data);
            console.debug("Searching in Solr using JSON data ...");

            try {
              const response = await fetch(solrJsonQuery.url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(solrJsonQuery.data),
              });
              if (response.status !== 200) {
                throw new Error(
                  "Cannot fetch data from solr",
                  response.status,
                  response.statusText
                );
              }
              const data = await response.json();
              console.debug("Solr response: ", data);

          const searchResult = data.response.docs.map(doc => {
            return {
              id: doc.id,
              type: 'videoSnippet',
              userId: doc.userId?.[0] || undefined,
              created: doc.created || "",
              updated: doc.updated || "",
              // indexed: doc.indexed || "",
              published: doc.published?.[0] || "unknown",
              generatedName: doc.name?.[0] || undefined,
              name: doc.name?.[0] || undefined,
              description: doc.description?.[0],
              externalVideoId: doc.externalId?.[0],
              platform: doc.platform?.[0],
              thumbnailUrl: doc.thumbnailUrl?.[0],
              imageSrc: doc.imageSrc?.[0],
              duration: doc.duration?.[0],
              tags: doc.tags || [],
              publishedAt: doc.publishedAt?.[0] || undefined,
              publisher: doc.publisher?.[0] || false,
              publisherId: doc.publisherId?.[0] || undefined,
              videoStart: doc.videoStart || undefined,
              videoEnd: doc.videoEnd || undefined,
              votesUp: doc.votesUp || [],
              votesDown: doc.votesDown || [],
            }
          });

              this.videoSnippetSearchResult = searchResult;
              this.showSuggestions(data);
              resolve(searchResult);
            } catch (error) {
              console.error(error);
              reject(error);
            }
          })();
        });
        // sets the wait time for the debounce to 500ms and executes it once after the last call ( https://www.geeksforgeeks.org/lodash-_-debounce-method/ )
      },
      500,
      { trailing: true }
    ),

    showSuggestions(data) {
      if (this.createSuggestions && data.spellcheck?.collations) {
        const suggestions = data.spellcheck.collations
          .filter((collation) => collation.collationQuery)
          .map((collation) => {
            return { suggestion: collation.collationQuery };
          });

        this.suggest = suggestions.map(
          (suggestEntry) => suggestEntry.suggestion
        );
      } else {
        this.suggest = [];
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    userIsAuthenticated() {
      return this.user !== null && this.user !== undefined;
    },
    userIsAdmin() {
      return (
        this.userIsAuthenticated &&
        this.user.roles &&
        this.user.roles.includes("admin")
      );
    },
    userIsFamily() {
      return (
        this.userIsAuthenticated &&
        this.user.roles &&
        this.user.roles.includes("family")
      );
    },
    showVideoSnippets() {
      return this.$store.getters.showVideoSnippets;
    },
    // filteredVideoSnippets() {
    //   return this.videoSnippets.filter(videoSnippetSolrDoc => {
    //     if (this.user === null || this.user === undefined) {
    //       // without auth, show only published snippets
    //       return videoSnippetSolrDoc.published === "published";
    //     } else if (this.showMyVideoSnippetsOnly) {
    //       // with auth show only my snippets if limited to those
    //       return videoSnippetSolrDoc.userId === this.user.id;
    //     } else {
    //       // otherwise my and the published snippets
    //       return videoSnippetSolrDoc.userId === this.user.id || videoSnippetSolrDoc.published === "published";
    //     }
    //    })
    // },
    loading() {
      return this.$store.getters.loading;
    },
    createSuggestions() {
      return this.searchTerm?.length > 3;
    },
    trimmedSearchTerm() {
      // remove leading and trailing whitespaces
      return this.searchTerm?.trim() || "";
    },
    computedSortFields() {
      // Generate the sortFields array based on the local changes
      const sortFields = [
        {
          text: this.$t("video-snippet.search.fields.name_str"),
          value: "name_str",
        },
        {
          text: this.$t("video-snippet.search.fields.created"),
          value: "created",
        },
        {
          text: this.$t("video-snippet.search.fields.updated"),
          value: "updated",
        },
        {
          text: this.$t("video-snippet.search.fields.publishedAt"),
          value: "publishedAt",
        },
        {
          text: this.$t("video-snippet.search.fields.publisher"),
          value: "publisher_str",
        },
      ];
      return sortFields;
    },
  },
};
</script>

<style scoped>
.searchCard {
  background-image: url("@/assets/sea.jpg");
  background-position: bottom center;
  background-size: cover;
  color: var(--v-appWhite-base);
}

.searchResultsHeader {
  color: var(--v-onTertiary-base);
  opacity: 80%;
  cursor: pointer;
  user-select: none;
}
</style>

<style>
/* this musn't be scoped and must be !important in order to work 
see https://stackoverflow.com/questions/52310060/how-to-override-vuetify-styles */
.searchField input,
.searchField .v-icon {
  color: var(--v-appBlack-base) !important;
}

body {
  overflow-x: hidden;
}
</style>
