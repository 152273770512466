<template>
<div class="video-preview">
  <v-card @click="openSequence(sequence.id)" class="my-8" hover>
    <span v-if="hasFavorited && userIsFamily" class="overlayBtnOnCards d-flex justify-center">
      <v-icon :class="{favorited: hasFavorited}">mdi-bookmark</v-icon>
    </span>
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-avatar class="ma-0" width="400" height="250" tile>
          <v-img height="250" width="400" v-if="sequence.imageSrc && sequence.imageSrc !==''" :src="sequence.imageSrc"></v-img>
          <v-img height="250" v-else src="https://via.placeholder.com/728x250.jpeg?text=Sammlung"></v-img>
        </v-avatar>
      </div>
      <div class="flex-grow-1">
        <v-card-title style="width: calc(100% - 40px)">{{ sequence.name }}</v-card-title>
        <v-card-text>
          <v-chip>{{ sequence.toolIds.length }} Tools</v-chip>
          <span class="ml-3">
            <v-icon v-if="sequence.published">mdi-earth</v-icon>
            <v-icon v-else>mdi-lock</v-icon>
          </span>
        </v-card-text>
        <v-card-text class="cardText">
          {{ sequence.description }}
        </v-card-text>
      </div>
    </div>
  </v-card>
</div>
</template>

<script>
export default {
  name: "SequenceCard",
  props: ["sequence"],
  computed: {
    user() { return this.$store.getters.user },
    userIsAuthenticated() {
      return this.user !== null && this.user !== undefined
    },
    userIsAdmin() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("admin");
    },
    userIsFamily() {
      return this.userIsAuthenticated && this.user.roles && this.user.roles.includes("family");
    },
    hasFavorited() {
      if (this.userIsAuthenticated) {
        return this.user.sequenceFavoriteIds?.indexOf(this.sequence.id) >= 0 || false 
      } else
        return false;
    }
  },
  methods: {
    openSequence(id) {
      this.$router.push({ name: "SequenceShow", params: { id }});
    }
  }
};
</script>

<style scoped>
.cardText {
  font-size: 100%;
}
</style>
